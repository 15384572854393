import React, { useState, useEffect, useCallback } from 'react';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import SaveIcon from '@mui/icons-material/Save';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import { DistContactInfoService } from '../services/service.dist.contact.info';
import { FieldTypes } from '../components/component.easyedit';


// Distributor contact information Component
export const DistContactInfo = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [tableData, setTableData] = useState({
        odata: null,
        cdata: null
    });
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: SaveIcon,
        action: (e, d) => {}
    });
    const [showDialog, setShowDialog] = useState(false);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    })

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'Type', field: 'CNTC_TYP_DESC', width: 200, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Name', field: 'CNTC_NAME', width: 400, visible: true, filter: true, sorting: true, searching: true, editable: {edit: true, type: FieldTypes.TEXT}},
            {label: 'Email', field: 'CNTC_EMAIL', width: 380, visible: true, filter: true, sorting: true, searching: true, editable: {edit: true, type: FieldTypes.TEXT}},
            {label: 'Phone', field: 'CNTC_PHONE', width: 160, visible: true, filter: true, sorting: true, searching: true, editable: {edit: true, type: FieldTypes.TEXT}}
        ],
        // Popup dialog columns while select rows for an action to perform
        /* pdgcolumns: [
            {label: 'Type', field: 'CNTC_TYP_DESC', width: 140, visible: true},
            {label: 'Name', field: 'CNTC_NAME', width: 220, visible: true},
            {label: 'Email', field: 'CNTC_EMAIL', width: 220, visible: true},
            {label: 'Phone', field: 'CNTC_PHONE', width: 100, visible: true}
        ], */
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'edit',
                label: 'Edit',
                icon: SaveIcon,
                isExtraAct: true,
                disable: false,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    // console.log('zz>rowItems>', rowItems);
                    setDialogType({
                        type: 'CNTC_UPD_CFM',
                        title: this.label,
                        icon: this.icon,
                        actionText: 'Update',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems/* , {cbks, dwv} */) => {
                            // console.log('>newRowItems>', newRowItems/* , cbks, dwv */);
                            (async () => {
                                await updateDistContactInfo(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], [])/* , cbks */);
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
        ],
        // Row actions
        /* actions: [
            {
                id: 'edit1',
                title: 'Edit',
                icon: EditOutlinedIcon,
                iedit: true,
                onClick: function(e, {rowItem, cbks}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    // console.log('>rowItem>', rowItem);
                    (async () => {
                        await updateDistContactInfo({...rowItem, AZR_USR_ID: userInfo?.upn}, cbks);
                    })();
                    
                    / * setDialogType({
                        type: 'CNTC_INFO_EDT',
                        title: this.title,
                        icon: this.icon,
                        actionText: 'Update',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            console.log('>newRowItems>', newRowItems);
                            (async () => {
                                await updateDistContactInfo(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []), cbks);
                            })();
                        }
                    });
                    setShowDialog(true); * /
                }
            }
        ], */
        // Table options
        options: {
            action: false,
            filtering: true,
            searching: true,
            contextmenu: true,
            rowclick: false,
            morecolumns: true,
            distdwmenu: true
        }
    }

    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        setJdeAddrBookNo(dwValue);
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        if (!dwValue?.id) {
            setTableData(null);
            return;
        }

        // Get Sales Rep data based on distributor
        (async () => {
            setShowLoader(true);

            await DistContactInfoService.getAllContactInfoData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {status, rows} = response.data;
                    setTableData({
                        odata: rows || [],
                        cdata: rows || []
                    });
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, []);

    // Edit distributor contact information
    const updateDistContactInfo = async (rowItems, cbks) => {
        setShowOverlayLoader(true);

        await DistContactInfoService.updateDistContactInfo({rowItems: rowItems, jdeAbNo: jdeAddrBookNo})
            .then(response => {
                // console.log('>response>', response);
                const {status, rows} = response.data;
                if (status.isOk) {
                    setTableData({
                        odata: rows || [],
                        cdata: rows || []
                    });
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
                setShowOverlayLoader(false)
                setTimeout(() => { cbks && cbks(); }, 3000);
            });
    }

    // useEffect hook to load all distributor product xref data
    useEffect(() => {
        (async () => {
            setShowLoader(true);

            await DistContactInfoService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setTableData(null);
                    setShowLoader(false);
                });
        })();
    }, [onDistributorChangeCallback])

    
    // Customized Grid Properties
	const customProps = {
		title: 'Distributor Contact Information',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader,
            showOverlayLoader: showOverlayLoader
        },
        vgrid: {
            id: 'idDistContactInfoCntr',
            rowData: tableData?.cdata,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension,
                actionColumnWidth: 80
            },
            pdialog: {
                showDialog: showDialog,
                dialogType: dialogType
            },
            distrsdata: {
                jdeAbNo: jdeAddrBookNo,
                allDistributors: allDistributorsData,
                onDistributorChange: onDistributorChangeCallback
            }
        }
    }

    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )
}