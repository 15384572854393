import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Autocomplete } from '@mui/material';
import { Container, Typography, TextField, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import clsx from 'clsx';
import { useStyles } from '../themes/style';
import { SMISDataTableGeo } from '../components/component.table.virtualized.geo';
import { GeographyRollupService } from '../services/service.geography.rollup';
// import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { env } from '../utils/env.variables';
// include utils module
require('../utils/utils');


// Severity level - error, info, success, warning
const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
};


// Geography rollup module
export const GeographyRollup = (props) => {
    const {dimensions: tableDimension/* leftDivRef */, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
	/* const {width} = useWindowDimensions();
    / * const _width = ((width - leftDivRef?.current?.clientWidth || 0) - 44);
    const _height = ((leftDivRef?.current?.clientHeight || 0) - 188); * /
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = ((leftDivRef?.current?.clientHeight || 500) - 170);
    const tableDimension = {width: _width, height: _height}; */

    const classes = useStyles();
    const btnCancelRef = useRef();
    const btnCloseRef = useRef();

    const [tableData, setTableData] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [geographyRollupData, setGeographyRollupData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [geoRollupDesc, setGeoRollupDesc] = useState([]);
    const [geoRollupTypes, setGeoRollupTypes] = useState([]);
    const [geoRollupReportTypes, setGeoRollupReportTypes] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    });
	const [dialogType, setDialogType] = useState({
        icon: SaveIcon,
        action: (e, d) => {}
    });
    const [showLoading, setShowLoading] = useState(false);
    const [callbacks, setCallbacks] = useState(null);
    const [rowPositionId, setRowPositionId] = useState(null);
    
	// Table column defination
	const tableColumns = [
        {label: 'RT', field: 'ROLL_UP_I', align: 'center', width: 60},
        {label: 'LN', field: 'ROLL_UP_LN_SEQ_N', /* align: 'center',  */width: 60, filter: true, sorting: true},
        {label: 'Rollup Level', field: 'ROLL_UP_LVL_D', width: 240, filter: true, sorting: true},
        {label: 'Level Description', field: 'ROLL_UP_LVL', width: 300, filter: true, sorting: true},
        {label: 'Rollup Description', field: 'ROLL_UP_LN_D', width: 260, filter: true, sorting: true},
        {label: 'Rpt Shading', field: 'RPT_SHAD_X', align: 'center', width: 100},
        {label: 'Rgn Rollup', field: 'RGN_RLUP_X', align: 'center', width: 100}
	];

	// Table options
	const tableOptions = {
		action: true,
		draggable: true,
        filtering: true,
        searching: true
	};

    // Table column search
    const tableColumnSearch = [
        'ROLL_UP_LVL_D', 
        'ROLL_UP_LVL', 
        'ROLL_UP_LN_D',
        'ROLL_UP_LN_SEQ_N'
    ];

    // Context Menu Items
    const contextMenuItems = [
        {
            id: 'copy',
            label: 'Copy',
            icon: ContentCopy,
            callback: (e, result) => {
                // console.log(result);
            }
        },
        {
            id: 'cut',
            label: 'Cut',
            icon: ContentCut,
            callback: (e, result) => {}
        },
        {
            id: 'paste',
            label: 'Paste',
            icon: ContentPaste,
            callback: (e, result) => {}
        },
        {
            id: 'cancel',
            label: 'Cancel',
            icon: CancelOutlinedIcon,
            callback: (e, result) => {}
        }
    ];

	// Callback for row actions
	const tableColumnActions = [
		{
            // Add rollup
            title: 'Add',
			icon: AddCircleOutlineOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;
                const {data_rollup_level_desc} = geographyRollupData;
                let _rowData = {
                        ...rowData,
                        ROLL_UP_INSERT_PN: 'P',
                        RPT_SHAD_X: 'N',
                        RGN_RLUP_X: 'N',
                        ROLL_UP_LN_D: ''
                    },
                    _dyn_rollup_desc = (data_rollup_level_desc.rows && data_rollup_level_desc.rows[_rowData.ROLL_UP_LVL_C]) || [];
                
                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setError(false);
                setErrorText('');
                setBtnDisabled(false);
				setSelectedRow(_rowData);
                setGeoRollupDesc(_dyn_rollup_desc);

				// open dialog to save
                setDialogType({
                    ...dialogType,
                    type: 'A',
                    title: 'Add',
                    icon: SaveIcon,
                    actionText: 'Save',
                    action: (e, newData) => {
                        setBtnLoading(true);
                        addGeographyRollup(e, {...newData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
			}
		},
		{
			// Edit rollup
            title: 'Edit',
			icon: EditOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;
                const {data_rollup_level_desc} = geographyRollupData;
                let _rowData = {
                        ...rowData,
                        ROLLUP_LBL_DESC: {
                            id: (rowData?.ROLLUP_LEVEL_TYPE_CODE || ''),
                            label: (rowData?.ROLL_UP_LVL || '')
                        },
                        ROLLUP_LEVEL_TYPE_CODE1: rowData.ROLLUP_LEVEL_TYPE_CODE
                    },
                    _dyn_rollup_level_desc = (data_rollup_level_desc.rows && data_rollup_level_desc.rows[_rowData.ROLL_UP_LVL_C]) || [];
                    
                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setError(false);
                setErrorText('');
                setBtnDisabled(false);
                setSelectedRow(_rowData);
                setGeoRollupDesc(_dyn_rollup_level_desc);
                
                setDialogType({
                    ...dialogType,
                    type: 'E',
                    title: 'Edit',
                    icon: SaveIcon,
                    actionText: 'Update',
                    action: (e, newData) => {
                        setBtnLoading(true);
                        updateGeographyRollup(e, _rowData, {...newData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		},
		{
			// Delete rollup
            title: 'Delete',
			icon: DeleteOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;
                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setSelectedRow({...rowData, is_multi: false});
                setBtnDisabled(false);

                // open dialog to delete
                setDialogType({
                    type: 'D',
                    title: 'Delete',
                    icon: DeleteOutlinedIcon,
                    actionText: 'Confirm',
                    action: (e, newData) => {
                        setBtnLoading(true);
                        deleteGeographyRollup(e, {...rowData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		}
	];

	// Callback for item drag end - table rows
	/* const handleDragDropRow = (result) => {
		const {source, destination, move, direction, rowData, onReorderItemsCallback} = result;

		let _sourceItem = rowData[source.index];
		let _destinationItemPrev = rowData[move.index1];
		let _destinationItemNext = rowData[move.index2];

        // Show loading
        setShowLoading(true);

		// Calculate item position to update
		_sourceItem = {..._sourceItem, is_multi: false, usrid: userInfo.upn}
		if (direction === 'UP') {
			_sourceItem = {..._sourceItem, position: _destinationItemNext.ROLL_UP_LN_SEQ_N};
		}
		else {
			_sourceItem = {..._sourceItem, position: _destinationItemPrev.ROLL_UP_LN_SEQ_N + 1};
		}

		// Re-order items - onReorderItemsCallback - function onReorderItemsCallback(list, startIndex, endIndex) {};
		if (onReorderItemsCallback) {
			const reorderedItems = onReorderItemsCallback(rowData, source.index, destination.index);
			setTableData(reorderedItems);
		}

		// Update item position to database
		updateGeographyRollupPosition(_sourceItem, (direction === 'UP' ? _destinationItemNext : _destinationItemPrev));
	}; */

    // Handle cut/paste row item    - have to look
    /* const handleCutPasteRow = (result) => {
        const {
            onClearRowSelection, 
            cutPasteActionType,
            sourceItems, 
            destinationItems: {destinationItemPosition, destinationItem}
        } = result;

        // handling validation - if source item empty
        if (!sourceItems || (sourceItems && sourceItems.length < 1)) {
            showSnackbarMessage(env.getEnv('REACT_APP_MSG_NO_ITEM_SELECTED'), severity_level.error);
            if (onClearRowSelection) {
                onClearRowSelection(null, true);
            }
            return;
        }

        // handling validation - if destination item empty
        if (!destinationItem || (destinationItem && destinationItem.length < 1)) {
            showSnackbarMessage(env.getEnv('REACT_APP_MSG_EMPTY_DESTINATION'), severity_level.error);
            if (onClearRowSelection) {
                onClearRowSelection(null, true);
            }
            return;
        }
        
        // Copy/move rollups in a new position
        cutPasteGeographyRollupsPosition(onClearRowSelection, sourceItems, {
            ...destinationItem, 
            position: destinationItemPosition, 
            action_type: cutPasteActionType,
            usrid: userInfo.upn
        });
    }; */

    // Miscellaneous action items
    const tableMiscActions = {
        // Drag and Drop handler
        onDragRow: (result) => {
            const {source, destination, move, direction, rowData, onReorderItemsCallback} = result;
    
            let _sourceItem = rowData[source.index];
            let _destinationItemPrev = rowData[move.index1];
            let _destinationItemNext = rowData[move.index2];
    
            // Show loading
            setShowLoading(true);
    
            // Calculate item position to update
            _sourceItem = {..._sourceItem, is_multi: false, usrid: userInfo.upn}
            if (direction === 'UP') {
                _sourceItem = {..._sourceItem, position: _destinationItemNext.ROLL_UP_LN_SEQ_N};
            }
            else {
                _sourceItem = {..._sourceItem, position: _destinationItemPrev.ROLL_UP_LN_SEQ_N + 1};
            }
    
            // Re-order items - onReorderItemsCallback - function onReorderItemsCallback(list, startIndex, endIndex) {};
            if (onReorderItemsCallback) {
                const reorderedItems = onReorderItemsCallback(rowData, source.index, destination.index);
                setTableData(reorderedItems);
            }
    
            // Update item position to database
            updateGeographyRollupPosition(_sourceItem, (direction === 'UP' ? _destinationItemNext : _destinationItemPrev));
        },

        // Handle cut/paste row item
        onCutPasteRow: (result) => {
            const {
                onClearRowSelection, 
                cutPasteActionType,
                sourceItems, 
                destinationItems: {destinationItemPosition, destinationItem}
            } = result;
    
            // handling validation - if source item empty
            if (!sourceItems || (sourceItems && sourceItems.length < 1)) {
                showSnackbarMessage(env.getEnv('REACT_APP_MSG_NO_ITEM_SELECTED'), severity_level.error);
                if (onClearRowSelection) {
                    onClearRowSelection(null, true);
                }
                return;
            }
    
            // handling validation - if destination item empty
            if (!destinationItem || (destinationItem && destinationItem.length < 1)) {
                showSnackbarMessage(env.getEnv('REACT_APP_MSG_EMPTY_DESTINATION'), severity_level.error);
                if (onClearRowSelection) {
                    onClearRowSelection(null, true);
                }
                return;
            }
            
            // Copy/move rollups in a new position
            cutPasteGeographyRollupsPosition(onClearRowSelection, sourceItems, {
                ...destinationItem, 
                position: destinationItemPosition, 
                action_type: cutPasteActionType,
                usrid: userInfo.upn
            });
        }
    }

	// Handle field value change
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setSelectedRow({...selectedRow, [name]: value});
    }

    // Handle field value change for rollup level
    const handleRollupChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const {data_rollup_level_desc} = geographyRollupData;
        let _dyn_rollup_level_desc = (data_rollup_level_desc.rows && data_rollup_level_desc.rows[value]) || [];
        
        setError(false);
        setErrorText('');
        setGeoRollupDesc(_dyn_rollup_level_desc);
        setSelectedRow({...selectedRow, [name]: value, ROLLUP_LBL_DESC: null});
    }

    // Disable / enable button
    const toggleButtonState = (btnRef, state) => {
        // Disable buttons
        btnRef.current.disabled = state || false;
        if (state) {
            btnRef.current.classList.add('Mui-disabled');
        }
        else {
            btnRef.current.classList.remove('Mui-disabled');
        }
    }

    // Close dialog
    const dialogClose = (e) => {
        setShowDialog(false);
        setTimeout(() => {
            setDialogType({});
            setBtnLoading(false);
            callbacks?.onClearRowSelection 
                && callbacks?.onClearRowSelection();
            setCallbacks(repackCallbacks('onClearRowSelection'));
        }, 1000);
    }

    // Rebuild / repack callbacks
    const repackCallbacks = (skips) => {
        if (!(skips instanceof Array)) {
            skips = [skips];
        }

        let _new_callbacks = null;
        Object.entries(callbacks || {}).forEach(([key, value]) => {
            if (!skips.includes(key)) {
                _new_callbacks = {..._new_callbacks, [key]: value};
            }
        });
        return _new_callbacks;
    }

	// Update items into database while drag and drop
    const updateGeographyRollupPosition = (sourceData, destinationData) => {
        // Update rollups data
        GeographyRollupService.updateGeographyRollupPosition({sourceData: sourceData, destinationData: destinationData})
            .then(response => {
                const {status, data_geography_rollups} = response.data;
                if (status.isOk) {
                    setTableData(data_geography_rollups.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_ROLLUP_POSITION_MOVED_SUCCESSFUL'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
			.catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
			})
            .finally(() => {
                setShowLoading(false);
            });
    }

    // Cut/Copy geography rollups and paste/update it in a new position
    const cutPasteGeographyRollupsPosition = (onClearRowSelection, sourceData, destinationData) => {
        const messageTypePhrase = {CUT: 'moved', COPY: 'copied'};
        let _isPartialClearSelection = false;

        // Show loading
        setShowLoading(true);

        // Copy/move rollups in a new position
        GeographyRollupService.cutPasteGeographyRollups({sourceData: sourceData, destinationData: destinationData})
            .then(response => {
                const {status, data_geography_rollups} = response.data;
                if (status.isOk) {
                    setTableData(data_geography_rollups.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_CUT_MOVED_SUCCESSFUL')
                        .format(messageTypePhrase[destinationData.action_type]), severity_level.success);
                }
                else {
                    _isPartialClearSelection = true;
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
			.catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
			})
            .finally(() => {
                setShowLoading(false);
                // onClearRowSelection - callback function to clear row selection
                if (onClearRowSelection) {
                    onClearRowSelection(null, _isPartialClearSelection);
                }
            });
    }

    // Add new geography rollup
    const addGeographyRollup = (e, newData) => {
        // Disable buttons
        toggleButtonState(btnCloseRef, true);
        toggleButtonState(btnCancelRef, true);
        setBtnLoading(true);

        // Get all geography rollups data
        GeographyRollupService.addGeographyRollup(newData)
            .then(response => {
                const {status, data_geography_rollups, ins_upd_pos_row_id} = response.data;
                if (status.isOk) {
                    setRowPositionId(ins_upd_pos_row_id);
                    setTableData(data_geography_rollups.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_ADD_SUCCESS'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
            .catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
            })
            .finally(() => {
                setSelectedRow({});
                setBtnLoading(false);
                setShowDialog(false);
                toggleButtonState(btnCloseRef, false);
                toggleButtonState(btnCancelRef, false);
                setTimeout(() => { setRowPositionId(null); }, 10000);
            });
    }

    // Edit/update geography rollup
    const updateGeographyRollup = (e, oldData, newData) => {
        // Disable buttons
        toggleButtonState(btnCloseRef, true);
        toggleButtonState(btnCancelRef, true);
        setBtnLoading(true);

        GeographyRollupService.updateGeographyRollup({old: oldData, new: newData})
            .then(response => {
                // console.log(response);
                const {status, data_geography_rollups, ins_upd_pos_row_id} = response.data;
                if (status.isOk) {
                    setRowPositionId(ins_upd_pos_row_id);
                    setTableData(data_geography_rollups.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
            .catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
            })
            .finally(() => {
                setSelectedRow({});
                setBtnLoading(false);
                setShowDialog(false);
                toggleButtonState(btnCloseRef, false);
                toggleButtonState(btnCancelRef, false);
                setTimeout(() => { setRowPositionId(null); }, 10000);
            });
    }
    
    // Delete geography rollup
    const deleteGeographyRollup = (e, rowData) => {
        // Disable buttons
        toggleButtonState(btnCloseRef, true);
        toggleButtonState(btnCancelRef, true);
        setBtnLoading(true);

        // Delete geography rollup data
        GeographyRollupService.deleteGeographyRollup(rowData)
            .then(response => {
                const {status, data_geography_rollups} = response.data;
                if (status.isOk) {
                    setTableData(data_geography_rollups.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_DELETE_SUCCESS'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
            .catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
			})
            .finally(() => {
                setSelectedRow({});
                setBtnLoading(false);
                setShowDialog(false);
                toggleButtonState(btnCloseRef, false);
                toggleButtonState(btnCancelRef, false);
            });
    }

	// Close Snackbar
	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
        // console.log(event?.type === 'click' || ['timeout', 'escapeKeyDown'].includes(reason));
    }

    // Show Snackbar message
    const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity])

    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    // userEffect hook to load geography rollups data during page load
    useEffect(() => {
        // Get geography rollups
        (async () => {
            await GeographyRollupService.getAllGeographyRollups()
                .then(response => {
                    const {data_geography_rollups, 
                        data_rollup_report_types, 
                        data_rollup_types} = response.data;
                    setGeographyRollupData(response.data);

                    // Rollup data
                    setTableData(data_geography_rollups.rows);
                    if (!data_geography_rollups.status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_ROLLUP_RETRIEVE_FAILED'), severity_level.error);
                    }
                    // Rollup report types
                    setGeoRollupReportTypes(data_rollup_report_types.rows);
                    // Rollup level types
                    setGeoRollupTypes(data_rollup_types.rows);
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                    setTimeout(() => setTableData([]), 5000);
                });
        })();
    }, [showSnackbarMessage])


	return (
		<>
			<SMISDataTableGeo
				title="Geography Rollups"
				columns={tableColumns}
				rows={tableData}
                tableDimension={{...tableDimension, width: tableDimension.width + 10, height: tableDimension.height + 20}}
                options={tableOptions}
				actions={tableColumnActions}
                miscActions={tableMiscActions}
				/* onDragRow={handleDragDropRow}
                onCutPasteRow={handleCutPasteRow} */
                isLoading={showLoading}
                alertMessage={showSnackbarMessage}
                contextMenuItems={contextMenuItems}
                searchColumns={tableColumnSearch}
                extras={{
                    rollup_rpt_types: geoRollupReportTypes,
                    isModifyEnabled: isModifyEnabled,
                    rowPositionId: rowPositionId
                }}/>

            {/* Add / Edit / Delete Geography Rollup Dialog */}
            <Dialog open={showDialog} style={{pointerEvent: 'auto'}} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <DialogTitle id="dialog-title">
                    <Typography align="left">{dialogType.title} Geography Rollup</Typography>
                    <IconButton ref={btnCancelRef} aria-label="close" onClick={dialogClose} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContent id="dialog-description">
                        {(dialogType.type === 'A' || dialogType.type === 'E') &&
                        <Container>
                            {dialogType.type === 'A' &&
                                <FormControl variant="standard" sx={{minWidth: 250}} className={classes.frmctldiv}>
                                    <InputLabel shrink id="rollup-insert-input-label">Insert Row</InputLabel>
                                    <Select labelId="rollup-insert-input-label" id="rollup-insert-select-label" name="ROLL_UP_INSERT_PN" defaultValue={'P'} onChange={handleChange}>
                                        <MenuItem value="P">Before selected row</MenuItem>
                                        <MenuItem value="N">After selected row</MenuItem>
                                    </Select>
                                </FormControl>
                            }

                            <FormControl fullWidth variant="standard" className={classes.frmctldiv1}>
                                <InputLabel shrink id="rollup-level-input-label">Rollup Level</InputLabel>
                                <Select labelId="rollup-level-input-label" id="rollup-level-select-label" name="ROLL_UP_LVL_C" value={selectedRow.ROLL_UP_LVL_C !== undefined ? selectedRow?.ROLL_UP_LVL_C : ''} defaultValue={''} onChange={handleRollupChange}>
                                    <MenuItem value={''}></MenuItem>
                                    {geoRollupTypes.map((element, idx) => (
                                        <MenuItem key={`${element}${idx}`} value={element.ROLL_UP_LVL_C}>{element.ROLL_UP_LVL_D}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{marginTop: 0, marginBottom: '1.2rem'}}>
                                <Autocomplete 
                                    options={(geoRollupDesc || []).map(el => ({
                                        id: el.ROLLUP_LEVEL_DESC_CODE,
                                        label: el.ROLLUP_LEVEL_DESC
                                    }))}
                                    id="level-desc-select-label" value={selectedRow.ROLLUP_LBL_DESC !== undefined ? selectedRow?.ROLLUP_LBL_DESC : ''}
                                    onChange={(e, nv) => {setSelectedRow({...selectedRow, ROLLUP_LEVEL_TYPE_CODE: nv?.id, ROLLUP_LBL_DESC: nv});}}
                                    isOptionEqualToValue={(o, v) => /* console.log(o, v) */(v === undefined || v === '' || o.id === v.id || v.id === '' || v?.label === '')}
                                    renderInput={(params) => <TextField {...params} error={error} helperText={errorText} label="Level Description" margin="normal" variant="standard"/>}/>
                            </FormControl>

                            <TextField fullWidth variant="standard" className={classes.frmctldiv} id="rollup-line-desc-input" onChange={handleChange} name="ROLL_UP_LN_D" {...(dialogType.type === 'E' && {value: selectedRow.ROLL_UP_LN_D || ''})} label="Rollup Description" />

                            <FormControl variant="standard" className={clsx(classes.frmctldiv, classes.w10em)}>
                                <InputLabel shrink id="report-shading-input-label">Report Shading</InputLabel>
                                <Select labelId="report-shading-input-label" id="report-shading-select-label" name="RPT_SHAD_X" {...(dialogType.type === 'E' && {value: selectedRow.RPT_SHAD_X || 'N'})} defaultValue={'N'} onChange={handleChange}>
                                    <MenuItem value="N">N</MenuItem>
                                    <MenuItem value="Y">Y</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" className={clsx(classes.frmctldiv, classes.w10em)} style={{float: 'right'}}>
                                <InputLabel shrink id="rgn-rollup-input-label">Region Rollup</InputLabel>
                                <Select labelId="rgn-rollup-input-label" id="rgn-rollu-select-label" name="RGN_RLUP_X" {...(dialogType.type === 'E' && {value: selectedRow.RGN_RLUP_X || 'N'})} defaultValue={'N'} onChange={handleChange}>
                                    <MenuItem value="N">N</MenuItem>
                                    <MenuItem value="Y">Y</MenuItem>
                                </Select>
                            </FormControl>
                        </Container>
                        }

                        {dialogType.type === 'D' &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography style={{color: '#ff0000'}} align="left">Please confirm to delete the selected record permanently.</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Report Type: {selectedRow.ROLL_UP_I}</TableCell>
                                    <TableCell>Line No: {selectedRow.ROLL_UP_LN_SEQ_N}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Rollup Level</TableCell>
                                    <TableCell colSpan={2}>{selectedRow.ROLL_UP_LVL_D}</TableCell>
                                </TableRow>
                                {selectedRow.ROLL_UP_LVL && 
                                <TableRow>
                                    <TableCell>Level Description</TableCell>
                                    <TableCell colSpan={2}>{/* selectedRow.ROLLUP_LEVEL_TYPE_CODE && `${selectedRow.ROLLUP_LEVEL_TYPE_CODE} - ` */}{selectedRow.ROLL_UP_LVL}</TableCell>
                                </TableRow>
                                }
                                {selectedRow.ROLL_UP_LN_D && 
                                <TableRow>
                                    <TableCell>Rollup Description</TableCell>
                                    <TableCell colSpan={2}>{selectedRow.ROLL_UP_LN_D}</TableCell>
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button loadingposition="start" startIcon={<CloseIcon/>} ref={btnCloseRef} onClick={dialogClose} variant="outlined">Close</Button>
                        <LoadingButton variant="contained" color="primary" loadingPosition="start" startIcon={<NavigateNextIcon/>}
                            {...(dialogType.icon && {startIcon: <dialogType.icon/>})} loading={btnLoading} disabled={btnDisabled}
                                onClick={(e) => dialogType.action(e, selectedRow)}>{dialogType.actionText}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>

			<Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity={snackbarMessage.severity} sx={{width: '100%'}}>{snackbarMessage.message}</Alert>
			</Snackbar>
		</>
	)
}
