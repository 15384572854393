import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FormControl, MenuItem, Select } from '@mui/material';


// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0 solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0 solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Extended dropdown
const StyledDropDownExd = styled(StyledDropDown)`
	margin-left: 0;
	&>.MuiInputBase-root {
		&>.MuiSelect-select {
			padding: 0;
			font-size: 0.675rem !important;
		}
	}
`;
// Row item text style div
const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	&>span {
		background-color: #ffff66;
        &.xclr {
            background-color: #ff8800b0;
        }
	}
`;
// List menu item style
const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;


// Inline edit component
export const EditInline = (props) => {
    // console.log('>props>>>', props);
    const {
        row,
        column,
        mactions,
        // selectedRow,
        // isRowMatched,
        isModifyEnabled,
        onCancelAction
        // onRowActionClick
    } = props;
    const [selectedRowField, setSelectedRowField] = useState({});
    const {edt: isEdit, typ: elType} = column?.iedit || {};

    const _textValue = column?.format && typeof row[column.field] === 'number' 
        ? column.format(row[column.field]) 
            : getMarkdownText(row[column.field]);
    // const newRowFieldName = isEdit ? `NV_${column.field}` : undefined;
    
    // onRowActionClick callback to perform specific action
    const onRowActionClickCallback = (e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}
        setSelectedRowField({
            [`NV_${e.target.name}`]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        
        rowAction?.onClick && 
            rowAction?.onClick(e, {rowItem: rowItem, cbks: onCancelAction});
    }
    
    return (
        <>
        {isEdit && isModifyEnabled 
            ? elType === 'select' && 
                <StyledDropDownExd variant={'standard'} sx={{width: '70%'}}>
                    <Select labelId="valid-flag-select-label" name={column.field} value={selectedRowField[`NV_${column.field}`] || row[column.field]} defaultValue={'N'} 
                        onChange={e => onRowActionClickCallback(e, {...row, [`NV_${column.field}`]: e.target.value}, mactions?.action1)}>
                        <StyledListItem value="N">N</StyledListItem>
                        <StyledListItem value="P">P</StyledListItem>
                        <StyledListItem value="Y">Y</StyledListItem>
                    </Select>
                </StyledDropDownExd>
            : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>}
        </>
    )
}

// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
