import React, { useState, useEffect } from 'react';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import { ProductXrefValidationService } from '../services/service.prod.xref.validation';


// Distributor Product Xref Validation Component
export const ProductXrefValidation = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [tableData, setTableData] = useState({
        odata: null,
        cdata: null
    });
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: FlagCircleOutlinedIcon,
        action: (e, d) => {}
    });
    const [showDialog, setShowDialog] = useState(false);

    SnackbarCfg.setState({
        state: setShowSnackbar
    })

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'Dist ID', field: 'JDE_ADDR_BOOK_N', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Market', field: 'MKT_D', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'State', field: 'ST_C', width: 60, visible: true, filter: true, sorting: true},
            {label: 'Affiliation', field: 'DIST_AFLTN_D', width: 120, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Dist Prod ID', field: 'DIST_PROD_I', width: 100, visible: true, filter: true, sorting: true},
            {label: 'Dist Prod Desc', field: 'DIST_PROD_D', width: 160, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Curr SKU', field: 'CURR_XREF_SKU', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Curr Prod Desc', field: 'CURR_DIST_PROD_DESC', width: 160, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Sugg SKU', field: 'SUGG_SKU', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Sugg Prod Desc', field: 'SUGG_PROD_DESC', width: 160, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Valid Flag', field: 'VLD_DIST_PROD_CNFG_X', width: 100, visible: true, sorting: true, iedit: {edt: true, typ: 'select'}},
            {label: 'Brand', field: 'PLN_BRAND_D', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Curr Ship Qty', field: 'CURR_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
            {label: 'Sugg Ship Qty', field: 'SUGG_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
            {label: 'Curr Depl Elig', field: 'DEPL_ELIG_X', width:110, visible: true, filter: true, sorting: true},
            {label: 'Sugg Depl Elig', field: 'SUGG_DEPL_ELIG_X', width: 110, visible: true, filter: true, sorting: true},
            {label: 'CS Qty', field: 'CS_Q', width: 80, visible: true, filter: true, sorting: true}
        ],
        // Popup dialog columns while select rows for an action to perform
        pdgcolumns: [
            {label: 'Dist #', field: 'JDE_ADDR_BOOK_N', width: 70, visible: true},
            {label: 'Dist Prod Desc', field: 'DIST_PROD_D', width: 240, visible: true},
            {label: 'Market', field: 'MKT_D', width: 140, visible: true},
            {label: 'Affiliation', field: 'DIST_AFLTN_D', width: 100, visible: true},
            {label: 'Curr SKU', field: 'CURR_XREF_SKU', width: 70, visible: true},
            {label: 'Sugg SKU', field: 'SUGG_SKU', width: 70, visible: true},
            {label: 'Valid Flag', field: 'VLD_DIST_PROD_CNFG_X', width: 70, visible: true, align: 'center'}
        ],
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'xrefvalidflag',
                label: 'XRef Validation',
                icon: FlagCircleOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'XREF_VLG_CFM',
                        icon: this.icon,
                        actionText: 'XRef Validate',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems, {cbks, dwv}) => {
                            (async () => {
                                await updateProdXrefData(newRowItems?.reduce((accu, curr) => [...accu, {...curr, NV_VLD_DIST_PROD_CNFG_X: dwv, AZR_USR_ID: userInfo?.upn}], []), cbks);
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            }
        ],
        mactions: {
            action1: {
                onClick: (e, {rowItem, cbks}) => {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    (async () => {
                        setShowOverlayLoader(true);
                        await updateProdXrefData({...rowItem, AZR_USR_ID: userInfo?.upn}, cbks);
                    })();
                }
            }
        },
        // Table options
        options: {
            rowclick: true,
            morecolumns: true,
            filtering: true,
            contextmenu: true,
            searching: true
        }
    }

    // Update product x-ref validation flag
    const updateProdXrefData = async (rowItems, cbks) => {
        await ProductXrefValidationService.updateProdXrefData(rowItems)
            .then(response => {
                const {status} = response.data;
                if (status.isOk) {
                    if (!(rowItems instanceof Array)) {
                        rowItems = [rowItems];
                    }
                    
                    let rowItemsNew = [];
                    const rowItemsUuids = rowItems?.reduce((accu, curr) => [...accu, curr?.UUID], []);
                    if (rowItems[0]?.NV_VLD_DIST_PROD_CNFG_X === 'Y') {
                        rowItemsNew = tableData.odata?.filter(o => !rowItemsUuids.includes(o?.UUID));
                    }
                    else {
                        rowItemsNew = tableData.odata?.reduce((accu, curr) => [
                            ...accu, 
                            {
                                ...curr, 
                                VLD_DIST_PROD_CNFG_X: (rowItemsUuids.includes(curr?.UUID) ? rowItems[0]?.NV_VLD_DIST_PROD_CNFG_X : curr?.VLD_DIST_PROD_CNFG_X)
                            }
                        ], []);
                    }
                    setTableData({
                        odata: rowItemsNew,
                        cdata: rowItemsNew
                    });
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
                setShowOverlayLoader(false);
                setTimeout(() => { cbks && cbks(); }, 3000);
            });
    }

    // useEffect hook to load all distributor product xref data
    useEffect(() => {
        (async () => {
            setShowLoader(true);

            await ProductXrefValidationService.getAllProdXrefData()
                .then(response => {
                    const {status, rows} = response.data;
                    setTableData({
                        odata: rows || [],
                        cdata: rows || []
                    });
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [])


    // Customized Grid Properties
	const customProps = {
		title: 'Distributor Product XRef Validation',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader,
            showOverlayLoader: showOverlayLoader
        },
        vgrid: {
            id: 'idPrdXrefValidationCntr',
            rowData: tableData?.cdata,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension
            },
            pdialog: {
                showDialog: showDialog,
                dialogType: dialogType
            }
        }
    }

    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )
}
