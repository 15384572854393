import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';


// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0 solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0 solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Extended dropdown
const StyledDropDownExd = styled(StyledDropDown)`
	margin-left: 0;
	&>.MuiInputBase-root {
		&>.MuiSelect-select {
			padding: 0;
			font-size: 0.675rem !important;
		}
	}
`;
// List menu item style
const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;
// Row item text style div
const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	&>span {
		background-color: #ffff66;
        &.xclr {
            background-color: #ff8800b0;
        }
	}
`;
// Style text filed
const StyledTextField = styled(TextField)`
    margin: 0;
    &>.MuiInputBase-root {
        &>.MuiInputBase-input {
            font-size: 0.675rem;
            // background-color: #cae5ff;
        }
        &:hover:not(.Mui-disabled, .Mui-error):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
        &:before {
            border-bottom-style: dotted;
        }
        &:after {
            border-bottom: 1px solid #1976d2
        }
    }
`;

export const FieldTypes = {
    TEXT: 'INPUT',
    TEXTAREA: 'TEXTAREA',
    SELECT: 'SELECT'

}

// Inline easy edit component
export const EasyEdit = (props) => {
    const {
        row,
        iedit,
        column,
        isModify,
        changedRowValue,
        changedRowValue1,
        setChangedRowValue,
        setChangedRowValue1
    } = props;
    const [/* selectedRowItem */, setSelectedRowItem] = useState();
    const _textValue = column?.format && typeof row[column.field] === 'number' 
        ? column.format(row[column.field]) 
            : getMarkdownText(row[column.field]);

    // Input/Textarea/Dropdown value change callback
    const onChangeCallback = (e, rowItem) => {
        const name = e.target.name;
        // const ofld_name = `O_${name}`;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        
        let value_n = (name === 'CNTC_NAME') ? value : changedRowValue1[rowItem?.ROW_ID]?.CNTC_NAME ?? rowItem?.CNTC_NAME,
            value_e = (name === 'CNTC_EMAIL') ? value : changedRowValue1[rowItem?.ROW_ID]?.CNTC_EMAIL ?? rowItem?.CNTC_EMAIL,
            value_p = (name === 'CNTC_PHONE') ? value : changedRowValue1[rowItem?.ROW_ID]?.CNTC_PHONE ?? rowItem?.CNTC_PHONE;
        // console.log('>value_n>value_e>value_p>', changedRowValue1, value_n, value_e, value_p);

        setChangedRowValue(prev => ({
            ...prev,
            [rowItem?.ROW_ID]: {
                ...prev[rowItem?.ROW_ID],
                [name]: value
            },
            itms: [...new Set([...(prev?.itms || []), rowItem?.ROW_ID])]
        }));
        setChangedRowValue1(prev => ({
            ...prev,
            [rowItem?.ROW_ID]: {
                ...prev[rowItem?.ROW_ID],
                CNTC_NAME: value_n,
                CNTC_EMAIL: value_e,
                CNTC_PHONE: value_p,
                // [name]: value,
                _row: rowItem
            },
            itms: [...new Set([...(prev?.itms || []), rowItem?.ROW_ID])]
        }));

        /* setChangedRowValue(prev => ({
            ...prev,
            [name]: value
        })); */
    }

    // onRowActionClick callback to perform specific action
    const onRowActionClickCallback = (e, rowItem/* , rowAction */) => {
		if (!isModify) {
			return false;
		}
        setSelectedRowItem({
            [`NV_${e.target.name}`]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        
        /* rowAction?.onClick && 
            rowAction?.onClick(e, {rowItem: rowItem, cbks: onCancelAction}); */
    }

    // Load data and show dialog
    useEffect(() => {
        // console.log('>>>useEffect-easyedit', row, column?.field, row[column?.field]);
        setChangedRowValue(prev => ({
            ...prev,
            [row?.ROW_ID]: {
                ...prev[row?.ROW_ID],
                [column?.field]: row[column?.field],
                _row: row
            },
            itms: [...new Set([...(prev?.itms || []), row?.ROW_ID])]
        }));
        // setSelectedRowItem(row);
    }, [row, column, setChangedRowValue]);
    // console.log('>>>changedRowValue>', changedRowValue);
    
    return (
        <>
            {(isModify && iedit?.edit /* && iedit?.id === row?.UUID */ && column?.editable?.edit) 
                ? (() => {
                        switch(column?.editable?.type) {
                            case FieldTypes.TEXT:
                                return <TextRenderer row={row} column={column} selectedRow={changedRowValue} onChange={onChangeCallback}/>
                            case FieldTypes.SELECT:
                                return <SelectRenderer row={row} column={column} selectedRow={changedRowValue} onRowActionClick={onRowActionClickCallback}/>
                            default:
                                return null
                        }
                    })()
                : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>}

            {/* {(isModify && iedit?.edit && iedit?.id === selectedRowItem?.UUID && column?.editable?.edit) 
                ? <StyledTextField variant="standard" sx={{width: `${column?.width}px`, marginLeft: 6, marginRight: 6}} id={`${column?.field}-${selectedRowItem?.UUID}`} 
                    onChange={e => onChangeCallback(e, selectedRowItem)} name={column.field} value={selectedRowItem[column?.field] || ''}/>
                : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
            } */}
        </>
        
        
    )
}

// Text input field renderer
const TextRenderer = React.memo(props => {
    const {row, column, selectedRow, onChange} = props;
    const _value = selectedRow[row?.ROW_ID] && selectedRow[row?.ROW_ID][column?.field];

	return (
		<StyledTextField variant="standard" sx={{width: `${column?.width}px`, marginLeft: 6, marginRight: 6}} id={`${column?.field}-${row?.UUID}`} 
            onChange={e => onChange(e, row)} name={column.field} value={_value || ''}/>
	)
})

// Select/Dropdown field renderer
const SelectRenderer = React.memo(props => {
    const {column, row, selectedRow, onRowActionClick} = props;

	return (
		<StyledDropDownExd variant={'standard'} sx={{width: '70%'}}>
            <Select labelId="valid-flag-select-label" name={column.field} value={selectedRow[`NV_${column.field}`] || row[column.field]} defaultValue={'N'} 
                onChange={e => onRowActionClick(e, {...row, [`NV_${column.field}`]: e.target.value}/* , mactions?.action1 */)}>
                <StyledListItem value="N">N</StyledListItem>
                <StyledListItem value="P">P</StyledListItem>
                <StyledListItem value="Y">Y</StyledListItem>
            </Select>
        </StyledDropDownExd>
	)
})




// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
