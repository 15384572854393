import { Typography } from '@mui/material';

// Copyright section
const Copyright = () => {
    return (
        <Typography sx={{fontSize: '0.675rem'}} variant="body2" align='center'>
            Copyright &copy;{new Date().getFullYear()} E.&amp;J. Gallo Winery. All rights reserved.
        </Typography>
    )
};

// Footer section
export const Footer = (props) => {
	return (
		<footer>
			<Copyright />
		</footer>
	)
}
