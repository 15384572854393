import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { List } from 'react-virtualized';
import { ConstCfg } from '../utils/utils';
import { EditInline } from './component.iedit';
import * as ColumnResizer  from 'react-draggable';
import { SearchBoxDialog } from './component.search.dialog';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { CircularProgressLoader, OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import { Autocomplete, Checkbox, Divider, FormControl, IconButton, Input, InputAdornment, Menu, MenuItem, Paper, TableSortLabel, TextField } from '@mui/material';
import { PopupDialog } from './component.popup.dialog';
import { EasyEdit } from './component.easyedit';


// Table main container
export const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
export const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`;
// Caption bar div style
export const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1.2em;
	padding: 0.4em 0.1em;
    // background-color: #0e7eed1a;
`;
// Table title style
export const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;
// Place holder inner content div
export const StyledPlaceHolderContainerDiv = styled.div`
	overflow-x: auto;
`;
// Place holder inner content div
export const StyledPlaceHolderInnerContainerDiv = styled.div`
    width: 100%;
`;
// No records found div
export const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;
// Column field name / data div style
export const StyledColumnContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    height: 3em;
	padding-right: 10px;
	user-select: none;
`;
// Item row container style
export const StyledRowItemContainer = styled(StyledColumnContainerDiv)`
    // border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #dfdfdf;
`;
// Column filter div style
export const StyledFilterContainerDiv = styled(StyledColumnContainerDiv)`
	border-top: 0 none;
	border-bottom: 1px solid #cbcbcb7a;
	background-color: #f1f1f159;
`;
// Row item / data div style
export const StyledRowItemDiv = styled.div`
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
    white-space: nowrap;
	margin-left: 0.7em;
    min-width: 40px;
    height: 100%;
`;
// Filter row field div style
export const StyledFilterItemDiv = styled(StyledRowItemDiv)`
	&>.MuiInputBase-root {
		font-size: inherit;
		&>.MuiInputBase-input {
            padding: 3px 2px;
		}
		&>.MuiInputAdornment-root {
			margin-right: 2px;
		}
	}
	&>.MuiInputBase-root:before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 1px solid #1976d2;
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
`;
// Column filter div style
export const StyledFilterInput = styled(Input)`
    width: 96%;
	background-color: #ffffff;
`;
// Column sort div style
export const StyledColumnSortDiv = styled(TableSortLabel)`
	flex: auto;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: inherit !important;
	&>.MuiTableSortLabel-icon {
		width: 0.6em;
		margin-left: 2px;
		margin-right: 2px;
	}
`;
// Row item text style div
export const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	&>span {
		background-color: #ffff66;
	}
`;
// Row action item style div
export const StyledActionItem = styled.span`
    // flex-grow: 1;
    padding: 0.1em;
    display: flex;
    &>.MuiButtonBase-root {
        &>.MuiSvgIcon-root {
            width: 0.68em;
            height: 0.68em;
        }
    }
`;
// List menu item style
export const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;
// Column resizer disable div style
export const StyledColumnResizeDisableDiv = styled.span`
    z-index: 2;
	display: flex;
	flex: 0 0 .2em;
	color: #cbcbcb;
`;
// Column resizer div style
export const StyledColumnResizeHandler = styled(StyledColumnResizeDisableDiv)`
    cursor: col-resize;
	background-color: inherit !important;
	&:hover, &:active {
		z-index: 3;
		color: #646464;
		background-color: #cbcbcbba !important;
	}
`;
// Place holder inner content div
export const StyledPlaceHolderDiv = styled.div`
    padding: .1em 0;
`;
// Action Menu handler div style
const StyledActionMenuDiv = styled.div`
	display: flex;
	margin-left: 1.5em;
	align-items: center;
	& .MuiIconButton-root {
		padding: 0 5px;
	}
	& .MuiIconButton-root:last-child {
		padding: 0 0 0 5px;
		/* & .MuiSvgIcon-root {
			width: 0.9em !important;
			height: 0.9em !important;
		} */
	}
`;
// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 30%;
    margin-left: 1.5em;
    // margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 0;
        &>.MuiInputLabel-root {
            top: -16px;
            font-size: 0.875rem;
        }
        &>.MuiInputBase-root {
            margin-top: 0;
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;


/**
 * Material virtualized data grid component
 */
export const VirtualizedDataGrid1 = (props) => {
    const {
        title, isModifyEnabled, 
        loader: {showLoader, showOverlayLoader},
        vgrid: {
            psearch={},
            pdialog={},
            distrsdata={},
            tblExtActProps={},
            rowData, tblProps, tblGridData,
            dimensions/* , selectedRow */
        },
        ComponentRenderer=null
    } = props;
    
    const [sortColumnOrder, setSortColumnOrder] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
    const [gridRowData, setGridRowData] = useState({
        odata: rowData,
        fdata: rowData,
        cdata: rowData
    });
    // const [selectedRow, setSelectedRow] = useState({});
    const [changedRowValue, setChangedRowValue] = useState({});
    const [changedRowValue1, setChangedRowValue1] = useState({});
    const [inlEdit, setInlEdit] = useState({
        edit: false,
        action: {},
        id: null
    });
    const [filterColumns, setFilterColumns] = useState();
	const [scrollToIndex, setScrollToIndex] = useState(-1);
    const [contextMenu, setContextMenu] = useState(null);
    const [ctxMenuActionState, setCtxMenuActionState] = useState({});
    const [rowItemsToProcess, setRowItemsToProcess] = useState({
        sourceItems: [],
        itemUids: []
    });
    const [gridColumnWidths, setGridColumnWidths] = useState({/* _ACT_COL: dimensions?.actionColumnWidth || 80 */});
    const [xRefGridColumns, setXRefGridColumns] = useState(tblProps?.columns || []);
    const [gridColumnWidthTotal, setGridColumnWidthTotal] = useState(dimensions?.width);
    const ActionColumnWidth = tblProps?.options?.action ? dimensions?.actionColumnWidth || 60 : 0;
    

    // Initialize columns width
    const initColumnsWidth = (columns) => {
        /* let columnsWidth = {};
        columns?.forEach(({field, width=60, visible=false}) => (columnsWidth = {
            ...columnsWidth, 
            [field]: visible && width ? width : 0
        })); */
        return columns.reduce((column, {field, width=60, visible=false}) => {column[field] = visible && width ? width : 0; return column;}, {});
    }

    // Calculate columns total width
    const initColumnsTotalWidth = (columns, actionColWidth) => {
        return (columns?.reduce((prev, curr) => curr?.visible && curr?.width ? prev + curr?.width : prev, 0) + actionColWidth);
    }
    
    // Re-calculate and initialize columns
    const reInitColumns = (columns, item) => {
        return columns.map(column => (column.field === item.field ? {...column, visible: !item?.visible} : column));
    }

    // Get filtered data
    const getFilteredData = (data, fcolumns) => {
        return data?.filter(d => Object.entries(fcolumns)
            .every(([key, val]) => !val?.length || (d[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())));
    }

    // Get context menu action states
    const getCtxMenuActionStates = (ctxmenus, disabled=true, clicked=false) => {
        return ctxmenus?.reduce((menu, {id}) => {menu[id] = {disable: disabled, clicked: clicked}; return menu;}, {});
    }

    // Calculate grid width based on columns
    const getGridWidth = useCallback((calculatedWidth) => {
        const {width} = dimensions;
        return calculatedWidth > width ? calculatedWidth : (width || 100);
    }, [dimensions])

    // onChangeColumnMenuItemCallback to show / hide grid columns
    const onChangeColumnMenuItemCallback = useCallback((e, item, isAllChecked) => {
        const columns = [...xRefGridColumns];

        // Re-initialize columns
        // const xRefGridColumnsNew = reInitColumns(columns, item);
        const xRefGridColumnsNew = item ? reInitColumns(columns, item) : columns.map(column => ({...column, visible: isAllChecked}));
        setXRefGridColumns(xRefGridColumnsNew);

        // Reset columns width as per column visibility
        const gridColumnWidthsNew = initColumnsWidth(xRefGridColumnsNew);
        setGridColumnWidths(prev => ({...prev, ...gridColumnWidthsNew}));

        // Set grid columns total width
        const gridColumnsWidthTotalNew = getGridWidth(initColumnsTotalWidth(xRefGridColumnsNew, ActionColumnWidth));
        setGridColumnWidthTotal(gridColumnsWidthTotalNew);
    }, [xRefGridColumns, getGridWidth, ActionColumnWidth])

    // onSortColumn callback to sort column
    const onSortColumnCallback = useCallback((e, props) => {
        const {sortCol, altSortCol} = props;
        const sortColumnField = altSortCol || sortCol;
        let {sortBy, sortOrder} = {...sortColumnOrder};

        // Set sort order and sort by value
        if (sortBy === sortColumnField) {
            sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        else {
            sortBy = sortColumnField;
            sortOrder = 'asc';
        }
        
        // Set column ordering
        setSortColumnOrder(prev => ({
            ...prev,
            sortBy: sortBy,
            sortOrder: sortOrder
        }));

        // Set sorted data
        setGridRowData(prev => ({
            ...prev,
            cdata: prev.cdata?.sort((i, j) => {
                if (i[sortColumnField] < j[sortColumnField]) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (i[sortColumnField] > j[sortColumnField]) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            })
        }));
    }, [sortColumnOrder])
    
    // onFilterChangeCallback callback to filter column data
    const onFilterChangeCallback = useCallback(({target: {name, value}}) => {
        const {odata} = gridRowData;
        const filterColumnsNew = {...filterColumns, [name]: value};
        // console.log('>>>>>>', odata);
        // Filtered data
        /* const filteredData = odata?.filter(data => Object.entries(filterColumnsNew)
            .every(([key, val]) => !val?.length || (data[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase()))); */
        const filteredData = getFilteredData(odata, filterColumnsNew);

        // Set new filter object and data
        setFilterColumns(filterColumnsNew);
        setGridRowData(prev => ({
            ...prev,
            fdata: filteredData,
            cdata: filteredData
        }));
    }, [filterColumns, gridRowData])
    
    // onResizeColumn callback to resize column while dragging
    const onResizeColumnCallback = useCallback((e, props) => {
        let nextDataKey = '';
        const columns = [...xRefGridColumns];
        const {dataKey, deltaX, gridWidth} = props;
        const percentDelta = (deltaX / gridWidth) * 1000;
        columns.forEach((column, i) => {
            if (column?.field === dataKey) {
                nextDataKey = columns[i + 1]?.field || null; //'_ACT_COL';
            }
        });
        setGridColumnWidths(prev => ({
            ...prev,
            [dataKey]: prev[dataKey] + percentDelta,
            [nextDataKey]: prev[nextDataKey] - percentDelta
        }));
    }, [xRefGridColumns])

    // onCancelActionCallback to cancel context menu/row action
	const onCancelActionCallback = useCallback(e => {
		if (!isModifyEnabled) {
			return false;
		}
        setRowItemsToProcess({
            sourceItems: [],
            itemUids: []
        });
        // Set context menu states
        setCtxMenuActionState(getCtxMenuActionStates(tblProps?.ctxmenus));
	}, [isModifyEnabled, tblProps?.ctxmenus]);

    // onRowClickCallback callback to perform specific action on row item
    const onRowClickCallback = useCallback((e, rowItem, index) => {
		if (!isModifyEnabled) {
			return;
		}
        // console.log('>>>>>>>>>>', inlEdit);
        // If edit enabled, return
        if (inlEdit?.edit/*  && inlEdit?.id === rowItem?.UUID */) {
            return;
        }

        const {cdata} = gridRowData;
        const {sourceItems, itemUids} = rowItemsToProcess;
        let sourceItemsNew = [],
            itemUidsNew = [];
        
        // Shift Key + Mouse left button click
        if (e.shiftKey) {
            const firstSelectedUid = itemUids[0] || rowItem.UUID;
            const indexFound = cdata.findIndex(item => item.UUID === firstSelectedUid);
            const startIndex = indexFound > index ? index : indexFound,
                endIndex = indexFound < index ? index : indexFound;
            sourceItemsNew = cdata.filter((_, idx) => idx >= startIndex && idx <= endIndex);
            itemUidsNew = sourceItemsNew?.reduce((item, {UUID}) => {item.push(UUID); return item;}, []);
        }
        // Ctrl Key + Mouse left button click
        else if (e.ctrlKey) {
            const itemFound = sourceItems.find(o => o.UUID === rowItem.UUID);
            sourceItemsNew = sourceItems.filter(o => o.UUID !== rowItem.UUID);
            itemUidsNew = itemUids.filter(uuid => uuid !== rowItem.UUID);
            if (!itemFound) {
                sourceItemsNew.push(rowItem);
                itemUidsNew.push(rowItem.UUID);
            }
        }
        // Mouse left button click
        else {
            if (itemUids[0] !== rowItem.UUID) {
                sourceItemsNew.push(rowItem);
                itemUidsNew.push(rowItem.UUID);
            }
        }
        setRowItemsToProcess({
            sourceItems: sourceItemsNew,
            itemUids: itemUidsNew
        });
        // Set context menu states
        setCtxMenuActionState(getCtxMenuActionStates(tblProps?.ctxmenus, !itemUidsNew?.length));
    }, [isModifyEnabled, gridRowData, tblProps?.ctxmenus, rowItemsToProcess, inlEdit])

    // onRowActionClick callback to perform specific action
    const onRowActionClickCallback = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}
        setRowItemsToProcess({
            sourceItems: [rowItem],
            itemUids: [rowItem?.UUID]
        });
        // Set context menu states
        setCtxMenuActionState(getCtxMenuActionStates(tblProps?.ctxmenus));

        // Trigger inline edit handler if any
        /* if (rowAction?.iedit) {
            setInlEdit({
                edit: true,
                action: rowAction,
                id: rowItem?.UUID
            });
            setChangedRowValue(rowItem);
        } */
        
        // Trigger callback if any
        (!rowAction?.iedit && rowAction?.onClick) && 
            rowAction?.onClick(e, {rowItem: rowItem, gdata: gridRowData?.cdata});
    }, [isModifyEnabled, tblProps?.ctxmenus, gridRowData?.cdata])

    // onSaveCancelClickCallback callback to perform specific action
    const onSaveCancelClickCallback = useCallback((e, rowItem, action, type) => {
        // Cancel
        if (type === 'CANCEL') {
            onCancelEditCallback();
            /* setInlEdit({
                edit: false,
                action: {},
                id: null
            });
            setRowItemsToProcess({
                sourceItems: [],
                itemUids: []
            });
            setChangedRowValue({}); */
        }

        // Save
        if (type === 'SAVE') {
            action?.onClick && 
                action?.onClick(e, {rowItem: rowItem, cbks: onCancelEditCallback});
        }
    }, [])

    // onSaveCancelClickCallback callback to perform specific action
    const onCancelEditCallback = () => {
        setInlEdit({
            edit: false,
            action: {},
            id: null
        });
        setRowItemsToProcess({
            sourceItems: [],
            itemUids: []
        });
        setChangedRowValue({});
    }
    
    // onSearch callback
    const onSearchCallback = useCallback((e, result) => {
        const {foundFirstIndex, searchData: {odata, cdata}} = result;
        // console.log('>', odata, cdata);
        // const filteredData = getFilteredData(cdata, filterColumns);
        setGridRowData(prev => ({
            ...prev,
            odata: odata ? odata : prev.odata,
            // fdata: odata ? filteredData : prev.fdata,
            cdata: cdata
        }));
		setScrollToIndex(foundFirstIndex);
    }, [/* filterColumns */])

    // onSearchClear callback
    const onSearchClearCallback = useCallback((e, {odata}) => {
        // console.log('>>>', odata);
        setGridRowData(prev => ({
            ...prev,
            // odata: odata,
            cdata: odata
        }));
        setScrollToIndex(-1);
    }, [])
    
    // onSearchFirstCallback callback
    const onSearchFirstCallback = useCallback((e, searchedIndex, cdata) => {
        setGridRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchLastCallback callback
    const onSearchLastCallback = useCallback((e, searchedIndex, cdata) => {
        setGridRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchPrevCallback callback
    const onSearchPrevCallback = useCallback((e, searchedIndex, cdata) => {
        setGridRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchNextCallback callback
    const onSearchNextCallback = useCallback((e, searchedIndex, cdata) => {
        setGridRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(searchedIndex);
    }, [])

    // onContextMenuClickCallback context menu on right click
	const onContextMenuClickCallback = useCallback(e => {
		if (!isModifyEnabled) {
			return false;
		}
        // Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

		// Set context menu position
        setContextMenu(prev => prev === null ? {mouseX: e.clientX + 4, mouseY: e.clientY + 4} : null);
	}, [isModifyEnabled]);
    
    // onContextMenuItemClickCallback context menu callback on right click  ---??????
    const onContextMenuItemClickCallback = useCallback((e, ctxmenu) => {
        if (!isModifyEnabled) {
            return false;
        }

        // Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

        // Set context menu to null to disappear
        setContextMenu(null);
        if (ctxmenu === 'backdropClick') {
            return;
        }
        const {sourceItems} = rowItemsToProcess;

        // Trigger callback internally if there is any
        // eslint-disable-next-line no-eval
        try { eval(`on${ctxmenu?.id?.charAt(0)?.toUpperCase() + ctxmenu?.id?.slice(1)}ActionCallback?.(e)`); } catch(e) {}
        // console.log('>>>>changedRowValue>>', changedRowValue, changedRowValue1);
        // Callback if there is any
        ctxmenu?.callback && 
            ctxmenu.callback(e, {rowItems: ctxmenu?.isExtraAct ? changedRowValue1 : sourceItems});
    }, [isModifyEnabled, rowItemsToProcess, /* changedRowValue,  */changedRowValue1])

    // Popup Dialog close callback
    const onDialogCloseCallback = useCallback(e => {
        onCancelActionCallback(e);
    }, [onCancelActionCallback])

    // userEffect hook to load column names if changes
    useEffect(() => {
        // console.log('>useEffect 0>');
        tblProps?.options?.dynscols && 
            setXRefGridColumns(tblProps?.columns);
    }, [tblProps?.columns, tblProps?.options?.dynscols])
    
    // userEffect hook to load data if changes
    useEffect(() => {
        // console.log('>useEffect 1>');
        const columns = [...xRefGridColumns];
        
        // Set context menu states
        setCtxMenuActionState(getCtxMenuActionStates(tblProps?.ctxmenus));

        // Set filter columns
        !filterColumns && 
            setFilterColumns(columns.reduce((column, {field}) => {column[field] = ''; return column;}, {}));
        
        // Set indivisual grid columns width
        const gridColumnWidthsNew = initColumnsWidth(columns);
        setGridColumnWidths(prev => ({...prev, ...gridColumnWidthsNew}));

        // Set grid columns total width
        const gridColumnsWidthTotalNew = getGridWidth(initColumnsTotalWidth(columns, ActionColumnWidth));
        setGridColumnWidthTotal(gridColumnsWidthTotalNew);
	}, [xRefGridColumns, tblProps?.ctxmenus, filterColumns, getGridWidth, ActionColumnWidth]);

    // userEffect hook to load data on page load
    useEffect(() => {
        const filteredData = getFilteredData(rowData, filterColumns);
        setGridRowData(prev => ({
            ...prev,
            fdata: filteredData,
            odata: tblGridData?.odata || rowData,
            cdata: tblGridData?.gdata || filteredData
        }));
        /* setGridRowData(prev => ({
            ...prev,
            fdata: getFilteredData(prev?.odata || rowData, filterColumns),
            cdata: getFilteredData(prev?.odata || rowData, filterColumns)
        })); */
        // console.log('>useEffect 2>', rowData, filteredData, tblGridData);
        // setChangedRowValue({});
        // console.log(dimensions);
    }, [rowData, filterColumns, tblGridData/* , dimensions */])

    // console.log(dimensions);
	// Customized Grid Properties
	const gridCustomProps = {
        title: title,
        showLoader: showLoader,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            gridData: gridRowData,
            // selectedRow: selectedRow,
            scrollToIndex: scrollToIndex,
            selectedRowItems: rowItemsToProcess,
            tblExtActProps: tblExtActProps,
            tblProps: {
                ...tblProps, 
                fcolumns: xRefGridColumns
            },
            dimensions: {
                gridWidth: dimensions?.width,
                // gridHeight: dimensions?.gheight || 480/*495 dimensions?.height */,
                gridHeight: dimensions?.gheight || dimensions?.height,
                gridRSCount: Math.floor((dimensions?.gheight || dimensions?.height)/30) - 3,
                gridColWidths: gridColumnWidths,
                actionColWidth: ActionColumnWidth,
                gridTotalWidth: gridColumnWidthTotal
            },
            psearch: {
                ...psearch,
                gridData: gridRowData,
                columns: xRefGridColumns,
                callbacks: {
                    onSearch: onSearchCallback,
                    onSearchClear: onSearchClearCallback,
                    onSearchFirst: onSearchFirstCallback,
                    onSearchLast: onSearchLastCallback,
                    onSearchPrev: onSearchPrevCallback,
                    onSearchNext: onSearchNextCallback
                }
            },
            psorts: {
                sortColumnOrder: sortColumnOrder,
                onSortColumn: onSortColumnCallback
            },
            callbacks: {
                onFilterChange: onFilterChangeCallback,
                onResizeColumn: onResizeColumnCallback,
                onRowClick: onRowClickCallback,
                onCancelAction: onCancelActionCallback,
                onRowActionClick: onRowActionClickCallback
            },
            pctxmenu: {
                contextMenu: contextMenu,
                ctxMenuActState: ctxMenuActionState,
                isCtxMenuOpen: contextMenu !== null,
                ctxopts: tblProps?.options,
                ctxmenus: tblProps?.ctxmenus,
                onContextMenuClick: onContextMenuClickCallback,
                onContextMenuItemClick: onContextMenuItemClickCallback
            },
            dwcolmenu: {
                onChangeColumnMenuItem: onChangeColumnMenuItemCallback
            },
            pdialog: {
                title: title,
                columns: tblProps?.pdgcolumns,
                callbacks: {
                    onDialogClose: onDialogCloseCallback
                },
                ...pdialog
            },
            pdistrs: {
                ...distrsdata
            },
            piedit: {
                iedit: inlEdit,
                changedRowValue: changedRowValue,
                changedRowValue1: changedRowValue1,
                setChangedRowValue: setChangedRowValue,
                setChangedRowValue1: setChangedRowValue1,
                onSaveCancelClick: onSaveCancelClickCallback
            },
            ComponentRenderer: ComponentRenderer
        }
    }

    return (
		<Container>
            {(tblProps?.options?.snackbar || tblProps?.options?.snackbar === undefined) && 
                <SnackbarAlert/>}
            {(tblProps?.options?.popdialog || tblProps?.options?.popdialog === undefined) && 
                <PopupDialog {...gridCustomProps.vgrid.pdialog}/>}
            <VirtualizedGridContainer {...gridCustomProps}/>
            {(tblProps?.options?.overlay || tblProps?.options?.overlay === undefined) && 
                <OverlayLoader loading={showOverlayLoader}/>}
        </Container>
    )
}


// Main Virtualized Grid Container
const VirtualizedGridContainer = React.memo(props => {
    const {
        vgrid: {
            // pdialog,
            dimensions: {gridWidth}
        }
    } = props;

    return (
        <TableRowContainer style={{width: `${gridWidth}px`}}>
            <TableCaptionBar {...props}/>
            <TableGridContainer {...props}/>
            {/* <PopupDialog {...pdialog}/> */}
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        isModifyEnabled,
        vgrid: {
            pdistrs,
            psearch,
            dwcolmenu,
            pctxmenu,
            gridData: {
                cdata: rowData
            },
            tblExtActProps,
            tblProps: {fcolumns, options},
            ComponentRenderer
        }
    } = props;

    return (
        <StyledCaptionBar style={{backgroundColor: options?.titlecolor ? '#0e7eed1a' : 'inherit'}}>
            <Title>{title} ({(rowData && rowData?.length) || 0})</Title>
            {options?.distdwmenu && 
                <DistributorDwRenderer pdistrs={pdistrs}/>}
            {options?.searching && 
                <SearchBoxDialog {...psearch}/>}
            {ComponentRenderer && 
                <ComponentRenderer/>}
            {options?.morecolumns && 
                <MoreColumnsRenderer columns={fcolumns} dwcolmenu={dwcolmenu}/>}
            {options?.contextmenu && 
                <ContextMenuRenderer {...pctxmenu} tblExtActProps={tblExtActProps} isModifyEnabled={isModifyEnabled}/>}
        </StyledCaptionBar>
    )
})

// Distributor dropdown
const DistributorDwRenderer = React.memo(({pdistrs}) => {
    const {jdeAbNo, allDistributors, onDistributorChange} = pdistrs;
    
    return (
        <StyledDropDown variant="standard" /* style={{marginTop: '-1rem', minWidth: '28%'}} */>
            <Autocomplete 
                options={(allDistributors || []).map(el => ({
                    id: el.JDE_ADDR_BOOK_N,
                    label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`,
                    label2: `${el.DIST_DESC}`
                }))}
                id="dist-select-label" value={jdeAbNo || ''}
                onChange={onDistributorChange}
                PaperComponent={props => <Paper sx={{fontSize: '0.75rem !important'}} {...props}/>}
                isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
        </StyledDropDown>
    )
})

// Context menu renderer
const ContextMenuRenderer = React.memo(props => {
    const {ctxmenus, ctxMenuActState, tblExtActProps, isModifyEnabled, onContextMenuItemClick} = props;

    return (
        <StyledActionMenuDiv>
            {ctxmenus?.map((ctxmenu, idx) => {
                const isMenuDisabled = (ctxmenu?.disable || ctxmenu?.disable === undefined) && (ctxMenuActState[ctxmenu?.id]?.disable || !isModifyEnabled);

                return (
                    (tblExtActProps[`ctx_visible_${ctxmenu?.id}`] || tblExtActProps[`ctx_visible_${ctxmenu?.id}`] === undefined) 
                        ? <IconButton title={ctxmenu?.label} key={`${idx}${ctxmenu}_${(new Date()).getTime()}`} onClick={e => onContextMenuItemClick(e, ctxmenu)} disabled={isMenuDisabled}>
                            <ctxmenu.icon size={'small'} sx={{width: '0.8em', height: '0.8em'}}/>
                        </IconButton> : null
                );
            })}
        </StyledActionMenuDiv>
    )
})

// Context menu item renderer
const ContextMenuItemsRenderer = React.memo(props => {
    const {contextMenu, ctxMenuActState, isCtxMenuOpen, ctxmenus, tblExtActProps, isModifyEnabled, onContextMenuItemClick} = props;

	return (
		<Menu open={isCtxMenuOpen} onClose={onContextMenuItemClick} anchorReference="anchorPosition" 
			anchorPosition={isCtxMenuOpen ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined}>
			{ctxmenus?.map((ctxmenu, idx) => {
                const isMenuDisabled = (ctxmenu?.disable || ctxmenu?.disable === undefined) && (ctxMenuActState[ctxmenu?.id]?.disable || !isModifyEnabled);


                return (
                    (tblExtActProps[`ctx_visible_${ctxmenu?.id}`] || tblExtActProps[`ctx_visible_${ctxmenu?.id}`] === undefined) 
                        ? <StyledListItem key={`${idx}${ctxmenu}_${(new Date()).getTime()}`} onClick={e => onContextMenuItemClick(e, ctxmenu)} 
                            sx={{padding: '0.6em 1.5em !important'}} disabled={isMenuDisabled/* ctxMenuActState[ctxmenu?.id]?.disable || !isModifyEnabled */}>
                            <ctxmenu.icon size={'small'} sx={{width: '0.7em', height: '0.7em', mr: '0.5em'}}/>{ctxmenu.label}
                        </StyledListItem> : null
                )
            })}
		</Menu>
	)
})

// Table Grid Container
const TableGridContainer = React.memo(props => {
    const {
        showLoader, isModifyEnabled,
        vgrid: {
            id, pctxmenu, scrollToIndex,
            gridData: {cdata: rowData},
            tblExtActProps,
            tblProps: {options},
            dimensions: {gridWidth, /* gridHeight,  */gridRSCount, gridTotalWidth}
        }
    } = props;
    
    return (
        <StyledPlaceHolderContainerDiv id={id} style={{/* border: `1px solid`,  height: `${gridHeight}px`*/}}>
            <StyledPlaceHolderInnerContainerDiv style={{width: `${gridTotalWidth}px`}}>
                <TableColumnContainer {...props.vgrid} />
                {options?.filtering && 
                    <TableFilterContainer {...props.vgrid} />}
                
                <StyledPlaceHolderDiv {...(options?.contextmenu && {style: {cursor: 'context-menu'}, onContextMenu: pctxmenu?.onContextMenuClick})}>
                    <CircularProgressLoader width={gridWidth} loading={showLoader}/>
                    <List 
                        height={gridRSCount * 30}
                        rowCount={rowData?.length || 0}
                        rowHeight={30}
                        width={gridTotalWidth}
                        style={{
                            backgroundColor: getBackgroundColor(),
                            transition: 'background-color 0.2s ease'
                        }}
                        rowRenderer={getRowRender(props)}
                        scrollToIndex={scrollToIndex}
                        overscanRowCount={gridRSCount}
                        scrollToAlignment={'start'}
                        noRowsRenderer={() => <NoRowsRenderer width={gridWidth}/>}/>
                    {options?.contextmenu && 
                        <ContextMenuItemsRenderer {...pctxmenu} tblExtActProps={tblExtActProps} isModifyEnabled={isModifyEnabled}/>}
                </StyledPlaceHolderDiv>
            </StyledPlaceHolderInnerContainerDiv>
        </StyledPlaceHolderContainerDiv>
    )
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {
        dimensions: {gridTotalWidth, gridColWidths, actionColWidth},
        tblProps: {fcolumns, options},
        callbacks: {onResizeColumn},
        psorts: {
            onSortColumn,
            sortColumnOrder: {sortBy, sortOrder}
        }
    } = props;

    return (
		<StyledColumnContainerDiv role="row" style={{fontWeight: 500}}>
            {/* Action position - default - start */}
			{options?.action && options?.actionposition === 'start' && 
                <StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`}}>
                    <StyledRowItemText style={{justifyContent: 'center', textAlign: 'center',}}>Actions</StyledRowItemText>
                    <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
                </StyledRowItemDiv>}
            
			{fcolumns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && 
                        <StyledRowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                            {column?.sorting 
                                ? <StyledColumnSortDiv style={{justifyContent: column?.align, textAlign: column?.align}} active={sortBy === (column?.sortColumn || column.field)} 
                                        direction={sortOrder} onClick={(e) => onSortColumn(e, {sortCol: column.field, altSortCol: column?.sortColumn})}>{column.label}</StyledColumnSortDiv>
                                : <StyledRowItemText style={{justifyContent: column?.align, textAlign: column?.align}}>{column?.label}</StyledRowItemText>
                            }
                            <ColumnResizerRenderer gridWidth={gridTotalWidth} column={column} onResize={onResizeColumn}/>
                        </StyledRowItemDiv>
				)
			})}
            {/* Action position - default - end */}
			{options?.action && (options?.actionposition === 'end' || options?.actionposition === undefined) && 
                <StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`}}>
                    <StyledRowItemText style={{justifyContent: 'center', textAlign: 'center',}}>{'Actions'}</StyledRowItemText>
                </StyledRowItemDiv>}
		</StyledColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {gridWidth, column, onResize} = props;
    
    return (
        <>
			{column.field && (column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, {deltaX}) => onResize(e, {gridWidth: gridWidth, dataKey: column.field, deltaX: deltaX})} position={{x: 0}}>
				    <StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				  </ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
        </>
	)
})

// Table column filter container
const TableFilterContainer = React.memo(props => {
    const {
        tblProps: {fcolumns, options},
        dimensions: {gridColWidths, actionColWidth},
        callbacks: {onFilterChange}
    } = props;

    return (
        <StyledFilterContainerDiv role="row" style={{fontWeight: 500}}>
            {options?.action && options?.actionposition === 'start' && 
                <StyledFilterItemDiv style={{flex: `0 1 ${actionColWidth}px`}}/>}
            
            {fcolumns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && (column?.filter 
						? <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                                <StyledFilterInput id={`filter-${column.field}-input-${new Date().getTime()}`} onKeyUp={onFilterChange} name={column.field} 
									startAdornment={<InputAdornment position="start"><FilterListOutlinedIcon sx={{width: '0.65em'}}/></InputAdornment>}>
								</StyledFilterInput>
                            </StyledFilterItemDiv>
                        : <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}/>)
                )
            })}
            {options?.action && (options?.actionposition === 'end' || options?.actionposition === undefined) && 
                <StyledFilterItemDiv style={{flex: `0 1 ${actionColWidth}px`}}/>}
        </StyledFilterContainerDiv>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style, parent: {props: {rowCount}}}) => {
    const {
        isModifyEnabled,
        vgrid: {
            // selectedRow,
            tblExtActProps,
            selectedRowItems: {itemUids},
            gridData: {
                cdata: rowData
            },
            piedit: {iedit, changedRowValue, changedRowValue1, setChangedRowValue, setChangedRowValue1, onSaveCancelClick},
            dimensions: {gridColWidths, actionColWidth},
            tblProps: {fcolumns, options, actions, mactions},
            callbacks: {onRowClick, onCancelAction, onRowActionClick}
        }
    } = props;
    // console.log('>props-piedit>>>>>>>>', iedit);

    const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}
	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5,
        paddingRight: rowCount < 18 ? 10: 0
	};
    // const isRowMatched = (row?.UUID && (selectedRow?.UUID === row?.UUID));
    // const isRowMatched = (row?.UUID && (itemUids.includes(row?.UUID) || selectedRow?.UUID === row?.UUID));
    const isRowMatched = row?.UUID && itemUids.includes(row?.UUID);
    const backgroundColors = isRowMatched ? {color1: ConstCfg.Colors.LIGHT_YELLOW} : undefined;
    
    return (
        <StyledRowItemContainer role="row" key={`${row?.UUID}-${index}`} style={{...getStyle(patchedStyle, backgroundColors)}}>
            {options?.action && options?.actionposition === 'start' && 
                <StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`, /* flexGrow: 1,  */justifyContent: 'center'}}>
                    {iedit?.edit && iedit?.id === row.UUID 
                        ? <>
                            <StyledActionItem>
                                <IconButton title="Save" sx={{flexGrow: 1, padding: '.1em'}} onClick={e => onSaveCancelClick(e, changedRowValue, iedit.action, 'SAVE')}><DoneOutlinedIcon/></IconButton>
                            </StyledActionItem>
                            <StyledActionItem>
                                <IconButton title="Cancel" sx={{flexGrow: 1, padding: '.1em'}} onClick={e => onSaveCancelClick(e, changedRowValue, iedit.action, 'CANCEL')}><ClearOutlinedIcon/></IconButton>
                            </StyledActionItem>
                        </>
                        : (actions?.map((action, idx) => (
                            (tblExtActProps[`act_visible_${action?.id}`] || tblExtActProps[`act_visible_${action?.id}`] === undefined) 
                                ? <StyledActionItem key={`_actions_${idx}`}>
                                    <IconButton title={action?.title} disabled={!isModifyEnabled} sx={{flexGrow: 1, padding: '.1em'}} /* {...(!action?.disable && {onClick: e => onRowActionClick(e, row, action)})} */onClick={e => onRowActionClick(e, row, action)}>
                                        <action.icon/>
                                    </IconButton>
                                </StyledActionItem> : null
                            ))
                        )
                    }
                </StyledRowItemDiv>}

            {fcolumns?.map(column => {
                const gridColWidth = gridColWidths[column.field];

                return (
                    column?.visible && 
                        <StyledRowItemDiv role="gridcell" key={`${row.UUID}-${column.field}`} {...(options?.rowclick && {onClick: e => onRowClick(e, row, index)})} 
                            style={{justifyContent: column?.align, overflow: 'hidden', flex: `0 1 ${gridColWidth}px`}}>
                            {column?.iedit 
                                ? <EditInline onCancelAction={onCancelAction} isRowMatched={isRowMatched} options={options} actions={actions} mactions={mactions} isModifyEnabled={isModifyEnabled} column={column} row={row}/>
                                : <EasyEdit iedit={{...iedit, edit: true, id: row.UUID}} changedRowValue={changedRowValue} changedRowValue1={changedRowValue1} setChangedRowValue={setChangedRowValue} setChangedRowValue1={setChangedRowValue1} column={column} row={row} isModify={isModifyEnabled}/>}
                        </StyledRowItemDiv>
                )
            })}

            {options?.action && (options?.actionposition === 'end' || options?.actionposition === undefined) && 
                <StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`, flexGrow: 1, justifyContent: 'center'}}>
                    {iedit?.edit && iedit?.id === row.UUID 
                        ? <>
                            <StyledActionItem>
                                <IconButton title="Save" sx={{flexGrow: 1, padding: '.1em'}} onClick={e => onSaveCancelClick(e, changedRowValue, iedit.action, 'SAVE')}><DoneOutlinedIcon/></IconButton>
                            </StyledActionItem>
                            <StyledActionItem>
                                <IconButton title="Cancel" sx={{flexGrow: 1, padding: '.1em'}} onClick={e => onSaveCancelClick(e, changedRowValue, iedit.action, 'CANCEL')}><ClearOutlinedIcon/></IconButton>
                            </StyledActionItem>
                        </>
                        : (actions?.map((action, idx) => (
                            (tblExtActProps[`act_visible_${action?.id}`] || tblExtActProps[`act_visible_${action?.id}`] === undefined) 
                                ? <StyledActionItem key={`_actions_${idx}`}>
                                    <IconButton title={action?.title} disabled={!isModifyEnabled} sx={{flexGrow: 1, padding: '.1em'}} /* {...(!action?.disable && {onClick: e => onRowActionClick(e, row, action)})} */onClick={e => onRowActionClick(e, row, action)}>
                                        <action.icon/>
                                    </IconButton>
                                </StyledActionItem> : null
                            ))
                        )
                    }
				</StyledRowItemDiv>}
        </StyledRowItemContainer>
    )
}

// More Column placeholder
const MoreColumnsRenderer = React.memo((props) => {
	const {dwcolmenu, columns} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isOpen = Boolean(anchorEl);
    
    return (
		<div style={{display: 'flex', alignItems: 'center', marginLeft: '1.5em'}}>
            <IconButton aria-label="more" id={`long-button`} aria-controls={isOpen ? `dwpcolmenu-long` : undefined} aria-haspopup={true} aria-expanded={isOpen ? 'true' : undefined} 
                onClick={(e) => setAnchorEl({target: e.currentTarget})} style={{padding: 0, borderRadius: '10%', color: 'rgb(0 0 0 / 38%)'}}>
                <ViewColumnOutlinedIcon sx={{width: '0.8em'}}/>
                <span style={{fontSize: '0.675rem', color: 'rgba(0, 0, 0, 0.98)', padding: '3px 0 0 4px'}}>Columns</span>
            </IconButton>
            <DropdownColumnMenu isOpen={isOpen} anchorEl={anchorEl} onDpColumnMenuClose={() => setAnchorEl(null)} columns={columns} {...dwcolmenu}/>
        </div>
    )
})

// Dropdown menu - more columns
const DropdownColumnMenu = React.memo(props => {
    const {
        isOpen,
        columns,
        anchorEl,
        menuHeight=50,
        onDpColumnMenuClose,
        onChangeColumnMenuItem
    } = props;
    const isAllChecked = (columns?.reduce((acc, curr) => curr?.visible && ++acc, 0) === columns?.length);

    return (
        <Menu id={`dwpcolmenu-long`} MenuListProps={{'aria-labelledby': `long-button`}} 
            open={isOpen} onClose={onDpColumnMenuClose} anchorEl={anchorEl?.target} 
            PaperProps={{
                style: {
                    maxHeight: menuHeight * 5,
                    width: 'auto'
                }
            }}>
            <StyledListItem disableRipple onClick={e => onChangeColumnMenuItem(e, undefined, !isAllChecked)}>
                <Checkbox size={'small'} checked={isAllChecked} onChange={e => onChangeColumnMenuItem(e, undefined, !isAllChecked)} 
                    inputProps={{'aria-label': 'controlled'}}/>All Columns
            </StyledListItem>
            <Divider sx={{my: 0.5}} />
            {columns.map((item, idx) => (
                <StyledListItem disableRipple key={`dpcolmenu-${item.field}-${idx}`} {...(item.field !== anchorEl?.parent && {onClick: (e) => onChangeColumnMenuItem(e, item)})}>
                    <Checkbox size={'small'} {...(item.field === anchorEl?.parent && {disabled: true})} checked={item?.visible} 
                        {...(item.field !== anchorEl?.parent && {onChange: (e) => onChangeColumnMenuItem(e, item)})} inputProps={{'aria-label': 'controlled'}}/> {item.label}
                </StyledListItem>
            ))}
        </Menu>
    )
})

// No rows found placeholder
const NoRowsRenderer = React.memo(({width}) => {
	return (
		<StyledNoRecordDiv {...(width && {style: {width: `${width}px`}})}>No record(s) found!</StyledNoRecordDiv>
	)
})

// Get drag item style while dragging
const getStyle = (styles, bgColors) => {
	const {color1} = bgColors || {color1: 'inherit'};
	
	return {
		...styles,
		color: 'inherit',
		borderTop: '0 none',
		backgroundColor: color1
	};
}

// Backgroud color
const getBackgroundColor = () => {
	return 'inherit';
};
