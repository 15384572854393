// Historical Rejected Data Service
import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { HistoricalData } from '../utils/urls';

export const HistSalesRejectedDataService = {
	// Get all Historical Rejected Data
    getAllHistoricalRejectedData: async () => {
        let result = await axios.get(HistoricalData.SalesRejectedData.ALL_REJECTED_DATA);
        rebuildResult(result);

        return result;
    },

    // Delete Historical Rejected Data
    deleteHistoricalRejectedData: async (rowData) => {
        let result = await axios.delete(HistoricalData.SalesRejectedData.DEL_REJECTED_DATA, {
            data: {
                data: rowData
            }
        });
        
        return result;
    },

	// Update rollup position while dragging item
	processHistoricalRejectedData: async (rowData) => {
		let result = await axios.put(HistoricalData.SalesRejectedData.PROCESS_REJECTED_DATA, {
            data: rowData
        });
        
        return result;
	}
}

// Re-build resultset
const rebuildResult = (result) => {
    if (!result?.data?.status?.isOk) {
        return;
    }

    // Historical sales rejected data re-build to add uuid
    let _rows_data_hist_sales_rejected = [];
    (result?.data?.rows || []).forEach(item => {
        _rows_data_hist_sales_rejected.push({...item, UUID: uuid_v4()});
    });
    result.data = {
        ...result?.data, 
        rows: _rows_data_hist_sales_rejected
    }
}
