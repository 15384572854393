import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Checkbox, FormControl, MenuItem } from '@mui/material';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import { SalesforceRepNameService } from '../services/service.salesforce.rep.names';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import SaveIcon from '@mui/icons-material/Save';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


// Table main container
export const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
export const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`;
// Caption bar div style
export const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1.2em;
	padding: 0 .1em;
`;
// Table title style
export const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;
// Dropdown
export const StyledDropDown = styled(FormControl)`
    min-width: 30%;
    margin-left: 1.5em;
    margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>.MuiInputLabel-root {
            top: -2px;
        }
        &>.MuiInputBase-root {
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// List menu item style
export const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 4px;
    padding-right: 0;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;


// Sales Force Hierarchy - Sales Rep Names
export const SalesForceRepNames = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    // const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [table1Data, setTable1Data] = useState({
        odata: null,
        cdata: null
    });
    /* const [table2Data, setTable2Data] = useState({
        odata: null,
        cdata: null
    });
    const [table3Data, setTable3Data] = useState({
        odata: null,
        cdata: null
    }); */
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: EditOutlinedIcon,
        action: (e, d) => {}
    });
    const [showTblDialog, setShowTblDialog] = useState({
        tbl1: false,
        tbl2: false,
        tbl3: false
    });
    /* const [showTbl1Dialog, setShowTbl1Dialog] = useState(false);
    const [showTbl2Dialog, setShowTbl2Dialog] = useState(false);
    const [showTbl3Dialog, setShowTbl3Dialog] = useState(false); */
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [incmpRouteChecked, setIncmpRouteChecked] = useState({clk: false, chk: false});

    SnackbarCfg.setState({
        state: setShowSnackbar
    });

    /* const [tbl1ExtActProps, setTbl1ExtActProps] = useState({
        ctx_visible_assignroute: false,
        /* 
        act_visible_addroute: true,
        act_visible_editroute: true,
        act_visible_deleteroute: true, * /
        act_visible_unassignroute: false
    });
    const [tbl2ExtActProps, setTbl2ExtActProps] = useState({
        ctx_visible_assignroute: false
    }); */

    // Table grid input properties - columns/options/actions etc - incomplete.
    const tableInputProps1 = {
        // Columns
        columns: [
            {label: 'Route #', field: 'SLS_RTE_N', width: 280, visible: true, filter: true, sorting: true},
            {label: 'Rep Name', field: 'SLS_REP_M', width: 600, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Status', field: 'RTE_STATUS', width: 190, visible: true, filter: false, sorting: true, searching: false}
        ],
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'tbl1editroute1',
                label: 'Edit',
                icon: EditOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'REP_EDT',
                        title: this.label,
                        icon: SaveIcon,
                        actionText: 'Update',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowTblDialog({
                                tbl1: false,
                                tbl2: false,
                                tbl3: false
                            });
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await updateSfRepresentativeName(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowTblDialog({
                        tbl1: true,
                        tbl2: false,
                        tbl3: false
                    });
                }
            }
        ],
        // Row actions
        actions: [
            {
                title: 'Edit',
                id: 'tbl1editroute2',
                icon: EditOutlinedIcon,
                onClick: function(e, {rowItem}) {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    setDialogType({
                        type: 'REP_EDT',
                        title: this.title,
                        icon: SaveIcon,
                        actionText: 'Update',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowTblDialog({
                                tbl1: false,
                                tbl2: false,
                                tbl3: false
                            });
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await updateSfRepresentativeName(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowTblDialog({
                        tbl1: true,
                        tbl2: false,
                        tbl3: false
                    });
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: true,
            overlay: false,
            rowclick: true,
            contextmenu: true,
            searching: false,
            distdwmenu: true,
            actionposition: 'start'
        }
    }
    
    // Update Salesforce Representative Name Data
    const updateSfRepresentativeName = async (rowItems) => {
        await SalesforceRepNameService.updateSfRepresentativeName(rowItems)
            .then(response => {
                const {status, rows} = response.data;
                if (status.isOk) {
                    /* const tbl1IncompleteData = rows?.filter(o => o.SLS_REP_M === null || o.SLS_REP_M?.length < 1);
                    const tbl2AsgnData = rows?.filter(o => o.SLS_FRC_DIST_MGR_N !== null || o.SLS_FRC_DIST_MGR_N?.length > 0);
                    const tbl3UnAsgnData = rows?.filter(o => o.SLS_FRC_DIST_MGR_N === null || o.SLS_FRC_DIST_MGR_N?.length < 1); */
                    const tbl1IncompleteData = rows?.filter(o => o.RTE_INCMP === 'IN_CMP');
                    setTable1Data({
                        odata: rows || [],
                        cdata: incmpRouteChecked?.chk ? tbl1IncompleteData : rows
                    });
                    // setIncmpRouteChecked(incmpRouteChecked);
                    /* setTable1Data({
                        odata: tbl1IncompleteData || [],
                        cdata: tbl1IncompleteData
                    });
                    setTable2Data({
                        odata: tbl2AsgnData || [],
                        cdata: tbl2AsgnData
                    });
                    setTable3Data({
                        odata: tbl3UnAsgnData || [],
                        cdata: tbl3UnAsgnData
                    }); */
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowTblDialog({
                    tbl1: false,
                    tbl2: false,
                    tbl3: false
                });
            });
    }
    
    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        setJdeAddrBookNo(dwValue);
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        if (!dwValue?.id) {
            setTable1Data(null);
            /* setTable2Data(null);
            setTable3Data(null); */
            return;
        }

        // Get Sales Rep data based on distributor
        (async () => {
            setShowLoader(true);

            await SalesforceRepNameService.getSfRepNamesData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {status, rows} = response.data;
                    /* const tbl1IncompleteData = rows?.filter(o => o.SLS_REP_M === null || o.SLS_REP_M?.length < 1);
                    const tbl2AsgnData = rows?.filter(o => o.SLS_FRC_DIST_MGR_N !== null || o.SLS_FRC_DIST_MGR_N?.length > 0);
                    const tbl3UnAsgnData = rows?.filter(o => o.SLS_FRC_DIST_MGR_N === null || o.SLS_FRC_DIST_MGR_N?.length < 1);
                    */
                    const tbl1IncompleteData = rows?.filter(o => o.RTE_INCMP === 'IN_CMP');
                    setTable1Data({
                        odata: rows || [],
                        cdata: incmpRouteChecked?.chk ? tbl1IncompleteData : rows
                    });

                    /* setTable1Data({
                        odata: tbl1IncompleteData || [],
                        cdata: tbl1IncompleteData
                    });
                    setTable2Data({
                        odata: tbl2AsgnData || [],
                        cdata: tbl2AsgnData
                    });
                    setTable3Data({
                        odata: tbl3UnAsgnData || [],
                        cdata: tbl3UnAsgnData
                    }); */

                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [incmpRouteChecked]);
    
    // onChangeColumnMenuItemCallback to show / hide grid columns
    const onChangeIncompleteRouteCallback = useCallback((e, checked) => {
        const tbl1IncompleteData = (table1Data?.odata || [])?.filter(o => o.RTE_INCMP === 'IN_CMP');
        setTable1Data(prev => ({
            ...prev,
            cdata: checked ? tbl1IncompleteData : prev?.odata
        }));
        setIncmpRouteChecked({clk: true, chk: checked});
    }, [table1Data?.odata]);

    // userEffect hook to load all distributors data
    useEffect(() => {
        if (incmpRouteChecked?.clk) {
            return;
        }
        (async () => {
            setShowLoader(true);
            await SalesforceRepNameService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setTable1Data(null);
                    /* setTable2Data(null);
                    setTable3Data(null); */
                    setShowLoader(false);
                });
        })();
    }, [incmpRouteChecked?.clk, onDistributorChangeCallback]);
    

	// Customized Properties
	/* const customProps = {
		title: 'Sales Force Hierarchy - Sales Rep Names',
		jdeAbNo: jdeAddrBookNo,
		allDistributors: allDistributorsData,
        showLoader: showLoader,
        rowData: rowData,
        tblInputProps: tableInputProps,
        columnWidths: columnWidths,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            dimensions: tableDimension,
            scrollToIndex: 0,
            sorts: {
                sortColumns: sortColumns,
                onSort: onSortCallback
            },
            onResize: onResizeCallback,
            onRowActionClick: onRowActionClickCallback
        },
		callbacks: {
			onDistributorChange: onDistributorChangeCallback
		},
        snackbar: {
			isOpen: showSnackbar,
            onSnackbarClose: onSnackbarCloseCallback
        },/* 
        snackbar: {
			isOpen: openSnackbar,
			messages: snackbarMessage
		}, * /
        pdialog: {
            title: 'Sales Representative',
            showDialog: showDialog,
            dialogType: dialogType,
            callbacks: {
                onDialogClose: onDialogCloseCallback
            }
        }
	} */

	/* return (
		<Container>
			<SfHierarchyContainer {...customProps}/>
			<SnackbarAlert {...customProps.snackbar}/>
        </Container>
	) */

    // Customized Grid Properties
	const customProps = {
		title: 'Sales Force Hierarchy - Sales Rep Names',
        isModifyEnabled: isModifyEnabled,
		jdeAbNo: jdeAddrBookNo,
		allDistributors: allDistributorsData,
        gridWidth: tableDimension?.width,
        loader: {
            showLoader: showLoader
        },
        vgrid: {
            id: 'idRepNamesRouteCntr',
            rowData: table1Data?.cdata,
            tblProps: tableInputProps1,
            // tblExtActProps: tbl1ExtActProps,
            dimensions: {
                ...tableDimension,
                // gheight: 400,
                actionColumnWidth: 80,
                // width: tableDimension?.width/3 - 10
            },
            psearch: {
                // width: 200
            },
            pdialog: {
                showDialog: showTblDialog?.tbl1,
                dialogType: dialogType
            },
            distrsdata: {
                jdeAbNo: jdeAddrBookNo,
                allDistributors: allDistributorsData,
                onDistributorChange: onDistributorChangeCallback
            }
        },

        /* vgrid1: {
            // title: 'Sales Rep Names',
            isModifyEnabled: isModifyEnabled,
            loader: {
                showLoader: showLoader
            },
            vgrid: {
                id: 'idRepNamesRouteCntr',
                rowData: table1Data?.cdata,
                tblProps: tableInputProps1,
                // tblExtActProps: tbl1ExtActProps,
                dimensions: {
                    ...tableDimension,
                    gheight: 400,
                    actionColumnWidth: 60,
                    width: tableDimension?.width/3 - 10
                },
                psearch: {
                    // width: 200
                },
                pdialog: {
                    showDialog: showTblDialog?.tbl1,
                    dialogType: dialogType
                }
            }
        }, */
        /* vgrid2: {
            title: 'Sales Force Hierarchy - Assigned',
            isModifyEnabled: isModifyEnabled,
            loader: {
                showLoader: showLoader
            },
            vgrid: {
                id: 'idAssignRouteCntr',
                rowData: table2Data?.cdata,
                tblProps: tableInputProps2,
                // tblExtActProps: tbl2ExtActProps,
                dimensions: {
                    ...tableDimension,
                    gheight: 400,
                    actionColumnWidth: 60,
                    width: tableDimension?.width/3
                },
                psearch: {
                    // width: 200
                },
                pdialog: {
                    showDialog: showTblDialog?.tbl2,
                    dialogType: dialogType
                }
            }
        },
        vgrid3: {
            title: 'Sales Force Hierarchy - Unassigned',
            isModifyEnabled: isModifyEnabled,
            loader: {
                showLoader: showLoader
            },
            vgrid: {
                id: 'idUnAssignRouteCntr',
                rowData: table3Data?.cdata,
                tblProps: tableInputProps3,
                // tblExtActProps: tbl3ExtActProps,
                dimensions: {
                    ...tableDimension,
                    gheight: 400,
                    actionColumnWidth: 60,
                    width: tableDimension?.width/3 - 10
                },
                psearch: {
                    // width: 200
                },
                pdialog: {
                    showDialog: showTblDialog?.tbl3,
                    dialogType: dialogType
                }
            }
        }, */
		/* callbacks: {
			onDistributorChange: onDistributorChangeCallback,
            onChangeIncompleteRoute: onChangeIncompleteRouteCallback
		} */
        incmprte: {
            incmpRouteChecked: incmpRouteChecked?.chk,
            onChangeIncompleteRoute: onChangeIncompleteRouteCallback
        }
    }

    return (
        <VirtualizedDataGrid1 {...customProps} ComponentRenderer={() => <IncompleteRendererComponent {...customProps?.incmprte}/>}/>
    )
    // return <></>;

    /* return (
		<Container>
            <SnackbarAlert/>
			<SfHierarchyContainer {...customProps}/>
            {/ * <OverlayLoader loading={showOverlayLoader}/> * /}
        </Container>
	) */
}

// No rows found placeholder
const IncompleteRendererComponent = React.memo((props) => {
    const {incmpRouteChecked, onChangeIncompleteRoute} = props;

	return (
        <StyledListItem disableRipple onClick={e => onChangeIncompleteRoute(e, !incmpRouteChecked)}>
            Show Incomplete Routes <Checkbox size={'small'} checked={incmpRouteChecked} onChange={e => onChangeIncompleteRoute(e, !incmpRouteChecked)} 
                inputProps={{'aria-label': 'controlled'}}/>
        </StyledListItem>
    )
})
