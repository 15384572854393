import axios from 'axios';
import { ManualEntry } from '../utils/urls';


// Distributor Product Xref Validation service
export const MeFileUploadService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(ManualEntry.FileUpload.ALL_DISTRIBUTORS_DATA);

        return result;
    },

    // Get all manual entry sls upload data
    getAllMeUpldData: async (data) => {
        let result = await axios.get(ManualEntry.FileUpload.ALL_ME_UPLD_SLS_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo
            }
        });

        return result;
    },

    // Upload sales files
    uploadFiles: async (formData, onProgress) => {
        return await axios.post(ManualEntry.FileUpload.ALL_FILE_UPLOADS, formData, {
            onUploadProgress: (progressEvent) => {
                onProgress && 
                    onProgress(progressEvent);
            }
        });
    }
}
