import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { ProductInfo } from '../utils/urls';


// Product information service
export const ProductInfoService = {
    // Get all product UPC data
    getAllProductUpcData: async () => {
        let result = await axios.get(ProductInfo.UpcInfo.ALL_PRODUCT_UPC_DATA);
        rebuildResult(result);
        
        return result;
    }
}

// Re-build resultset to add uuid
const rebuildResult = (result) => {
    if (!result?.data?.status?.isOk) {
        return;
    }

    let _rows_data_upc_info = [];
    (result?.data?.rows || []).forEach(item => {
        _rows_data_upc_info.push({...item, UUID: uuid_v4()});
    });
    if (result?.data?.rows) {
        result.data = {
            ...result?.data, 
            rows: _rows_data_upc_info
        }
    }
}
