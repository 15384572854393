import React, { useState, useEffect } from 'react';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import { ProductInfoService } from '../services/service.product.info';


// Product Information Component
export const ProductInfo = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    const [tableData, setTableData] = useState({
        odata: null,
        cdata: null
    });
    const [, setShowSnackbar] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    })

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'UPC', field: 'UPC_N', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Product Desc', field: 'PROD_DESC', width: 260, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Size', field: 'CTNR_SIZE', width: 100, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Brand', field: 'BRAND_DESC', width: 220, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Flavor', field: 'FLAVOR_DESC', width: 180, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Color', field: 'COLOR', width: 100, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Category', field: 'CATEGORY', width: 220, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Subcategory', field: 'SUB_CATEGORY', width: 220, visible: true, filter: true, sorting: true, searching: true},
            {label: 'LOB', field: 'LOB', width: 180, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Business Unit', field: 'BUSINESS_UNIT', width: 180, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Bottles / Case', field: 'BOTTLE_PER_CASE', width: 100, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Bottles / Unit', field: 'BOTTLE_PER_UNIT', width: 100, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Units / Case', field: 'UNIT_PER_CASE', width: 100, visible: true, filter: true, sorting: true, searching: false},
            {label: 'Product Status', field: 'PROD_STATUS', width: 100, visible: true, filter: true, sorting: true, searching: true}
        ],
        // Table options
        options: {
            action: false,
            filtering: true,
            searching: true,
            morecolumns: true
        }
    }

    // useEffect hook to load all distributor product xref data
    useEffect(() => {
        (async () => {
            setShowLoader(true);

            await ProductInfoService.getAllProductUpcData()
                .then(response => {
                    // console.log(response);
                    const {status, rows} = response.data;
                    setTableData({
                        odata: rows || [],
                        cdata: rows || []
                    });
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [])
    

    // Customized Grid Properties
	const customProps = {
		title: 'Product/UPC Information',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader
        },
        vgrid: {
            id: 'idProdUpcInfoCntr',
            rowData: tableData?.cdata,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension
            }
        }
    }
    
    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )
}