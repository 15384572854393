import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, 
    TextField, Typography, FormControl, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ConstCfg, DialogCfg } from '../utils/utils';
import { env } from '../utils/env.variables';
import styled from '@emotion/styled';


// Style dialog container
const StyledDialog = styled(Dialog)`
    pointer-events: auto;
    &>.MuiDialog-container {
        &>.MuiDialog-paper {
            max-width: calc(100% - 4px);
            &>.MuiDialogTitle-root {
                display: flex;
                padding: 0.8em 1.4em;
                font-size: 0.92rem;
            }
        }
    }
`;
// Style text filed
const StyledTextField = styled(TextField)`
    margin: 0 0.7em;
    &>.MuiInputBase-root {
        &>.MuiInputBase-input {
            font-size: 0.675rem;
        }
        &:hover:not(.Mui-disabled, .Mui-error):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
        &:before {
            border-bottom-style: dotted;
        }
        &:after {
            border-bottom: 1px solid #1976d2
        }
    }
`;
const StyledConfirmDiv = styled.div`
    color: #d70606;
    font-weight: 500;
    text-align: center;
    font-size: 0.8rem;
    user-select: none;
`;
const StyledRowDiv = styled.div`
    display: flex;
    padding: 0.6em 0;
`;
const StyledRowDivExd = styled(StyledRowDiv)`
    // display: flex;
    // width: 330px;
    width: 380px;
    padding: 0.4em 0;
`;
const StyledRowDivExd2 = styled(StyledRowDivExd)`
    padding: 0.4em 0;
`;
const StyledRowHeadDiv = styled(StyledRowDiv)`
    font-weight: 500;
    background-color: #ddddddc7;
`;
const StyledRowBodyDiv = styled(StyledRowDiv)`
    border-bottom: 1px solid #ddddddc7;
    align-items: center;
`;
const StyledRowBodyDiv2 = styled(StyledRowBodyDiv)`
    padding: 0.4em 0;
`;
const StyledRowItemDiv = styled.div`
    display: flex;
	align-items: center;
	padding: 0 0.5em;
    min-width: 60px;
    user-select: none;
`;
const StyledRowItemDiv1 = styled(StyledRowItemDiv)`
    width: 160px;
    min-width: 160px;
    // background-color: yellow;
    font-weight: 500;
    // border-right: 1px dotted;
`;
// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0 solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0 solid #1976d2;
    }
`;
// Extended dropdown
const StyledDropDownExd = styled(StyledDropDown)`
	&>.MuiInputBase-root {
		&>.MuiSelect-select {
			font-size: 0.89rem;
            padding: 4px 0 5px 4px;
		}
	}
`;
const StyledDropDownExd2 = styled(StyledDropDown)`
    margin-left: 0;
    width: 130px;
    &>.MuiInputBase-root {
        font-size: 0.675rem !important;
        &>.MuiSelect-select {
            // padding: 4px 0 5px 4px;
        }
    }
`;
// List menu item style
const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;
const StyledErrorDiv = styled.div`
    flex: 1;
    color: #ff0000;
    padding: 4px;
`;


// Popup Dialog
export const PopupDialog = (props) => {
    const closeBtnRef = useRef();
    const closeXBtnRef = useRef();
    const {
        title,
        showDialog,
        dialogType,
        columns,
        callbacks: {onDialogClose}
    } = props;

    const [errorMsg, setErrorMsg] = useState(null);
    const [extRowItems, setExtRowItems] = useState();
    const [selectedRowItems, setSelectedRowItems] = useState([]);
    const [selectedLicenseTypeText, setSelectedLicenseTypeText] = useState();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [dwSelect1Value, setDwSelect1Value] = useState('N');
    const [actionBtnVisible, setActionBtnVisible] = useState(true);
    const [addNewRowItems, setAddNewRowItems] = useState([{SRV_NEW_ITEM_VALUE: ''}]);

    // Close dialog callback
    const onCloseDialogCallback = (e) => {
        onDialogClose && 
            onDialogClose(e);
        
        dialogType?.onClose && 
            dialogType?.onClose(e);
        
        setBtnDisabled(false);
        setIsOpenDialog(false);
    };

    // Close dialog callback
    const onActionDialogCallback = (e) => {
        if (onValidateFormCallback()) {
            setBtnDisabled(true);
            DialogCfg.onToggleButtonState([
                {ref: closeBtnRef, state: true},
                {ref: closeXBtnRef, state: true}
            ]);

            dialogType?.action && 
                dialogType?.action(e, selectedRowItems, {cbks: onDialogClose, dwv: dwSelect1Value});
        }
    };

    // License text change callback
    const onChangeLicenseText = (e, rowItems) => {
        const lic_type_codes_found = (extRowItems?.lictypecodes || []).find(item => 
            item.WINE_ALLW_X === (rowItems[0].WINE_LICENSE || 'N')
                && item.SPRT_ALLW_X === (rowItems[0].SPIRIT_LICENSE || 'N')
                    && item.MALT_ALLW_X === (rowItems[0].MALT_LICENSE || 'N'));
        // console.log('lic_type_codes_found>', lic_type_codes_found);
        setErrorMsg(null);
        setSelectedLicenseTypeText(lic_type_codes_found?.ALCOH_LIC_TYP_D || '--');
        if (!lic_type_codes_found) {
            setErrorMsg(env.getEnv('REACT_APP_MSG_LIC_NOT_FOUND_ERROR'));
            setTimeout(() => { setErrorMsg(null); }, 15000);
        }
    }
    
    // Input/Textarea/Dropdown value change callback
    const onChangeCallback = (e, rowItem, cbks) => {
        const name = e.target.name;
        const ofld_name = `O_${name}`;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        const selectedRowItemsNew = selectedRowItems?.reduce((accu, curr) => 
            [...accu, {
                ...curr, 
                [ofld_name]: curr?.UUID === rowItem?.UUID ? (!curr[ofld_name] ? curr[name] : curr[ofld_name]) : (curr[ofld_name] || null), 
                [name]: curr?.UUID === rowItem?.UUID ? value : curr[name]
            }], []);
        setSelectedRowItems(selectedRowItemsNew);
        /* setSelectedRowItems(prev => 
            prev?.reduce((accu, curr) => 
                [...accu, {
                    ...curr, 
                    [ofld_name]: curr?.UUID === rowItem?.UUID ? (!curr[ofld_name] ? curr[name] : curr[ofld_name]) : (curr[ofld_name] || null), 
                    [name]: curr?.UUID === rowItem?.UUID ? value : curr[name]
                }], [])); */
        cbks && 
            cbks(e, selectedRowItemsNew);
    };
    
    // Gallo channel value change callback
    const onChannelChangeCallback = (e, rowItem) => {
        const name = e.target.name;
        const ofld_name = `O_${name}`;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const orgChannelCode = selectedRowItems[0][ofld_name] !== undefined ? selectedRowItems[0][ofld_name] : selectedRowItems[0][name];
        const orgSubChannelCode = selectedRowItems[0]['O_SUB_CHNL_C'] !== undefined ? selectedRowItems[0]['O_SUB_CHNL_C'] : selectedRowItems[0]['SUB_CHNL_C'];
        const isChannelCodeMatched = (value === orgChannelCode);
        
        setSelectedRowItems(prev => 
            prev?.reduce((accu, curr) => 
                [...accu, {
                    ...curr, 
                    [name]: curr?.UUID === rowItem?.UUID ? value : curr[name],
                    [ofld_name]: curr?.UUID === rowItem?.UUID ? (curr[ofld_name] === undefined ? curr[name] : curr[ofld_name]) : (curr[ofld_name] || null), 
                    SUB_CHNL_C: curr?.UUID === rowItem?.UUID ? (isChannelCodeMatched ? orgSubChannelCode : curr['SUB_CHNL_C']) : (curr['SUB_CHNL_C'] || null),
                }], []));
    };

    // Input/Textarea/Dropdown value change callback
    const onChangeAddValueCallback = (e, idx) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        const addNewRowItemList = [...addNewRowItems];
        addNewRowItemList[idx][name] = value;

        setAddNewRowItems(addNewRowItemList);
        setSelectedRowItems(addNewRowItemList);
    }

    // Add new row
    const onChangeAddRowCallback = () => {
        setAddNewRowItems(prev => ([
            ...prev,
            {SRV_NEW_ITEM_VALUE: ''}
        ]));
    }

    // Remove existing row
    const onChangeRemoveRowCallback = (e, idx) => {
        const deleteRowItemList = [...addNewRowItems];
        deleteRowItemList.splice(idx, 1);
        setAddNewRowItems(deleteRowItemList);
    }

    // Validate form data
    const onValidateFormCallback = () => {
        let isValid = true;

        switch (dialogType?.type) {
            case 'ACCNT_INFO_EDT':
                const lic_type_codes_found = (extRowItems?.lictypecodes || []).filter(item => 
                    item.WINE_ALLW_X === (selectedRowItems[0].WINE_LICENSE || 'N')
                        && item.SPRT_ALLW_X === (selectedRowItems[0].SPIRIT_LICENSE || 'N')
                            && item.MALT_ALLW_X === (selectedRowItems[0].MALT_LICENSE || 'N'));
                
                if (!lic_type_codes_found.length) {
                    setErrorMsg(env.getEnv('REACT_APP_MSG_LIC_NOT_FOUND_ERROR'));
                    isValid = false;
                }
                break;
            default:
                break;
        }
        
        setTimeout(() => {
            setErrorMsg(null);
        }, 15000);
        return isValid;
    }

    useEffect(() => {
        setErrorMsg(null);
        setActionBtnVisible(true);
        if (dialogType?.type === 'ACCNT_INFO_EDT') {
            let webRoleCounter = 0;
            const fieldsArr = ['CHNL_C', 'ALCOH_LIC_TYP_C', 'WINE_MVMT_C', 'CHNL_C', 'SPRT_MVMT_C', 'ACCT_STAT_C', 'MALT_MVMT_C', 'CUST_ETHN_C', 'FOOD_TYP_C', 'DLVR_TYP_C', 'SLS_RTE1_N', 'NON_PRIM_RSN_C'];
            fieldsArr.forEach(field => (extRowItems?.distreditroles[field] === 'WEB') ? webRoleCounter++ : 0);
            !webRoleCounter && 
                setActionBtnVisible(false);
            
            /* fieldsArr.forEach(field => {
                if (extRowItems?.distreditroles[field] === 'WEB1') {
                    webRoleCounter++;
                }
                console.log(extRowItems?.distreditroles[field], field);
            })
            (webRoleCounter < 1) && 
                setActionBtnVisible(false);
            console.log('webRoleCounter>', webRoleCounter, !webRoleCounter, (webRoleCounter < 1)); */
        }
    }, [dialogType, extRowItems, setActionBtnVisible]);
    
    // Load data and show dialog
    useEffect(() => {
        let rowItemsData = dialogType?.rowItem;
        if (rowItemsData && !(rowItemsData instanceof Array)) {
            rowItemsData = [rowItemsData];
        }
        setBtnDisabled(false);
        setIsOpenDialog(showDialog);
        setSelectedRowItems(rowItemsData || []);
        setSelectedLicenseTypeText(undefined);
        setExtRowItems(dialogType?.rowItem1);
        setAddNewRowItems([{SRV_NEW_ITEM_VALUE: ''}]);
    }, [showDialog, dialogType]);

    // console.log('popup>>selectedRowItems>>', selectedRowItems);
    // console.log('>extRowItems>', extRowItems);
    // console.log(selectedLicenseTypeText);

    return (
        <StyledDialog open={isOpenDialog || false} aria-labelledby="dialog-title" aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">
                <Typography align="left" sx={{flexGrow: 1, marginRight: '50px', userSelect: 'none'}}>{dialogType?.title} {title}</Typography>
                <IconButton ref={closeXBtnRef} aria-label="close" onClick={onCloseDialogCallback} sx={{padding: 0, color: ConstCfg.Colors.LIGHT_GREY}}>
                    <CloseIcon sx={{width: '0.8em', height: '0.8em'}}/>
                </IconButton>
            </DialogTitle>
            
            <DialogContent dividers={true}>
                <DialogContent id="dialog-description" sx={{padding: '10px'}}>
                    {dialogType?.type === 'ACCNT_INFO_EDT' && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important', width: 760}}>
                            <StyledRowHeadDiv style={{marginBottom: 20}}>
                                <StyledRowItemDiv>Distributor Name: {selectedRowItems[0]?.DIST_NAME} ({selectedRowItems[0]?.DIST_NBR})</StyledRowItemDiv>
                            </StyledRowHeadDiv>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Account Name</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_NAME}</StyledRowItemDiv>
                                </StyledRowDivExd>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Account Nbr</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_NBR}</StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                {/* <StyledRowDivExd2 style={{flex: 1}}>
                                    <StyledRowItemDiv style={{width: 120, fontWeight: 500}}>Address</StyledRowItemDiv>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_ADDR}{selectedRowItems[0]?.ACCNT_ADDR2 ? `, ${selectedRowItems[0]?.ACCNT_ADDR2}`: null}</StyledRowItemDiv>
                                </StyledRowDivExd2> */}

                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>Address</StyledRowItemDiv1>
                                    <StyledRowItemDiv style={{display: 'block'}}>
                                        <p style={{margin: 0}}>{selectedRowItems[0]?.ACCNT_ADDR}</p>
                                        {selectedRowItems[0]?.ACCNT_ADDR2 && <p style={{margin: '2px 0 0 0'}}>{selectedRowItems[0]?.ACCNT_ADDR2}</p>}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>Master Acct/TDLinx/VIPOUT</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.MSTR_ACCT ?? '--'} / {selectedRowItems[0]?.TD_LINK ?? '--'} / {selectedRowItems[0]?.VIP_OUT ?? '--'}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>City</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_CITY}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>County</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.COUNTY}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>State</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_STATE}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>Country</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_COUNTRY}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>Postal Code</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_PIN_CODE}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                                <StyledRowDivExd2>
                                    <StyledRowItemDiv1>Sales Type</StyledRowItemDiv1>
                                    <StyledRowItemDiv>{selectedRowItems[0]?.ACCNT_SLS_TYPE}</StyledRowItemDiv>
                                </StyledRowDivExd2>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                {<StyledRowDivExd2>&nbsp;</StyledRowDivExd2>}
                                {/* <StyledRowDivExd>
                                    <StyledRowItemDiv style={{width: 120, fontWeight: 500}}>License Type</StyledRowItemDiv>
                                    <StyledRowItemDiv style={{color: '#ff5c00'}}>{selectedRowItems[0]?.LICENSE_TYPE_DESC}</StyledRowItemDiv>
                                </StyledRowDivExd> */}
                                <StyledRowDivExd style={{fontWeight: 500}}>
                                    <StyledRowItemDiv1 style={{color: '#ff5c00'}}>{selectedLicenseTypeText || selectedRowItems[0]?.LICENSE_TYPE_DESC}</StyledRowItemDiv1>
                                    <StyledRowItemDiv style={{width: 100}}>License (Y/N)</StyledRowItemDiv>
                                    <StyledRowItemDiv style={{marginLeft: 10, width: 100}}>Movement Code</StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Gallo Channel</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.CHNL_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="CHANNEL_CODE" value={selectedRowItems[0]?.CHANNEL_CODE} defaultValue={0} onChange={e => onChannelChangeCallback(e, selectedRowItems[0])}>
                                                {extRowItems?.chnls[selectedRowItems[0]?.SLS_TYPE_CODE]?.map((itm, idx) => (
                                                    <StyledListItem key={`chnlc-${itm.CHNL_C}-${idx}`} value={itm.CHNL_C}>{itm.CHNL_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.CHANNEL_DESC}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Wine</StyledRowItemDiv1>
                                    <StyledRowItemDiv style={{width: 100}}>
                                        {extRowItems?.distreditroles?.ALCOH_LIC_TYP_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.WINE_ALLW_X === 'Y' 
                                                ? <Select name="WINE_LICENSE" value={selectedRowItems[0]?.WINE_LICENSE || 'N'} defaultValue={'N'} onChange={e => onChangeCallback(e, selectedRowItems[0], onChangeLicenseText)}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                        <StyledListItem value="Y">Y</StyledListItem>
                                                    </Select>
                                                : <Select disabled={true} name="WINE_LICENSE" value={'N'}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                    </Select>}
                                            </StyledDropDownExd2> : selectedRowItems[0]?.WINE_LICENSE}
                                    </StyledRowItemDiv>
                                    <StyledRowItemDiv style={{marginLeft: 10, width: 100}}>
                                        {extRowItems?.distreditroles?.WINE_MVMT_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.WINE_ALLW_X === 'Y' && selectedRowItems[0]?.WINE_LICENSE === 'Y' 
                                                ? <Select name="WINE_MVMT_C" value={selectedRowItems[0]?.WINE_MVMT_C !== selectedRowItems[0]?.DFLT_MVMT_NA_CODE ? selectedRowItems[0]?.WINE_MVMT_C : selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} defaultValue={selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                        {extRowItems?.wmsmvmttyps[selectedRowItems[0]?.SLS_TYPE_CODE]?.map((itm, idx) => (
                                                            <StyledListItem key={`winemvmt-${itm.MVMT_CLS_C}-${idx}`} value={itm.MVMT_CLS_C}>{itm.MVMT_CLS_D}</StyledListItem>
                                                        ))}
                                                    </Select>
                                                : <Select disabled={true} name="WINE_MVMT_C" value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>
                                                        <StyledListItem value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>N/A</StyledListItem>
                                                    </Select>}
                                            </StyledDropDownExd2> : selectedRowItems[0]?.WINE_MOVEMENT}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Gallo Sub-Channel</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.SUB_CHNL_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="SUB_CHNL_C" value={selectedRowItems[0]?.SUB_CHNL_C || ''} defaultValue={''} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                {extRowItems?.subchnls[selectedRowItems[0]?.CHANNEL_CODE]?.map((itm, idx) => (
                                                    <StyledListItem key={`subchnlc-${itm.SUB_CHNL_C}-${idx}`} value={itm.SUB_CHNL_C}>{itm.SUB_CHNL_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.SUB_CHANNEL_DESC}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Spirits</StyledRowItemDiv1>
                                    <StyledRowItemDiv style={{width: 100}}>
                                        {extRowItems?.distreditroles?.ALCOH_LIC_TYP_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.SPRT_ALLW_X === 'Y' 
                                                ? <Select name="SPIRIT_LICENSE" value={selectedRowItems[0]?.SPIRIT_LICENSE || 'N'} defaultValue={'N'} onChange={e => onChangeCallback(e, selectedRowItems[0], onChangeLicenseText)}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                        <StyledListItem value="Y">Y</StyledListItem>
                                                    </Select>
                                                : <Select disabled={true} name="SPIRIT_LICENSE" value={'N'}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                    </Select>}
                                            </StyledDropDownExd2> : selectedRowItems[0]?.SPIRIT_LICENSE}
                                    </StyledRowItemDiv>
                                    <StyledRowItemDiv style={{marginLeft: 10, width: 100}}>
                                        {extRowItems?.distreditroles?.SPRT_MVMT_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.SPRT_ALLW_X === 'Y' && selectedRowItems[0]?.SPIRIT_LICENSE === 'Y' 
                                                ? <Select name="SPRT_MVMT_C" value={selectedRowItems[0]?.SPRT_MVMT_C !== selectedRowItems[0]?.DFLT_MVMT_NA_CODE ? selectedRowItems[0]?.SPRT_MVMT_C : selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} defaultValue={selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                        {extRowItems?.wmsmvmttyps[selectedRowItems[0]?.SLS_TYPE_CODE]?.map((itm, idx) => (
                                                            <StyledListItem key={`spiritmvmt-${itm.MVMT_CLS_C}-${idx}`} value={itm.MVMT_CLS_C}>{itm.MVMT_CLS_D}</StyledListItem>
                                                        ))}
                                                    </Select>
                                                : <Select disabled={true} name="SPRT_MVMT_C" value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>
                                                        <StyledListItem value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>N/A</StyledListItem>
                                                    </Select>}
                                            </StyledDropDownExd2> : selectedRowItems[0]?.SPIRIT_MOVEMENT}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Gallo Status</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.ACCT_STAT_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="ACCT_STAT_C" value={selectedRowItems[0]?.ACCT_STAT_C} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                {extRowItems?.accntstat?.map((itm, idx) => (
                                                    <StyledListItem key={`accntstus-${itm.STAT_C}-${idx}`} value={itm.STAT_C}>{itm.STAT_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.ACCNT_STATUS}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Malt</StyledRowItemDiv1>
                                    <StyledRowItemDiv style={{width: 100}}>
                                        {extRowItems?.distreditroles?.ALCOH_LIC_TYP_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.MALT_ALLW_X === 'Y' 
                                                ? <Select name="MALT_LICENSE" value={selectedRowItems[0]?.MALT_LICENSE || 'N'} defaultValue={'N'} onChange={e => onChangeCallback(e, selectedRowItems[0], onChangeLicenseText)}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                        <StyledListItem value="Y">Y</StyledListItem>
                                                    </Select>
                                                : <Select disabled={true} name="MALT_LICENSE" value={'N'}>
                                                        <StyledListItem value="N">N</StyledListItem>
                                                    </Select>
                                            }
                                        </StyledDropDownExd2> : selectedRowItems[0]?.MALT_LICENSE}
                                    </StyledRowItemDiv>
                                    <StyledRowItemDiv style={{marginLeft: 10, width: 100}}>
                                        {extRowItems?.distreditroles?.MALT_MVMT_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            {extRowItems?.distrlictypes?.MALT_ALLW_X === 'Y' && selectedRowItems[0]?.MALT_LICENSE === 'Y' 
                                                ? <Select name="MALT_MVMT_C" value={selectedRowItems[0]?.MALT_MVMT_C !== selectedRowItems[0]?.DFLT_MVMT_NA_CODE ? selectedRowItems[0]?.MALT_MVMT_C : selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} defaultValue={selectedRowItems[0]?.DFLT_MVMT_DWP_CODE} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                        {extRowItems?.wmsmvmttyps[selectedRowItems[0]?.SLS_TYPE_CODE]?.map((itm, idx) => (
                                                            <StyledListItem key={`multmvmt-${itm.MVMT_CLS_C}-${idx}`} value={itm.MVMT_CLS_C}>{itm.MVMT_CLS_D}</StyledListItem>
                                                        ))}
                                                    </Select>
                                                : <Select disabled={true} name="MALT_MVMT_C" value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>
                                                        <StyledListItem value={selectedRowItems[0]?.DFLT_MVMT_NA_CODE}>N/A</StyledListItem>
                                                    </Select>}
                                            </StyledDropDownExd2> : selectedRowItems[0]?.MALT_MOVEMENT}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                {selectedRowItems[0]?.SLS_TYPE_CODE === 'OFF' && 
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Patron Ethnicity</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.CUST_ETHN_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="CUST_ETHN_C" value={selectedRowItems[0]?.CUST_ETHN_C} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                {extRowItems?.patethns?.map((itm, idx) => (
                                                    <StyledListItem key={`pethn-${itm.CUST_ETHN_C}-${idx}`} value={itm.CUST_ETHN_C}>{itm.CUST_ETHN_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.PATRON_ETHNICITY}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>}
                                {selectedRowItems[0]?.SLS_TYPE_CODE === 'ON' && 
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1 style={{textWrap: 'balance'}}>Cuisine Type</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.FOOD_TYP_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="CUISINE_TYP_C" value={selectedRowItems[0]?.CUISINE_TYP_C || ''} defaultValue={''} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                <StyledListItem value={''}>&nbsp;</StyledListItem>
                                                {extRowItems?.cuisintyps?.map((itm, idx) => (
                                                    <StyledListItem key={`cuisntyp-${itm.FOOD_TYP_C}-${idx}`} value={itm.FOOD_TYP_C}>{itm.FOOD_TYP_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.CUISINE_TYPE}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                                }
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Delivery Type</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.DLVR_TYP_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'} style={{width: 202}}>
                                            <Select style={{minWidth: 160}} name="DLVR_TYP_C" value={selectedRowItems[0]?.DLVR_TYP_C} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                {extRowItems?.dlvrtyps?.map((itm, idx) => (
                                                    <StyledListItem key={`dlvr-${itm.DLVR_TYP_C}-${idx}`} value={itm.DLVR_TYP_C}>{itm.DLVR_TYP_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.DELIVERY_TYPE}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                            <StyledRowBodyDiv2>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Primary Sales Route</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.SLS_RTE1_N === 'WEB' ? <StyledDropDownExd2 variant={'standard'}>
                                            <Select style={{minWidth: 160}} name="PRIMARY_SLS_ROUTE" value={selectedRowItems[0]?.PRIMARY_SLS_ROUTE || ''} defaultValue={''} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                <StyledListItem value="">&nbsp;</StyledListItem>
                                                {extRowItems?.slsprimroutes?.map((itm, idx) => (
                                                    <StyledListItem key={`prmslsrts-${itm.SLS_RTE_N}-${idx}`} value={itm.SLS_RTE_N}>{itm.SLS_RTE_N} - {itm.SLS_REP_M}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.PRIMARY_SLS_ROUTE_D}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                                <StyledRowDivExd>
                                    <StyledRowItemDiv1>Non-Primary Reason</StyledRowItemDiv1>
                                    <StyledRowItemDiv>
                                        {extRowItems?.distreditroles?.NON_PRIM_RSN_C === 'WEB' ? <StyledDropDownExd2 variant={'standard'} style={{width: 202}}>
                                            <Select style={{minWidth: 160}} name="NON_PRIM_RSN_C" value={selectedRowItems[0]?.NON_PRIM_RSN_C || ''} defaultValue={''} onChange={e => onChangeCallback(e, selectedRowItems[0])}>
                                                <StyledListItem value="">&nbsp;</StyledListItem>
                                                {extRowItems?.nprimrsn?.map((itm, idx) => (
                                                    <StyledListItem key={`nprmrsn-${itm.NON_PRIM_RSN_C}-${idx}`} value={itm.NON_PRIM_RSN_C}>{itm.NON_PRIM_RSN_D}</StyledListItem>
                                                ))}
                                            </Select>
                                        </StyledDropDownExd2> : selectedRowItems[0]?.NON_PRIMARY_REASON}
                                    </StyledRowItemDiv>
                                </StyledRowDivExd>
                            </StyledRowBodyDiv2>
                        </Container>}
                    
                    {dialogType?.type === 'REP_FRC_ADD' && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                            {addNewRowItems?.map((item, idx) => (
                                <StyledRowBodyDiv key={`${item}-${idx}}`} style={{border: '0px solid'}}>
                                    <StyledTextField {...(idx === 0 && {autoFocus: true})} variant="standard" sx={{width: '100%', border: '0px solid'}} id={`srv-add-name-input-${idx}`} label="Name/Description" 
                                        onChange={(e) => onChangeAddValueCallback(e, idx)} name="SRV_NEW_ITEM_VALUE" value={item?.SRV_NEW_ITEM_VALUE} required/>
                                    <span style={{border: '0px solid', justifyContent: 'right', display: 'flex', width: `${addNewRowItems?.length > 1 ? '24%' : 'auto'}`}}>
                                    {addNewRowItems?.length !== 1 && 
                                        <IconButton title={'Remove'} onClick={(e) => onChangeRemoveRowCallback(e, idx)} sx={{padding: '2px'}}>
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>}
                                    {addNewRowItems?.length - 1 === idx && 
                                        <IconButton title={'Add'} onClick={onChangeAddRowCallback} sx={{padding: '2px'}}>
                                            <AddCircleOutlineOutlinedIcon/>
                                        </IconButton>}
                                    </span>
                                </StyledRowBodyDiv>
                            ))}
                        </Container>}
                    
                    {dialogType?.type === 'REP_FRC_EDT' && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                            {selectedRowItems?.map((item, idx) => (
                                <StyledRowBodyDiv key={`${item?.UUID}-${idx}`}>
                                    <StyledTextField disabled variant="standard" sx={{width: '27%'}} id={`route-number-input-disabled-${idx}`} 
                                        label="ID #" value={item.SLS_FRC_NO}/>
                                    <StyledTextField variant="standard" sx={{width: '70%'}} id={`representative-name-input-${idx}`} 
                                        label="Name/Description" onChange={e => onChangeCallback(e, item)} name="SLS_FRC_DESC" value={item.SLS_FRC_DESC || ''}/>
                                </StyledRowBodyDiv>
                            ))}
                        </Container>}
                    
                    {/* Representative data update */}
                    {dialogType?.type === 'REP_EDT' && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                            {selectedRowItems?.map((item, idx) => (
                                <StyledRowBodyDiv key={`${item?.UUID}-${idx}`}>
                                    <StyledTextField disabled variant="standard" sx={{width: '27%'}} id={`route-number-input-disabled-${idx}`} 
                                        label="Route #" value={item.SLS_RTE_N}/>
                                    <StyledTextField variant="standard" sx={{width: '70%'}} id={`representative-name-input-${idx}`} 
                                        label="Rep Name" onChange={e => onChangeCallback(e, item)} name="SLS_REP_M" value={item.SLS_REP_M || ''}/>
                                </StyledRowBodyDiv>
                            ))}
                        </Container>}
                    
                    {/* Distributor contact information data update */}
                    {dialogType?.type === 'CNTC_INFO_EDT' && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                            <StyledRowHeadDiv>
                                {columns?.map((column, idx) => 
                                    column?.visible 
                                        ? <StyledRowItemDiv key={`pdgh-${column.field}${idx}`} style={{width: `${column?.width}px`, textAlign: `${column?.align}`, marginLeft: 6, marginRight: 6}}>{column?.label}</StyledRowItemDiv> 
                                        : null)}
                            </StyledRowHeadDiv>

                            {selectedRowItems?.map((row, idx) => (
                                <StyledRowBodyDiv key={`${row?.UUID}-${idx}`} style={{marginLeft: 4, marginRight: 4}}>
                                    {columns?.map((column, idx1) => 
                                        column?.visible 
                                            ? (idx1 === 0 
                                                ? <StyledRowItemDiv key={`pdgcv-${column.field}-${idx}-${idx1}`} style={{width: `${column?.width}px`, textAlign: `${column?.align}`, marginLeft: 6, marginRight: 6}}>{row[column?.field]}</StyledRowItemDiv>
                                                : <StyledTextField key={`pdgcv-${column.field}-${idx}-${idx1}`} variant="standard" sx={{width: `${column?.width}px`, marginLeft: 6, marginRight: 6}} id={`cntc-info-name-input-${idx}-${idx1}`} 
                                                    onChange={e => onChangeCallback(e, row)} name={column.field} value={row[column?.field] || ''}/>)
                                            : null)}
                                </StyledRowBodyDiv>
                            ))}
                        </Container>}
                    
                    {/* Delete/Process Sales Rejected data */}
                    {['REJ_CFM', 'REJ_DEL', 'XREF_VLG_CFM', 'REP_FRC_DEL'].includes(dialogType?.type) && 
                        <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                            {!['XREF_VLG_CFM'].includes(dialogType?.type) && 
                                <StyledConfirmDiv style={{marginBottom: '1.8em'}}>Please confirm to {dialogType?.title?.toLowerCase()} the selected record(s).</StyledConfirmDiv>}
                            
                            {['XREF_VLG_CFM'].includes(dialogType?.type) && 
                                <StyledRowBodyDiv style={{justifyContent: 'right', padding: 0, marginBottom: '1.8em', background: '#e6f2fd'}}>
                                    <StyledConfirmDiv style={{fontSize: '0.8rem', color: '#000', marginRight: '1em'}}>Select flag and validate</StyledConfirmDiv>
                                    <StyledDropDownExd variant={'standard'} sx={{width: '10%'}}>
                                        <Select name="Valid Flag" defaultValue={'N'} onChange={e => setDwSelect1Value(e.target.value)}>
                                            <StyledListItem value="N">N</StyledListItem>
                                            <StyledListItem value="P">P</StyledListItem>
                                            <StyledListItem value="Y">Y</StyledListItem>
                                        </Select>
                                    </StyledDropDownExd>
                                </StyledRowBodyDiv>}

                            <StyledRowHeadDiv>
                                {columns?.map((column, idx) => 
                                    column?.visible 
                                        ? <StyledRowItemDiv key={`pdgh-${column.field}${idx}`} 
                                            style={{width: `${column?.width}px`, textAlign: `${column?.align}`}}>
                                                {column?.label}
                                            </StyledRowItemDiv> 
                                        : null)}
                            </StyledRowHeadDiv>

                            {selectedRowItems?.map((row, idx) => (
                                <StyledRowBodyDiv key={`${row?.UUID}-${idx}`}>
                                    {columns?.map((column, idx1) => 
                                        column?.visible 
                                            ? <StyledRowItemDiv key={`pdgcv-${column.field}-${idx}-${idx1}`} 
                                                style={{width: `${column?.width}px`, textAlign: `${column?.align}`}}>
                                                    {row[column?.field]}
                                                </StyledRowItemDiv> 
                                            : null)}
                                </StyledRowBodyDiv>
                            ))}
                        </Container>}
                    
                        {['EOM_UPD_CFM', 'CNTC_UPD_CFM'].includes(dialogType?.type) && 
                            <Container sx={{paddingLeft: '0 !important', paddingRight: '0 !important'}}>
                                <StyledConfirmDiv style={{marginBottom: '1.8em'}}>Please confirm to update selected information.</StyledConfirmDiv>
                            </Container>}
                </DialogContent>
                <DialogActions sx={{padding: '15px 10px 10px 10px'}}>
                    {errorMsg && <StyledErrorDiv>{errorMsg}</StyledErrorDiv>}
                    <Button sx={{padding: '4px 12px'}} loadingposition="start" startIcon={<CloseIcon sx={{width: '0.8em', height: '0.8em'}}/>} ref={closeBtnRef} onClick={onCloseDialogCallback} variant="outlined">Close</Button>
                    {actionBtnVisible && 
                        <LoadingButton sx={{padding: '4px 12px'}} variant="contained" color="primary" loadingPosition="start" startIcon={<NavigateNextIcon/>}
                            {...(dialogType?.icon && {startIcon: <dialogType.icon sx={{width: '0.8em', height: '0.8em'}}/>})} loading={btnDisabled} disabled={btnDisabled}
                                onClick={onActionDialogCallback}>{dialogType?.actionText}</LoadingButton>}
                </DialogActions>
            </DialogContent>
        </StyledDialog>
    )
}
