import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';


const StyledSideBar = styled.div`
	flex-shrink: 0;
	height: 100%;
	overflow: auto;
	// padding-left: 0.6em;
	margin-top: 2em;
`;

const StyledSideBarItem = styled.div`
	padding: .5em 1px .5em 0;
	&.navitem-open > .navitem-content {
		height: auto;
	}
	&.navitem-open > .navitem-title > .toggle-arrow {
		transform: rotate(90deg);
	}
`;

const StyledSideBarItemLink = styled(NavLink)`
	display: flex;
	color: #000000;
	text-decoration: none;
	font-size: 1.1em;
	padding: .5em 1px .5em 1.5em;
	&:hover {
		color: #1976d2;
	}
	&.active {
		color: #1976d2;
	}
`;

const StyledSideBarItemTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.2em;
`;

const StyledSideBarItemContent = styled.div`
	height: 0;
	padding-top: .25em;
	overflow: hidden;
`;


// Side navigation
export const SideNav = ({routes}) => {
	return (
		<StyledSideBar>
			{routes.map((item, index) => <SideNavItem key={`${index}${item}_${(new Date()).getTime()}`} item={item} />)}
		</StyledSideBar>
	)
}

// Side navigation items
const SideNavItem = ({item}) => {
	const [navItemOpen, setNavItemOpen] = useState(false);

	useEffect(() => {
		const traverseRoutes = (item) => {
			item?.children?.forEach((route, i) => {
				switch (window.location.pathname) {
					case `${route?.path}`:
						setNavItemOpen(true);
						break;
					default:
						break;
				}
				traverseRoutes(route?.children);
			});
		}
		
		traverseRoutes(item);
	}, [item]);
	

	// If Link has children
	if (item.children) {
        return (
            <StyledSideBarItem className={(navItemOpen ? 'navitem-open' : '')}>
                <StyledSideBarItemTitle className="navitem-title">
					<ArrowForwardIosSharpIcon className="toggle-arrow" onClick={(e) => setNavItemOpen(!navItemOpen)} sx={{width: '0.5em', height: '0.5em', marginTop: '-3px', cursor: 'pointer'}}/>
					<span style={{marginLeft: '3px', cursor: 'pointer', fontWeight: 500}} onClick={(e) => setNavItemOpen(!navItemOpen)}>{item.title}</span>
                </StyledSideBarItemTitle>
                <StyledSideBarItemContent className="navitem-content">
                    {item.children.map((child, index) => <SideNavItem key={`${index}${child}_${(new Date()).getTime()}`} item={child} />)}
                </StyledSideBarItemContent>
            </StyledSideBarItem>
        )
    }

	// Render link that does not have any children
	return <StyledSideBarItemLink to={item?.path}>{item.title}</StyledSideBarItemLink>;
}