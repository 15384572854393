import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Autocomplete, FormControl, Paper, TextField, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MeFileUploadService } from '../services/service.mentry.fileupload';
import { CircularProgressLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import { SnackbarCfg } from '../utils/utils';
import { env } from '../utils/env.variables';
import dayjs from 'dayjs';


// Table main container
const Container = styled.div`
	display: flex;
	margin-top: 20px;
    width: 100%;
    justify-content: center;
`;
// Table row container
const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1.2em;
	padding: 0 .1em;
    // background-color: #f0b4e05c;
`;
// Table title style
const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;
// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 35%;
    margin-left: 1.5em;
    margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>.MuiInputLabel-root {
            top: -2px;
        }
        &>.MuiInputBase-root {
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
const StyledMeContainer = styled.div`
    // background-color: #f2f2f2;
    // border-top: 1px solid #cbcbcb;
    // border-bottom: 1px solid #cbcbcb;
    // padding-right: 10px;
    user-select: none;
    margin-top: 20px;
`;
const StyledContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 30px;
    border-radius: 2px;
    border: 1px solid #cbcbcb;
    // justify-content: space-between
`;
const StyledTitle = styled.div`
    border-bottom: 1px solid #cccccc;
    background-color: #f2f2f2;
    padding: 5px;
    font-size: 0.975rem;
    font-weight: 600;
`;
const StyledFieldContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
`;
const StyledTextField = styled(TextField)`
    width: 100%;
    &>.MuiOutlinedInput-root {
        height: 40px;
        &> fieldset {
            border: 0;
            background-color: #f9ffda63/* #d2e8ff52 */;
        }
    }
`;
const StyledTypography = styled(Typography)`
    padding: 2px;
    display: flex;
    font-size: 0.775rem;
    font-weight: 500;
    &> span:first-of-type {
        width: 30%;
    }
    &> span:nth-of-type(2) {
        font-style: italic;
        color: #f53c3c;
    }
`;
// No records found div
const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;
const StyledNoMupRecordDiv = styled(StyledNoRecordDiv)`
	font-size: 1rem;
	padding: 6em;
	color: #de1818f5;
`;



// File upload types
const UploadTypes = {
    SLS: {I: 'SLS', M: 'Sales', UT: 0},
    INV: {I: 'INV', M: 'Inventory', UT: 2},
    OUT: {I: 'OUT', M: 'Account', UT: 1},
    FTS: {SLS: undefined, INV: undefined, OUT: undefined}
}


// Manual Entry - File upload (Sales/Inventory/Account)
export const MEntryFileUpload = (props) => {
    const {dimensions: tableDimension} = props;
    const [meUpldData, setMeUpldData] = useState({
        slsinfo: {}
    });
    const [slsThroughDate, setSlsThroughDate] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000));
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadProgress, setUploadProgress] = React.useState(UploadTypes.FTS);
    const [fileRenderKey, setFileRenderKey] = React.useState(UploadTypes.FTS);
    const [upldBtnDisabled, setUpldBtnDisabled] = useState(false);
    const [, setShowSnackbar] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState(null);
    const [allDistributorsData, setAllDistributorsData] = useState();
    
    SnackbarCfg.setState({
        state: setShowSnackbar
    });
    
    // resetUpldFileInput to reset selected files
    const resetUpldFileInput = () => {
        setFileRenderKey({
            SLS: Math.random().toString(36),
            INV: Math.random().toString(36),
            OUT: Math.random().toString(36)
        });
    }

    // Calculate upload type
    const calculateUpldType = (fileType) => {
        return (meUpldData?.UpldType || 0) + UploadTypes[fileType].UT;
    }
    
    // onChangeFileCallback to set selected files
    const onChangeFileCallback = (event, type) => {
        if (meUpldData?.cnfgs?.FILE_UPLD_X !== 'Y') {
            setFileRenderKey(prev => ({
                ...prev,
                [type]: Math.random().toString(36)
            }));
            return;
        }
        let filesToUploadNew = [...filesToUpload];
        const itmFound = filesToUploadNew?.find(o => o.TYPE === type);
        if (!itmFound) {
            filesToUploadNew.push({
                TYPE: type, 
                FILE: event.target.files[0]
            });
        }
        else {
            let itmFiltered = filesToUploadNew?.filter(o => o.TYPE !== type);
            if (event.target.files[0]) {
                itmFiltered.push({
                    TYPE: type, 
                    FILE: event.target.files[0]
                });
            }
            filesToUploadNew = itmFiltered;
        }
        setFilesToUpload(filesToUploadNew);
    }

    // onUploadFileCallback to upload files
    const onUploadFileCallback = () => {
        let fileFinishedCounter = 0;
        setUpldBtnDisabled(true);
        if (meUpldData?.cnfgs?.FILE_UPLD_X !== 'Y') {
            return;
        }
        
        filesToUpload.forEach(async item => {
            // console.log(item.TYPE, item.FILE.name, slsThroughDate, meUpldData);
            const slsThroughDateNew = new Date(slsThroughDate);
            const yearNow = slsThroughDateNew.getFullYear();
            // const yearNow = (slsThroughDateNew.getFullYear().toString()).slice(-2);
            const thrDateTime = `${yearNow}${('00' + (slsThroughDateNew.getMonth() + 1)).slice(-2)}${('00' + slsThroughDateNew.getDate()).slice(-2)}`;
            
            const formData = new FormData();
            formData.append('FileType', item.TYPE);
            formData.append('jdeAbNo', jdeAddrBookNo?.id);
            formData.append('throughDate', slsThroughDate);
            formData.append('thrDateTime', thrDateTime);
            formData.append('upldType', calculateUpldType(item.TYPE));
            formData.append('File', item.FILE, item.FILE.name);
            
            await MeFileUploadService.uploadFiles(formData, (progress) => {
                // console.log('>progress.loaded>', progress.loaded, progress.total);
                const percentCompleted = Math.round((progress.loaded / progress.total) * 100);
                setUploadProgress(prev => ({
                    ...prev,
                    [item.TYPE]: percentCompleted
                }));
            })
            .then(response => {
                // console.log('response>uploadFiles>>', response);
                const {status, meupldinfo} = response.data;
                if (status.isOk) {
                    setMeUpldData(prev => ({
                        ...prev,
                        slsinfo: {
                            ...prev.slsinfo,
                            [item.TYPE]: meupldinfo.slsinfo[item.TYPE]
                        }
                    }));
                    /* SnackbarCfg.setSeverity(SnackbarCfg.severities.success)
                        .setMessage(`${UploadTypes[item.TYPE].M} file successfully uploaded.`).show(); */
                }/* 
                else {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage(error?.message/ * `${UploadTypes[item.TYPE].M} file upload failed.` * /).show();
                } */
                SnackbarCfg.setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                    .setMessage(status.isOk ? `${UploadTypes[item.TYPE].M} file successfully uploaded.` : status.message).show();

            })
            .catch(error => {
                // console.log('error', error);
                setUploadProgress(prev => ({
                    ...prev,
                    [item.TYPE]: undefined
                }));
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage(error?.response?.data?.message || `${UploadTypes[item.TYPE].M} file upload failed.`).show();
            })
            .finally(() => {
                fileFinishedCounter++;
                if (filesToUpload.length === fileFinishedCounter) {
                    setFilesToUpload([]);
                    setUpldBtnDisabled(false);
                    setUploadProgress(UploadTypes.FTS);
                    resetUpldFileInput();
                }
            });
        });
    }

    // Distributor Change Callback defination
    const onDistributorChangeCallback = (e, dwValue) => {
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        if (!dwValue?.id) {
            setJdeAddrBookNo(null);
            return;
        }

        (async () => {
            setShowLoader(true);
            await MeFileUploadService.getAllMeUpldData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {status, meupldinfo} = response.data;
                    setMeUpldData({...meupldinfo, UpldType: meupldinfo?.cnfgs?.FILE_UPLD_TYP_C || 0});
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                    setJdeAddrBookNo(dwValue);
                });
        })();
    }

    // userEffect hook to load all distributors data
    useEffect(() => {
        (async () => {
            setShowLoader(true);
            await MeFileUploadService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(status?.message).show();
                        /* SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show(); */
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, []);


    // Customized Grid Properties
	const customProps = {
		title: 'ManualEntry - File Upload',
        showLoader: showLoader,
		jdeAbNo: jdeAddrBookNo,
        meUpldData: meUpldData,
		allDistributors: allDistributorsData,
        gridWidth: tableDimension?.width,
        uploadProgress: uploadProgress,
        upldBtnDisabled: upldBtnDisabled,
        filesToUpload: filesToUpload,
        fileRenderKey: fileRenderKey,
        slsThroughDate: {
            d: slsThroughDate,
            sd: setSlsThroughDate
        },
		callbacks: {
            onUploadFile: onUploadFileCallback,
            onChangeFile: onChangeFileCallback,
			onDistributorChange: onDistributorChangeCallback
		}
    }

    return (
		<Container>
            <SnackbarAlert/>
			<MeUploadContainer {...customProps}/>
        </Container>
	)
}


// ManualEntry File Upload Container
const MeUploadContainer = React.memo(props => {
    const {gridWidth} = props;

    return (
        <TableRowContainer style={{/* border: '1px solid #f0b4e05c',   */width: `${gridWidth - 100}px`}}>
            <TableCaptionBar {...props} />
            <TableContainer {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        jdeAbNo,
        allDistributors,
        callbacks: {onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar style={{borderBottom: '1px solid #cbcbcb'}}>
            <Title>{title}</Title>

            <StyledDropDown variant="standard">
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`,
                        label2: `${el.DIST_DESC}`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    PaperComponent={props => <Paper sx={{fontSize: '0.75rem !important'}} {...props}/>}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Table Caption Bar - Title / Search
const TableContainer = React.memo(props => {
    const {
        jdeAbNo,
        gridWidth,
        showLoader,
        uploadProgress,
        upldBtnDisabled,
        slsThroughDate,
        filesToUpload,
        fileRenderKey,
        meUpldData: {slsinfo, cnfgs},
        callbacks: {onChangeFile, onUploadFile}
    } = props;
    // console.log('>meUpldData>', cnfgs?.FILE_UPLD_X);

    return (
        <StyledMeContainer style={{/* margin: '40px auto 20px', width: `${gridWidth - 200}px` */}}>
            <CircularProgressLoader /* width={gridWidth}  */loading={showLoader}/>
            {!jdeAbNo && 
                <StyledNoRecordDiv>Please select a distributor to upload file(s).</StyledNoRecordDiv>}
            {jdeAbNo && cnfgs?.FILE_UPLD_X !== 'Y' && 
                <StyledNoMupRecordDiv>This distributor does not use manual file upload.</StyledNoMupRecordDiv>}
            {jdeAbNo && cnfgs?.FILE_UPLD_X === 'Y' && 
                <div style={{margin: '30px auto 20px', width: `${gridWidth - 200}px`}}>
                    {/* SLS file */}
                    <StyledContainer>
                        <StyledTitle>SALES FILE</StyledTitle>
                        <StyledFieldContainer>
                            <div style={{width: '40%'}}>
                                <StyledTextField key={fileRenderKey.SLS} onChange={(e) => onChangeFile(e, UploadTypes.SLS.I)} type="file" /* inputProps={{accept: 'application/pdf'}} */ />
                                {uploadProgress.SLS > 0 && <LinearProgress sx={{height: 6, marginTop: 0.5}} variant="determinate" value={uploadProgress.SLS} />}
                            </div>
                            <div style={{width: '50%'}}>
                                <StyledTypography>
                                    <span>Last Sales File Name:</span>
                                    <span>{slsinfo?.SLS?.SRC_FILE_M || 'NA'}</span>
                                </StyledTypography>
                                <StyledTypography>
                                <span>Last Sales Upload Date:</span>
                                    <span>{slsinfo?.SLS?.FILE_DT || 'NA'}</span>
                                </StyledTypography>
                            </div>
                        </StyledFieldContainer>
                    </StyledContainer>

                    {/* INV file */}
                    <StyledContainer>
                        <StyledTitle>INVENTORY FILE</StyledTitle>
                        <StyledFieldContainer>
                            <div style={{width: '40%'}}>
                                <StyledTextField key={fileRenderKey.INV} onChange={(e) => onChangeFile(e, UploadTypes.INV.I)} type="file" />
                                {uploadProgress.INV > 0 && <LinearProgress sx={{height: 6, marginTop: 0.5}} variant="determinate" value={uploadProgress.INV} />}
                            </div>
                            <div style={{width: '50%'}}>
                                <StyledTypography>
                                    <span>Last Inventory File Name:</span>
                                    <span>{slsinfo?.INV?.SRC_FILE_M || 'NA'}</span>
                                </StyledTypography>
                                <StyledTypography>
                                    <span>Last Inventory Upload Date:</span>
                                    <span>{slsinfo?.INV?.FILE_DT || 'NA'}</span>
                                </StyledTypography>
                            </div>
                        </StyledFieldContainer>
                    </StyledContainer>
                    
                    {/* ACC file */}
                    <StyledContainer>
                        <StyledTitle>ACCOUNT FILE</StyledTitle>
                        <StyledFieldContainer>
                            <div style={{width: '40%'}}>
                                <StyledTextField key={fileRenderKey.OUT} onChange={(e) => onChangeFile(e, UploadTypes.OUT.I)} type="file" />
                                {uploadProgress.OUT > 0 && <LinearProgress sx={{height: 6, marginTop: 0.5}} variant="determinate" value={uploadProgress.OUT} />}
                            </div>
                            <div style={{width: '50%'}}>
                                <StyledTypography>
                                    <span>Last Account File Name:</span>
                                    <span>{slsinfo?.OUT?.SRC_FILE_M || 'NA'}</span>
                                </StyledTypography>
                                <StyledTypography>
                                    <span>Last Account Upload Date:</span>
                                    <span>{slsinfo?.OUT?.FILE_DT || 'NA'}</span>
                                </StyledTypography>
                            </div>
                        </StyledFieldContainer>
                    </StyledContainer>

                    {/* Inventory date */}
                    {/* <StyledContainer>
                        <StyledFieldContainer>
                            <div style={{width: '40%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography sx={{padding: '2px', fontSize: '0.875rem', fontWeight: 500}}>Sales and Inventory Through Date</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker sx={{'& .MuiOutlinedInput-root': {height: 40, borderRadius: 1, '&:hover > fieldset': {borderColor: '#C7C8CD'}}}} 
                                        onChange={(newDate) => slsThroughDate.sd(newDate)} shouldDisableDate={(day) => (day.$W === 0 || day.$W === 6)} value={slsThroughDate.d}/>
                                </LocalizationProvider>
                            </div>
                            <LoadingButton sx={{padding: '4px 12px'}} variant="contained" color="primary" loadingPosition="start" loading={upldBtnDisabled} 
                                startIcon={<CloudUploadIcon sx={{width: '0.8em', height: '0.8em'}}/>} disabled={upldBtnDisabled} 
                                    onClick={onUploadFile}>{upldBtnDisabled ? 'Uploading...' : 'Upload File(s)'}</LoadingButton>
                        </StyledFieldContainer>
                    </StyledContainer> */}
                    <StyledFieldContainer style={{justifyContent: 'center'}}>
                        <div style={{width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Typography sx={{padding: '2px', fontSize: '0.875rem', fontWeight: 500}}>Sales and Inventory Through Date</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{'& .MuiOutlinedInput-root': {height: 40, borderRadius: 1, '&> .MuiOutlinedInput-notchedOutline': {borderColor: '#6e6e6e73 !important'}, '&:hover > fieldset': {borderColor: '#C7C8CD'}}}} 
                                    onChange={(newDate) => slsThroughDate.sd(newDate)} shouldDisableDate={(day) => (day.$W === 0 || day.$W === 6)} defaultValue={dayjs(slsThroughDate.d)}/>
                            </LocalizationProvider>
                        </div>
                    </StyledFieldContainer>
                    <StyledFieldContainer style={{justifyContent: 'center', marginTop: '20px'}}>
                        <LoadingButton sx={{padding: '4px 12px'}} variant="contained" color="primary" loadingPosition="start" loading={upldBtnDisabled} 
                            startIcon={<CloudUploadIcon sx={{width: '0.8em', height: '0.8em'}}/>} disabled={upldBtnDisabled || !filesToUpload?.length} 
                                onClick={onUploadFile}>{upldBtnDisabled ? 'Uploading...' : 'Upload File(s)'}</LoadingButton>
                    </StyledFieldContainer>
                </div>}
            
        </StyledMeContainer>
    );
})

/* value={value}
        onChange={(newValue) => setValue(newValue)}
        format="YYYY-MM-DD" */