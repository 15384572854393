import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
// import MuiAlert from '@mui/material/Alert';
import { FormControl, InputLabel, MenuItem, Select, /* Snackbar,  */Autocomplete, TextField, Paper } from '@mui/material';
// import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SalesCalendar } from '../components/component.sales.calendar';
import { EndOfMonthDaysService } from '../services/service.sales.endofmonth';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import SaveIcon from '@mui/icons-material/Save';
import { SnackbarAlert } from '../components/component.snackbar.alert';
import { PopupDialog } from '../components/component.popup.dialog';
// include utils module
require('../utils/utils');


/**
 * Styled components
 */
// Table main container
const Container = styled.div`
    display: flex;
    margin-top: 8px;
`;
// Table row container
const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    /* border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: inline-block;
    margin: 0 auto; */
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
    display: flex;
	margin: 0 0 1.2em;
	padding: 0 .1em;
`;
// Table title style
const Title = styled.h4`
    margin: 0;
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 0.92rem;
    user-select: none;
`;
// Distributor type div style
/* const StyledDropDown = styled(FormControl)`
	min-width: 8%;
	margin-left: 1.5em;
	&>label {
		top: -16px;
	}
	&>.MuiInputBase-root {
		margin-top: 0;
		&:before {
			border-bottom: 0px solid rgba(0, 0, 0, 0.22);
		}
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`; */
/* const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0px solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
`; */

// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 30%;
    margin-left: 1.5em;
    margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>.MuiInputLabel-root {
            top: -2px;
        }
        &>.MuiInputBase-root {
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Extended dropdown
const StyledDropDown1 = styled(FormControl)`
    min-width: 10%;
    margin-top: -10px;
    margin-left: 1.5em;
    /* &>.MuiInputLabel-root {
        top: -2px;
    } */
    /* &>label {
        top: -16px;
    } */
    &>.MuiInputBase-root {
        // margin-top: 0;
        margin-bottom: 0;
        font-size: inherit;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
`;
// Calendar place holder
const CalendarPlaceHolderDiv = styled.div`
	display: inline-block;
    padding: .1em 0;
    width: 100%;
`;
// List menu item style
const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;



// Severity level - error, info, success, warning
/* const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
}; */



// Sales Calendar - End of Month
export const SalesCalEndOfMonth = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    // const {isUserCanModify: isModifyEnabled} = userInfo;
    const [years, setYears] = useState();
    const [yearSelected, setYearSelected] = useState();
    const [monthSelected, setMonthSelected] = useState();
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    // const [, setOpenSnackbar] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    /* const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    }); */
    const [dialogType, setDialogType] = useState({
        icon: SaveIcon,
        action: (e, d) => {}
    });
    const [endOfMonthDaysData, setEndOfMonthDaysData] = useState();
    const [endOfMonthDate, setEndOfMonthDate] = useState({d1:'', d2: ''});
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [isCalEventEnabled, setIsCalEventEnabled] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [, setShowSnackbar] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    });

    // Show Snackbar message
    /* const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity]); */

    // Get Fiscal year list
    const getFiscalYears = () => /* useCallback(() =>  */{
        let currentYear = new Date().getFullYear(),
            startYear = currentYear - 2,
            endYear = currentYear + 1;
        const yearRangeArr = startYear.range(endYear);

        return yearRangeArr;
    }/* , []); */

    // Get all End Of Month Days data
    const getEndOfMonthDaysData = useCallback(async (jdeAbNo, year, month) => {
        let currDate = new Date(),
            currYear = currDate.getFullYear(),
            _month = month || (currYear === year ? currDate.getMonth() + 1 : 1);
        // console.log('getEndOfMonthDaysData>>>', jdeAbNo, year, _month);
        (jdeAbNo && year && _month) && 
            await EndOfMonthDaysService.getEndOfMonthDaysData({jdeAbNo: jdeAbNo, year: year, month: _month})
                .then(response => {
                    console.log('getEndOfMonthDaysData-response>>>', response);
                    const {status, rows} = response.data;
                    if (rows[0]?.DTE_T) {
                        const _dtEom = new Date(rows[0]?.DTE_T);
                        // console.log(_dtEom, _dtEom.getFullYear(), _dtEom.getMonth(), _dtEom.getDate());
                        setEndOfMonthDate({
                            d1: _dtEom,
                            d2: `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`
                        });
                    }
                    setEndOfMonthDaysData(rows || []);

                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                });
    }, []);

    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        // console.log('onDistributorChangeCallback > ', dwValue);
        setJdeAddrBookNo(dwValue);
        setIsCalEventEnabled(!!(dwValue?.id && yearSelected));
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(dwValue?.id, yearSelected, monthSelected);
            setShowLoader(false);
        })();
    }, [yearSelected, monthSelected, getEndOfMonthDaysData]);

    // Year Change Callback defination
    const onYearChangeCallback = /* useCallback( */(e) => {
        // console.log('onYearChangeCallback > ', e);
        setYearSelected(e.target.value);
        setIsCalEventEnabled(!!(jdeAddrBookNo?.id && e.target.value));

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(jdeAddrBookNo?.id, e.target.value, monthSelected);
            setShowLoader(false);
        })();
    }/* , [jdeAddrBookNo, monthSelected/ * , getEndOfMonthDaysData * /]); */

    // Day click callback
    const onCalendarDayClickCallback = useCallback((result) => {
        // console.log('result>zzzz>', result, yearSelected, monthSelected);
        setDialogType({
            type: 'EOM_UPD_CFM',
            icon: SaveIcon,
            actionText: 'Save',
            rowItem: result,
            action: (e, newRowItems) => {
                // console.log('>zzzz>', newRowItems);
                let newResultData = [];
                newRowItems?.forEach(item => {
                    const _dtEom = new Date(item.N_DATE),
                        // targetEndDate = `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`,
                        targetEndDate = `${item.NT_DATE.y}-${('00' + (item.NT_DATE.m + 1)).slice(-2)}-${('00' + item.NT_DATE.d).slice(-2)}`;
                    
                    const _p_start_date = (endOfMonthDate.d1 ? (endOfMonthDate.d1 > _dtEom ? targetEndDate : endOfMonthDate.d2) : targetEndDate),
                        _p_end_date = (endOfMonthDate.d1 ? (endOfMonthDate.d1 > _dtEom ? endOfMonthDate.d2 : targetEndDate) : targetEndDate);
                    const sMonthToUpdate = Number(_p_start_date.split('-')[1]);
                    // console.log('>_p_start_date>', _p_start_date, _p_end_date, sMonthToUpdate/* , _p_start_date.getMonth(), _p_end_date.getMonth() */);
                    
                    newResultData.push({
                        ...item,
                        AZR_USR_ID: userInfo.upn,
                        SELECTED_YEAR: yearSelected,
                        SELECTED_MONTH: sMonthToUpdate/*  monthSelected || (item.NT_DATE.m + 1) */,
                        N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id,
                        P_START_DATE: _p_start_date,
                        P_END_DATE: _p_end_date
                        /* P_START_DATE: (endOfMonthDate.d1 ? (endOfMonthDate.d1 > _dtEom ? targetEndDate : endOfMonthDate.d2) : targetEndDate),
                        P_END_DATE: (endOfMonthDate.d1 ? (endOfMonthDate.d1 > _dtEom ? endOfMonthDate.d2 : targetEndDate) : targetEndDate) */
                    });
                });
                
                (async () => {
                    await EndOfMonthDaysService.updateEndOfMonthDays(newResultData)
                        .then(response => {
                            // console.log('>response>', response);
                            const {status, rows} = response.data;
                            if (status.isOk) {
                                if (rows[0]?.DTE_T) {
                                    const _dtEom = new Date(rows[0]?.DTE_T);
                                    setEndOfMonthDate({
                                        d1: _dtEom,
                                        d2: `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`
                                    });
                                }
                                setEndOfMonthDaysData(rows || []);
                            }
                            SnackbarCfg
                                .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                                    .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_EOM_UPDATE_SUCCESS') : status?.message).show();
                        })
                        .catch(error => {
                            SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                                .setMessage((error?.response?.data?.message || error?.message)).show();
                        })
                        .finally(() => {
                            setShowDialog(false);
                        });
                })();
            }
        });
        setShowDialog(true);

        /* setShowLoader(true);
        let newResultData = [];
        result?.forEach(item => {
            const _dtEom = new Date(item.N_DATE),
                // targetEndDate = `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`,
                targetEndDate = `${item.NT_DATE.y}-${('00' + (item.NT_DATE.m + 1)).slice(-2)}-${('00' + item.NT_DATE.d).slice(-2)}`;
            
            newResultData.push({
                ...item,
                AZR_USR_ID: userInfo.upn,
                SELECTED_YEAR: yearSelected,
                SELECTED_MONTH: monthSelected,
                N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id,
                P_START_DATE: endOfMonthDate.d1 > _dtEom ? targetEndDate : endOfMonthDate.d2,
                P_END_DATE: endOfMonthDate.d1 > _dtEom ? endOfMonthDate.d2 : targetEndDate
            });
        });
        
        (async () => {
            await EndOfMonthDaysService.updateEndOfMonthDays(newResultData)
                .then(response => {
                    const {status, rows} = response.data;
                    if (status.isOk) {
                        if (rows[0]?.DTE_T) {
                            const _dtEom = new Date(rows[0]?.DTE_T);
                            setEndOfMonthDate({
                                d1: _dtEom,
                                d2: `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`
                            });
                        }
                        setEndOfMonthDaysData(rows || []);
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_EOM_UPDATE_SUCCESS'), severity_level.success);
                    }
                    else {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })(); */
    }, [jdeAddrBookNo, yearSelected, /* monthSelected,  */userInfo, endOfMonthDate]);

    // Month click callback
    const onCalendarMonthClickCallback = /* useCallback( */(result) => {
        // console.log('onCalendarMonthClickCallback>>>>', result);
        const {monthNo} = result;
        setMonthSelected(monthNo);

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(jdeAddrBookNo?.id, yearSelected, monthNo);
            setShowLoader(false);
        })();
    }/* , [jdeAddrBookNo, yearSelected/ * , getEndOfMonthDaysData * /]); */

    // Close Snackbar
	/* const onCloseSnackbarCallback = useCallback((event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
    }, [snackbarMessage.severity]); */

    // userEffect hook to load data if changes
    useEffect(() => {
        setYears(getFiscalYears());
        setYearSelected(prev => prev ?? new Date().getFullYear());

        // Get distributors data
        (async () => {
            setShowLoader(true);
            await EndOfMonthDaysService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [onDistributorChangeCallback]);

    // Customized Properties
    const customProps = {
        title: 'Sales Calendar - End of Month',
        dimensions: tableDimension,
        dwYears: years,
        showLoader: showLoader,
        dwYear: yearSelected,
        dwMonth: monthSelected,
        jdeAbNo: jdeAddrBookNo,
        endOfMonthDays: endOfMonthDaysData,
        isCalEventEnabled: isCalEventEnabled,
        allDistributors: allDistributorsData,
        pdialog: {
            title: 'Sales Calendar - End of Month',
            showDialog: showDialog,
            dialogType: dialogType,
            callbacks: {
                onDialogClose: () => null
            }
        },
        callbacks: {
            onYearChange: onYearChangeCallback,
            onDistributorChange: onDistributorChangeCallback,
            onCalendarDayClick: onCalendarDayClickCallback,
            onCalendarMonthClick: onCalendarMonthClickCallback
        }/* ,
        snackbar: {
            duration: 5000,
            isOpen: openSnackbar,
            messages: snackbarMessage,
            callbacks: {
                onCloseSnackbar: onCloseSnackbarCallback
            }
        } */
    }

    return (
        <Container>
            <SnackbarAlert/>
            <CalendarContainer {...customProps} />
        </Container>
    )
}

// Main Calendar Container
const CalendarContainer = React.memo(props => {
    const {dimensions: {width: gridWidth}} = props;
    return (
        <TableRowContainer style={{width: `${gridWidth}px`}}>
            <TableCaptionBar {...props} />
            <CalendarPlaceHolder {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        dwYear,
        dwYears,
        jdeAbNo,
        allDistributors,
        callbacks: {onYearChange, onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} (Year - {dwYear})</Title>

            <StyledDropDown variant="standard" /* style={{marginTop: '-1rem', minWidth: '28%'}} */>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`,
                        label2: `${el.DIST_DESC}`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    PaperComponent={props => <Paper sx={{fontSize: '0.75rem !important'}} {...props}/>}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>

            <StyledDropDown1 variant="standard">
                <InputLabel id="year-input-label">Year</InputLabel>
                <Select id="year-select-label" labelId="year-select-label" defaultValue={''} 
                    value={dwYear || ''} name="DW_YEAR" label="Year" onChange={onYearChange}>
                    {(dwYears || []).map((year, i) => (
                        <StyledListItem key={`dwy-${year}-${i}`} value={year}>{year}</StyledListItem>
                    ))}
                </Select>
            </StyledDropDown1>
        </StyledCaptionBar>
    )
})

// Calendar Place Holder
const CalendarPlaceHolder = React.memo(props => {
    const {
        dwYear,
        pdialog,
        dwMonth,
        showLoader,
        endOfMonthDays,
        isCalEventEnabled,
        callbacks: {onCalendarDayClick, onCalendarMonthClick}
    } = props;
    const calendarCallbacks = {
        onCalendarDayClick: onCalendarDayClick,
        onCalendarMonthClick: onCalendarMonthClick
    }
    
    return (
        <CalendarPlaceHolderDiv>
            <SalesCalendar 
                isCalEventEnabled={isCalEventEnabled} 
                calendarDays={endOfMonthDays} 
                showLoader={showLoader} 
                callbacks={calendarCallbacks} 
                year={dwYear} 
                month={dwMonth}
                onloadcalled={true}
                showNextMonth={true} 
                calType="EOM"/>
            <PopupDialog {...pdialog}/>
        </CalendarPlaceHolderDiv>
    )
})
/* 
// Snackbar Alert Container
const SnackbarAlert = React.memo(props => {
    const {
        isOpen,
        duration,
        messages: {severity='info', message=''},
        callbacks: {onCloseSnackbar}
    } = props;
    
    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    return (
        <Snackbar open={isOpen} autoHideDuration={duration} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity={severity} sx={{width: '100%'}}>{message}</Alert>
        </Snackbar>
    )
})
 */