import { Home } from './modules/module.home';
import { ProductRollup } from './modules/module.product.rollup';
import { GeographyRollup } from './modules/module.geography.rollup';
import { HistSalesRejectedData } from './modules/module.hist.sales.rejected';
import { SalesCalNonSellingDays } from './modules/module.sales.nonsellingdays';
import { SalesCalEndOfMonth } from './modules/module.sales.endofmonth';
import { SalesForceHierarchy } from './modules/module.salesforce.hierarchy';
import { SalesForceRepNames } from './modules/module.salesforce.rep.names';
import { ProductXrefValidation } from './modules/module.prod.xref.validation';
import { DistContactInfo } from './modules/module.dist.contact.info';
import { MEntryFileUpload } from './modules/module.mentry.fileupload';
import { DistAccountProfile } from './modules/module.dist.account.profile';
import { ProductInfo } from './modules/module.product.info';

// URL prefix
const UrlPrefix = {
	Rollups: '/Rollups',
	SalesCalendar: '/SalesCalendar',
	SalesForce: '/SalesForce',
	DistProfile: '/DistProfile',
	ProductInfo: '/Products',
	ManualEntry: '/ManualEntry',
	Utilities: '/Utilities'
};

export const distAccessModules = ['ME_FILE_UPLOAD'];

// Menu Items
export const navMenuItems = [
	/* {
		id: 'HOME',
		title: 'Home',
		path: '/'
	}, */
	{
		title: 'Rollups',
		children: [
			{id: 'PROD_ROLLUP', title: 'Product Rollup', path: `${UrlPrefix.Rollups}/product-rollup`},
			{id: 'GEO_ROLLUP', title: 'Geography Rollup', path: `${UrlPrefix.Rollups}/geography-rollup`}
		]
	},
	{
		title: 'Sales Calendar',
		children: [
			{id: 'SLS_CAL_NSD', title: 'Non-Selling Days', path: `${UrlPrefix.SalesCalendar}/non-selling-days`},
			{id: 'SLS_CAL_EOM', title: 'End of Month', path: `${UrlPrefix.SalesCalendar}/end-of-month`}
		]
	},
	{
		title: 'Sales Force',
		children: [
			{id: 'SF_HIERARCHY', title: 'Sales Force Hierarchy', path: `${UrlPrefix.SalesForce}/sales-force-hierarchy`},
			{id: 'SF_REP_NAME', title: 'Sales Rep Names', path: `${UrlPrefix.SalesForce}/sales-rep-names`}
		]
	},
	{
		title: 'Account Maintenance',
		children: [
			{id: 'DIST_ACCNT_INFO', title: 'Account Profile', path: `${UrlPrefix.DistProfile}/distributor-account-profile`}
		]
	},
	{
		title: 'Distributor Profile',
		children: [
			{id: 'DIST_CNTC_INFO', title: 'Contact Information', path: `${UrlPrefix.DistProfile}/distributor-contact-info`}
		]
	},
	{
		title: 'Products',
		children: [
			{id: 'PRODUCT_INFO', title: 'Product Information', path: `${UrlPrefix.ProductInfo}/product-info`}
		]
	},
	{
		title: 'Manual Entry',
		children: [
			{id: 'ME_FILE_UPLOAD', title: 'File Upload', path: `${UrlPrefix.ManualEntry}/file-upload`}
		]
	},
	{
		title: 'Utilities',
		children: [
			{id: 'DIST_XREF_VLDN', title: 'Product XRef Validation', path: `${UrlPrefix.Utilities}/distr-product-xref-validation`},
			{id: 'SLS_REJCT', title: 'Historical Sales Data', path: `${UrlPrefix.Utilities}/hist-sales-rejected-data`}
		]
	}
];


// Set routing of modules
export const moduleRoutes = [
	{id: 'HOME', component: Home, link: '/'},
	{id: 'PROD_ROLLUP', component: ProductRollup, link: `${UrlPrefix.Rollups}/product-rollup`},
	{id: 'GEO_ROLLUP', component: GeographyRollup, link: `${UrlPrefix.Rollups}/geography-rollup`},
	{id: 'SLS_CAL_NSD', component: SalesCalNonSellingDays, link: `${UrlPrefix.SalesCalendar}/non-selling-days`},
	{id: 'SLS_CAL_EOM', component: SalesCalEndOfMonth, link: `${UrlPrefix.SalesCalendar}/end-of-month`},
	{id: 'SF_HIERARCHY', component: SalesForceHierarchy, link: `${UrlPrefix.SalesForce}/sales-force-hierarchy`},
	{id: 'SF_REP_NAME', component: SalesForceRepNames, link: `${UrlPrefix.SalesForce}/sales-rep-names`},
	{id: 'SLS_REJCT', component: HistSalesRejectedData, link: `${UrlPrefix.Utilities}/hist-sales-rejected-data`},
	{id: 'DIST_XREF_VLDN', component: ProductXrefValidation, link: `${UrlPrefix.Utilities}/distr-product-xref-validation`},
	{id: 'DIST_CNTC_INFO', component: DistContactInfo, link: `${UrlPrefix.DistProfile}/distributor-contact-info`},
	{id: 'DIST_ACCNT_INFO', component: DistAccountProfile, link: `${UrlPrefix.DistProfile}/distributor-account-profile`},
	{id: 'PRODUCT_INFO', component: ProductInfo, link: `${UrlPrefix.ProductInfo}/product-info`},
	{id: 'ME_FILE_UPLOAD', component: MEntryFileUpload, link: `${UrlPrefix.ManualEntry}/file-upload`}
];
