import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { Salesforce } from '../utils/urls';


// Salesforce hierarchy service
export const SalesforceHierarchyService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(Salesforce.Hierarchy.ALL_DISTRIBUTORS_DATA);

        return result;
    },

	// Get all Sales Rep data for selected distributor with tree view data
    getSfRepManagersData: async (data) => {
        let result = await axios.get(Salesforce.Hierarchy.REP_ALL_MANAGER_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo
            }
        });
        rebuildResult(result);
        
        return result;
    },
    
    // Assign/Unassign RTE routes
	assignUnassignRteRoutes: async (rowData) => {
		let result = await axios.put(Salesforce.Hierarchy.UPD_REP_RTE_DIST_ASG_UNASSGN_MGR, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	},

    // Add RTE routes
	addRteRoutes: async (rowData) => {
		let result = await axios.post(Salesforce.Hierarchy.ADD_REP_RTE_MGRS, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	},

    // Update RTE routes
	updateRteRoutes: async (rowData) => {
		let result = await axios.put(Salesforce.Hierarchy.UPD_REP_RTE_MGRS, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	},

    // Delete RTE routes
	deleteRteRoutes: async (rowData) => {
		let result = await axios.delete(Salesforce.Hierarchy.DEL_REP_RTE_MGRS, {
            data: {
                data: rowData
            }
        });
        rebuildResult(result);
        
        return result;
	}

	// Assign RTE routes
	/* assignRteRoutes: async (rowData) => {
		let result = await axios.put(Salesforce.Hierarchy.UPD_REP_RTE_DIST_ASG_MGR, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	}, */

	// Un-Assign RTE routes
	/* unAssignRteRoutes: async (rowData) => {
		let result = await axios.put(Salesforce.Hierarchy.UPD_REP_RTE_DIST_UNASG_MGR, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	} */
}

// Re-build resultset
const rebuildResult = (result) => {
    if (!result?.data?.status?.isOk) {
        return;
    }

    // frc managers re-build to add uuid
    let _rows_data_frc_mgrs = [];
    result?.data?.sls_frcs?.frc_mgr?.rows?.forEach(item => {
        _rows_data_frc_mgrs.push({...item, UUID: uuid_v4()});
    });
    result.data.sls_frcs.frc_mgr = {
        ...result?.data?.sls_frcs?.frc_mgr,
        rows: _rows_data_frc_mgrs
    };
    
    // Area manager
    let _rows_data_frc_mgr_area = [];
    result?.data?.sls_frcs?.frc_mgr_area?.rows?.forEach(item => {
        _rows_data_frc_mgr_area.push({...item, UUID: uuid_v4()});
    });
    result.data.sls_frcs.frc_mgr_area = {
        ...result?.data?.sls_frcs?.frc_mgr_area,
        rows: _rows_data_frc_mgr_area
    };

    // District manager
    let _rows_data_frc_mgr_dist = [];
    result?.data?.sls_frcs?.frc_mgr_dist?.rows?.forEach(item => {
        _rows_data_frc_mgr_dist.push({...item, UUID: uuid_v4()});
    });
    result.data.sls_frcs.frc_mgr_dist = {
        ...result?.data?.sls_frcs?.frc_mgr_dist,
        rows: _rows_data_frc_mgr_dist
    };

    // Representative manager
    let _rows_data_frc_mgr_rep = [];
    result?.data?.sls_frcs?.frc_mgr_rep?.rows?.forEach(item => {
        _rows_data_frc_mgr_rep.push({...item, UUID: uuid_v4()});
    });
    result.data.sls_frcs.frc_mgr_rep = {
        ...result?.data?.sls_frcs?.frc_mgr_rep,
        rows: _rows_data_frc_mgr_rep
    };

    // Representative manager - incomplete/unassigned
    let _rows_data_frc_mgr_rep_iu = [];
    result?.data?.sls_frcs?.frc_mgr_rep_iu?.rows?.forEach(item => {
        _rows_data_frc_mgr_rep_iu.push({...item, UUID: uuid_v4()});
    });
    result.data.sls_frcs.frc_mgr_rep_iu = {
        ...result?.data?.sls_frcs?.frc_mgr_rep_iu,
        rows: _rows_data_frc_mgr_rep_iu
    };
}

// Build Tree view data
/* const generateTreeViewData = (result) => {
    result.data.sls_frcs_tree = {
        id: uuid_v4(),
        type: '',
        children: []
    };

    // frc managers
    result?.data?.sls_frcs?.frc_mgr?.rows?.forEach((el1, i1) => {
        result.data.sls_frcs_tree.children.push({
            id: el1?.UUID,
            name: el1?.SLS_FRC_D,
            item: el1,
            type: '_area',
            children: []
        });

        // Area managers
        const frc_mgr_area_rows = result?.data?.sls_frcs?.frc_mgr_area?.rows?.filter(o => o?.SLS_FRC_N === el1?.SLS_FRC_N);
        frc_mgr_area_rows?.forEach((el2, i2) => {
            result.data.sls_frcs_tree.children[i1]?.children.push({
                id: el2?.UUID,
                item: el2,
                type: '_dist',
                name: el2?.SLS_FRC_AREA_MGR_M,
                children: []
            });

            // District managers
            const frc_mgr_dist_rows = result?.data?.sls_frcs?.frc_mgr_dist?.rows?.filter(o => o?.SLS_FRC_AREA_MGR_N === el2?.SLS_FRC_AREA_MGR_N);
            frc_mgr_dist_rows?.forEach((el3, i3) => {
                result.data.sls_frcs_tree.children[i1]?.children[i2]?.children.push({
                    id: el3?.UUID,
                    item: el3,
                    type: '_rep',
                    name: el3?.SLS_FRC_DIST_MGR_M,
                    children: []
                });

                // Representative managers
                const frc_mgr_rep_rows = result?.data?.sls_frcs?.frc_mgr_rep?.rows?.filter(o => o?.SLS_FRC_DIST_MGR_N === el3?.SLS_FRC_DIST_MGR_N);
                frc_mgr_rep_rows?.forEach(el4 => {
                    result.data.sls_frcs_tree.children[i1]?.children[i2]?.children[i3]?.children.push({
                        id: el4?.UUID,
                        item: el4,
                        name: el4?.SLS_REP_M
                    });
                });
            });
        });
    });
} */
