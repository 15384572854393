import React, { useState, useEffect } from 'react';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import { DistAccountProfileService } from '../services/service.dist.account.profile';


// Distributor Account Profile Component
export const DistAccountProfile = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [tableData, setTableData] = useState({
        odata: null,
        cdata: null,
        gdata: null
    });
    const [actDwpValues, setActDwpValues] = useState();
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: EditOutlinedIcon,
        action: (e, d) => {}
    });
    const [showDialog, setShowDialog] = useState(false);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [searchTextClear, setSearchTextClear] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    })

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'Account Number', field: 'ACCNT_NBR', width: 120, visible: true, filter: false, sorting: true, searching: true},
            {label: 'Account Name', field: 'ACCNT_NAME', width: 160, visible: true, filter: false, sorting: true, searching: true},
            {label: 'Address', field: 'ACCNT_ADDR', width: 180, visible: true, filter: false, sorting: true, searching: true},
            {label: 'City', field: 'ACCNT_CITY', width: 80, visible: true, filter: false, sorting: true, searching: true},
            {label: 'State', field: 'ACCNT_STATE', width: 60, visible: true, filter: false, sorting: true},
            {label: 'Postal Code', field: 'ACCNT_PIN_CODE', width: 80, visible: true, filter: false, sorting: true},
            {label: 'Sales Type', field: 'ACCNT_SLS_TYPE', width: 100, visible: true, filter: false, sorting: true},
            {label: 'Status', field: 'ACCNT_STATUS', width: 100, visible: true, filter: false, sorting: true, searching: true},
            {label: 'Channel', field: 'ACCNT_CHANNEL', width: 100, visible: true, filter: false, sorting: true},
            {label: 'Key Account DBA', field: 'KEY_ACCNT_DBA', width: 160, visible: true, filter: false, sorting: true},
            {label: 'Key Account Corp', field: 'KEY_ACCNT_CORP', width: 140, visible: true, filter: false, sorting: true},
            {label: 'Key Account Group', field: 'KEY_ACCNT_GROUP', width: 140, visible: true, filter: false, sorting: true},
            {label: 'Key Account Zone', field: 'KEY_ACCNT_ZONE', width: 120, visible: true, filter: false, sorting: true}
        ],
        // Popup dialog columns while select rows for an action to perform
        /* pdgcolumns: [
            {label: 'Type', field: 'CNTC_TYP_DESC', width: 140, visible: true},
            {label: 'Name', field: 'CNTC_NAME', width: 220, visible: true},
            {label: 'Email', field: 'CNTC_EMAIL', width: 220, visible: true},
            {label: 'Phone', field: 'CNTC_PHONE', width: 100, visible: true}
        ], */
        // Context menu - mouse right button click
        /* ctxmenus: [
            {
                id: 'edit',
                label: 'Edit',
                icon: EditOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'CNTC_INFO_EDT',
                        title: this.label,
                        icon: this.icon,
                        actionText: 'Update',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems, {cbks, dwv}) => {
                            // console.log('>newRowItems>', newRowItems, cbks, dwv);
                            (async () => {
                                // await updateDistContactInfo(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []), cbks);
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
        ], */
        // Row actions
        actions: [
            {
                id: 'edit1',
                title: 'Edit',
                icon: EditOutlinedIcon,
                onClick: (e, {rowItem, gdata}) => {
                    if (!isModifyEnabled) {
                        return;
                    }

                    (async () => {
                        setShowOverlayLoader(true);

                        await DistAccountProfileService.getAccountProfileInfo({
                            jdeAbNo: jdeAddrBookNo?.id, 
                            accountNo: rowItem?.ACCNT_NBR
                        })
                        .then(response => {
                            const {rows} = response.data;
                            const _rowsNew = rows[0] ? {
                                ...rows[0], 
                                DIST_NBR: jdeAddrBookNo?.id, 
                                DIST_NAME: jdeAddrBookNo?.label2, 
                                UID: rowItem?.UUID
                            } : {}
                            // console.log('>_rowsNew>', _rowsNew);
                            /* env.getEnv('DIST_DEFAULT_MVMT_DWP_OFF_SALE')=1
                            env.getEnv('DIST_DEFAULT_MVMT_DWP_ON_SALE')=5
                            env.getEnv('DIST_DEFAULT_NA_OFF_SALE')=98
                            env.getEnv('DIST_DEFAULT_NA_ON_SALE')=99 */

                            setDialogType({
                                type: 'ACCNT_INFO_EDT',
                                title: 'Edit',
                                icon: EditOutlinedIcon,
                                actionText: 'Update',
                                rowItem: _rowsNew,
                                rowItem1: actDwpValues,
                                onClose: (e) => {
                                    setShowDialog(false);
                                },
                                action: (e, newRowItems, {cbks}) => {
                                    // console.log('>newRowItems>', newRowItems);
                                    (async () => {
                                        await updateAccountProfileInfo(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []), cbks, gdata);
                                    })();
                                }
                            });
                            setShowDialog(true);
                        })
                        .catch(e => {
                        })
                        .finally(() => {
                            setShowOverlayLoader(false);
                        });
                    })();
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: false,
            searching: true,
            morecolumns: true,
            distdwmenu: true,
            actionposition: 'start'    // start|end - default - end
        }
    }

    // Distributor Change Callback defination
    const onDistributorChangeCallback = (e, dwValue) => {
        setTableData(null);
        setJdeAddrBookNo(dwValue);
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        setSearchTextClear(Math.random().toString(36));
        if (!dwValue?.id) {
            // setTableData(null);
            return;
        }

        // Get Sales Rep data based on distributor
        (async () => {
            setShowLoader(true);

            await DistAccountProfileService.getAllAccountProfileData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {
                        rows: {accntproinfo, slsprimroutes, distreditroles, distrlictypes}
                    } = response.data;
                    setTableData(prev => ({
                        ...prev,
                        cdata: accntproinfo || [],
                        odata: accntproinfo || []
                    }));
                    setActDwpValues(prev => ({
                        ...prev,
                        slsprimroutes: slsprimroutes,
                        distreditroles: distreditroles,
                        distrlictypes: distrlictypes
                        // distrlictypes: {...distrlictypes, MALT_ALLW_X: 'N', SPRT_ALLW_X: 'N', WINE_ALLW_X: 'N'}
                    }));
                })
                .catch(e => {})
                .finally(() => {
                    setSearchTextClear(null);
                    setShowLoader(false);
                });
        })();
    }

    // Edit distributor account profile information
    const updateAccountProfileInfo = async (rowItems, cbks, gdata) => {
        await DistAccountProfileService.updateDistAccountProfile(rowItems)
            .then(response => {
                // console.log(response);
                const {status, rows} = response.data;
                if (status.isOk) {
                    if (!(rowItems instanceof Array)) {
                        rowItems = [rowItems];
                    }
                    let tblOdata = [...tableData?.odata || []];
                    const _updatedRowItem = {...rows[0], /* ACCNT_STATUS: 'UPDATED STATUS', ACCNT_CHANNEL: 'UPDATED CHANNEL',  */UUID: rowItems[0]?.UUID};
                    // console.log('_updatedRowItem>', _updatedRowItem);
                    const itemIndexFound = tblOdata?.findIndex(itm => itm?.UUID === rowItems[0]?.UUID);
                    tblOdata?.splice(itemIndexFound, 1, _updatedRowItem);
                    /* const rowItemsNew = tblOdata?.reduce((accu, curr) => [
                        ...accu, 
                        (
                            curr?.UUID === _updatedRowItem?.UUID ? 
                            {
                                ...curr,
                                ..._updatedRowItem
                            } : {...curr}
                        )
                    ], []);
                    console.log(rowItemsNew); */
                    const gdataNew = gdata?.reduce((accu, curr) => [
                        ...accu, 
                        (curr?.UUID === _updatedRowItem?.UUID ? {...curr, ..._updatedRowItem} : {...curr})
                    ], []);
                    setTableData(prev => ({
                        ...prev,
                        gdata: gdataNew,
                        odata: tblOdata
                    }));
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
                setTimeout(() => { cbks && cbks(); }, 4000);
            });
    }

    // useEffect hook to load all distributor product xref data
    useEffect(() => {
        (async () => {
            setShowLoader(true);

            // Get distributors
            await DistAccountProfileService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setTableData(null);
                    setShowLoader(false);
                    // Get dropdown values
                    DistAccountProfileService.getAccountProfileDropdownValues()
                        .then(response => {
                            const {rows} = response.data;
                            setActDwpValues(rows || {});
                            // Reload selected distributor data
                            const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                            onDistributorChangeCallback(null, sessStorageItems);
                        })
                        .catch(e => {})
                        .finally(() => {});
                });
        })();
    }, []);
    
    // Customized Grid Properties
	const customProps = {
		title: 'Distributor Account Maintenance',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader,
            showOverlayLoader: showOverlayLoader
        },
        vgrid: {
            id: 'idDistAccountProfileCntr',
            tblGridData: tableData,
            rowData: tableData?.cdata,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension,
                gheight: tableDimension?.height + 50,
                actionColumnWidth: 60
            },
            psearch: {
                width: 360,
                miscsearch: true,
                searchTextClear: searchTextClear,
                // gridOdata: tableData?.odata,
                placeholder: 'Account Search',
                disable: !jdeAddrBookNo?.id,
                cbks: {

                }
            },
            pdialog: {
                title: 'Distributor Account Profile',
                showDialog: showDialog,
                dialogType: dialogType
            },
            distrsdata: {
                jdeAbNo: jdeAddrBookNo,
                allDistributors: allDistributorsData,
                onDistributorChange: onDistributorChangeCallback
            }
        }
    }

    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )
}