import { env } from './env.variables';

// API base URL
const API_BASE_URL = env.getEnv('REACT_APP_API_BASE_URL');

// Rollups
export const Rollups = {
	Product: {
		ALL_PRODUCT_ROLLUPS: [API_BASE_URL, 'product/rollup/list-rollups'].join('/'),
		UPD_ROLLUP_POSITION: [API_BASE_URL, 'product/rollup/update-position'].join('/'),
		ADD_PRODUCT_ROLLUPS: [API_BASE_URL, 'product/rollup/add-rollups'].join('/'),
		UPD_PRODUCT_ROLLUPS: [API_BASE_URL, 'product/rollup/update-rollups'].join('/'),
		DEL_PRODUCT_ROLLUPS: [API_BASE_URL, 'product/rollup/delete-rollups'].join('/'),
		CUT_PASTE_ROLLUPS: [API_BASE_URL, 'product/rollup/cut-paste-rollups'].join('/')
	},

	Geography: {
		ALL_GEOGRAPHY_ROLLUPS: [API_BASE_URL, 'geography/rollup/list-rollups'].join('/'),
		UPD_ROLLUP_POSITION: [API_BASE_URL, 'geography/rollup/update-position'].join('/'),
		ADD_GEOGRAPHY_ROLLUPS: [API_BASE_URL, 'geography/rollup/add-rollups'].join('/'),
		UPD_GEOGRAPHY_ROLLUPS: [API_BASE_URL, 'geography/rollup/update-rollups'].join('/'),
		DEL_GEOGRAPHY_ROLLUPS: [API_BASE_URL, 'geography/rollup/delete-rollups'].join('/'),
		CUT_PASTE_ROLLUPS: [API_BASE_URL, 'geography/rollup/cut-paste-rollups'].join('/')
	}
}

// Historical Data
export const HistoricalData = {
	SalesRejectedData: {
		ALL_REJECTED_DATA: [API_BASE_URL, 'hist-sales/rejected-data/list'].join('/'),
		DEL_REJECTED_DATA: [API_BASE_URL, 'hist-sales/rejected-data/delete'].join('/'),
		PROCESS_REJECTED_DATA: [API_BASE_URL, 'hist-sales/rejected-data/process'].join('/')

	}
}

// Sales Calendar
export const SalesCalendar = {
	NonSellingDays: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'sales-calendar/non-selling-days/distributors/all'].join('/'),
		ALL_NSD_DAYS_DATA: [API_BASE_URL, 'sales-calendar/non-selling-days/days'].join('/'),
		UPD_NSD_DAYS_DATA: [API_BASE_URL, 'sales-calendar/non-selling-days/update'].join('/')
	},

	EndOfMonth: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'sales-calendar/end-of-month/distributors/all'].join('/'),
		ALL_EOM_DAYS_DATA: [API_BASE_URL, 'sales-calendar/end-of-month/day'].join('/'),
		UPD_EOM_DAYS_DATA: [API_BASE_URL, 'sales-calendar/end-of-month/update'].join('/')
	}
}

// Salesforce Hierarchy
export const Salesforce = {
	Hierarchy: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'salesforce/hierarchy/distributors/all'].join('/'),
		REP_ALL_MANAGER_DATA: [API_BASE_URL, 'salesforce/hierarchy/slsrepmgr/all'].join('/'),
		UPD_REP_RTE_DIST_ASG_UNASSGN_MGR: [API_BASE_URL, 'salesforce/hierarchy/rtemgrs/assgnunassgn'].join('/'),
		ADD_REP_RTE_MGRS: [API_BASE_URL, 'salesforce/hierarchy/rtemgrs/add'].join('/'),
		UPD_REP_RTE_MGRS: [API_BASE_URL, 'salesforce/hierarchy/rtemgrs/update'].join('/'),
		DEL_REP_RTE_MGRS: [API_BASE_URL, 'salesforce/hierarchy/rtemgrs/delete'].join('/')
	},
	
	RepNames: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'salesforce/repnames/distributors/all'].join('/'),
		REP_NAMES_DATA: [API_BASE_URL, 'salesforce/repnames/repdata/all'].join('/'),
		REP_NAMES_UPD_DATA: [API_BASE_URL, 'salesforce/repnames/repdata/update'].join('/')
	}
}

// Product Xref Validation
export const ProductXref = {
	Validation: {
		ALL_XREF_DATA: [API_BASE_URL, 'distprodxref/xref-validation/data/all'].join('/'),
		UPD_XREF_DATA: [API_BASE_URL, 'distprodxref/xref-validation/data/update'].join('/')
	}
}

// Distributor contact info
export const DistProfile = {
	ContactInfo: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'distprofile/contact-info/distributors/all'].join('/'),
		ALL_CONTACT_INFO_DATA: [API_BASE_URL, 'distprofile/contact-info/data/all'].join('/'),
		UPD_CONTACT_INFO_DATA: [API_BASE_URL, 'distprofile/contact-info/data/update'].join('/')
	},
	AccountProfile: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'distprofile/account-profile/distributors/all'].join('/'),
		ALL_ACT_PROF_DWP_VALUES: [API_BASE_URL, 'distprofile/account-profile/dwpvalues/all'].join('/'),
		ALL_ACCOUNT_PROFILE_DATA: [API_BASE_URL, 'distprofile/account-profile/data/all'].join('/'),
		SNGL_ACCOUNT_PROFILE_DATA: [API_BASE_URL, 'distprofile/account-profile/data/single'].join('/'),
		UPD_ACCOUNT_PROFILE_INFO: [API_BASE_URL, 'distprofile/account-profile/data/update'].join('/')
	}
}

// Manual Entry info
export const ManualEntry = {
	FileUpload: {
		ALL_DISTRIBUTORS_DATA: [API_BASE_URL, 'manualentry/file-upload/distributors/all'].join('/'),
		ALL_ME_UPLD_SLS_DATA: [API_BASE_URL, 'manualentry/file-upload/siadata/all'].join('/'),
		ALL_FILE_UPLOADS: [API_BASE_URL, 'manualentry/file-upload/files/upload'].join('/')
	}
}

// Product info
export const ProductInfo = {
	UpcInfo: {
		ALL_PRODUCT_UPC_DATA: [API_BASE_URL, 'products/product-info/upc/all'].join('/')
	}
}
