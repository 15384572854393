import React, { useCallback } from 'react';
import MuiAlert from '@mui/material/Alert';
import { Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { SnackbarCfg } from '../utils/utils';
import { StyledLoaderDiv, StyledLoadingDiv } from '../themes/style';


// Snackbar Alert
export const SnackbarAlert = (/* {isOpen, onSnackbarClose} */) => {
    // Close Snackbar
	const onCloseSnackbar = useCallback((e, reason) => {
		if (reason === 'clickaway') {
			return;
		}
        SnackbarCfg
            .setSeverity(SnackbarCfg.severity)
                .setMessage(null).close();
        
        /* onSnackbarClose && 
            onSnackbarClose(); */
    }, [/* onSnackbarClose */])

    // console.log('>SnackbarCfg.open>', SnackbarCfg.open);

    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    return (
        <Snackbar open={SnackbarCfg.open} autoHideDuration={SnackbarCfg.duration} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity={SnackbarCfg.severity} sx={{width: '100%'}}>{SnackbarCfg.message}</Alert>
        </Snackbar>
    )
}


// Overlay progress loader
export const OverlayLoader = (props) => {
	const {loading=false, size=30, color='inherit', message='Please wait...'} = props;
    
	return (
		<Backdrop open={loading} sx={{color: '#0e5ead', backgroundColor: 'rgb(0 0 0 / 2%)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
			<StyledLoaderDiv>
				<CircularProgress size={size} color={color}/>
				<span style={{fontSize: '0.9rem', marginLeft: '0.8em'}}>{message}</span>
			</StyledLoaderDiv>
		</Backdrop>
	)
}


// Circular progress loader
export const CircularProgressLoader = (props) => {
	const {loading=false, size=16, message='Please wait...'} = props;
    
	return (
        <>
            {loading && 
                <StyledLoadingDiv>
                    <CircularProgress disableShrink size={size}/>
                    <span style={{paddingTop: '3px'}}>{message}</span>
                </StyledLoadingDiv>
            }
        </>
    )
}
