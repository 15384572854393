import { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { smisTheme } from './themes/theme';
import { SideNav } from './common/common.sidebar';
import { Header } from './common/common.header';
import { Footer } from './common/common.footer';
import { NotFound } from './modules/module.notfound';
import { moduleRoutes, navMenuItems, distAccessModules } from './routes';
import { env } from './utils/env.variables';
import { RequestInterceptor } from './auth/azure.interceptor';
import { loginRequest, graphRequest, /* meGroupsRequest */ } from './auth/azure.auth.config';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, AuthenticatedTemplate, useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { useWindowDimensions } from './hooks/hook.window.dimensions';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';


// SMIS App component
const SmisAppComponent = (props) => {
	const {graphData} = props;
	const leftDivRef = useRef();
	const {width, height} = useWindowDimensions();
	const isUserHasViewAccess = !!graphData?.isUserCanView;
	const isDistHasViewAccess = !!graphData?.isDistCanAccess;
	const [dimensions, setDimensions] = useState({
		owidth: width,
		width: width,
		height: height
	});
	const [leftNavState, setLeftNavState] = useState({
		width: 12,
		cwidth: 12,
		open: true
	});

	// Toggle left navigation
	const onToggleLeftNav = (e) => {
		setLeftNavState(prev => ({
			...prev,
			cwidth: !!prev.open ? 1.5 : prev.width,
			open: !prev.open
		}));
// console.log(dimensions, width);
		// Re-calculate dimensions
		setDimensions(prev => ({
			...prev,
			// width: !!leftNavState.open ? (width - 46) : prev?.owidth
			width: !!leftNavState.open ? (width - 66) : prev?.owidth
		}));
	}

	// useEffect hook to re-calculate left/right container width
	useEffect(() => {
		// const _width = ((width - (leftDivRef?.current?.clientWidth || 183)) - 30);
		const _width = ((width - (leftDivRef?.current?.clientWidth || 183)) - 30);
		// const _height = (leftDivRef?.current?.clientHeight || window.screen.availHeight) - 210;
		const _height = (leftDivRef?.current?.clientHeight || window.screen.availHeight - 222) - 80;
		setDimensions({
			owidth: _width,
			width: _width,
			height: _height
		});
	}, [width])

	return (
		<div className="wrapper">
			<div className="main-content">
				<Header graphData={graphData}/>
				<div className="content">
					<AuthenticatedTemplate>
						<RequestInterceptor>
							{(isUserHasViewAccess || isDistHasViewAccess) && 
								<>
									<div ref={leftDivRef} className="content-left" style={{width: `${leftNavState?.cwidth}%`}}>
										<IconButton onClick={onToggleLeftNav} sx={{padding: 0, right: '4px', position: 'absolute'/* , border: '1px solid #76aee4' */}}>
											{leftNavState?.open ? <ChevronLeftIcon sx={{width: '0.78em', height: '0.78em'}}/> : <ChevronRightIcon sx={{width: '0.78em', height: '0.78em'}}/>}
										</IconButton>
										{leftNavState?.open && <SideNav routes={navMenuItems} />}
									</div>
									<div className="content-right" /* style={{width: `${100 - leftNavState?.cwidth}%`}} */>
										<Routes>
											{moduleRoutes.map((module, idx) => (
												isDistHasViewAccess && distAccessModules.includes(module?.id) 
													? <Route exact key={`${idx}${module}_${(new Date()).getTime()}`} element={<module.component leftDivRef={leftDivRef} dimensions={dimensions} graphData={graphData}/>} path={module.link} />
													: isUserHasViewAccess
														? <Route exact key={`${idx}${module}_${(new Date()).getTime()}`} element={<module.component leftDivRef={leftDivRef} dimensions={dimensions} graphData={graphData}/>} path={module.link} />
														: null
											))}
											{/* {moduleRoutes.map((module, idx) => {
												isDistHasViewAccess && distAccessModules.includes(module?.id)
												console.log(module, distAccessModules, distAccessModules.includes(module?.id));
												return (
													<Route exact key={`${idx}${module}_${(new Date()).getTime()}`} element={<module.component leftDivRef={leftDivRef} dimensions={dimensions} graphData={graphData}/>} path={module.link} />
												)
											})} */}
											<Route key={`_notfound_${(new Date()).getTime()}`} path="*" element={<NotFound/>} />
										</Routes>
									</div>
								</>
							}
							{!(isUserHasViewAccess || isDistHasViewAccess) && graphData !== null && 
								<h3 style={{textAlign: 'center', flexGrow: 1, margin: '6em'}}>{env.getEnv('REACT_APP_MSG_UNAUTHORIZED')}</h3>
							}
						</RequestInterceptor>
					</AuthenticatedTemplate>
				</div>
			</div>
			<Footer />
		</div>
	);
}

// Microsoft Graph API call
const callMsGraph = async (response) => {
	return await axios.create().get(graphRequest.graphMeEndpoint, {
		headers: {
			Authorization: `${(response.tokenType || 'Bearer')} ${response.accessToken}`
		}
	})
	.then(response => response)
    .catch(error => error?.response?.error);
}

// Microsoft Graph API call - get member groups where the user belongs to
/* const getMemberGroups = async ({type, token}) => {
	return await axios.create().get(meGroupsRequest.meGroupsEndpoint, {
		headers: {
			Authorization: `${(type || 'Bearer')} ${token}`
		}
	})
	.then(response => {
		console.log('group response>', response);
	})
    .catch(error => {
		console.log('group response>', error);
	});
} */

// Build graph data
const getMsGraphData = (msGraphData) => {
	const {data: msGrphData, idTokenClaims} = msGraphData;
	// console.log('>msGraphData>', msGraphData);

	let userData = msGrphData && {
		...msGraphData?.data,
		name: [msGrphData?.givenName, msGrphData?.surname].join(' '),
		fname: msGrphData?.givenName,
		username: msGrphData?.userPrincipalName,
		upn: msGrphData?.userPrincipalName,
		initials: ((msGrphData?.givenName?.charAt(0) + msGrphData?.surname?.charAt(0)) || '').toUpperCase(),
		roles: idTokenClaims?.roles,
		isUserCanView: (idTokenClaims?.roles?.includes('view') || idTokenClaims?.roles?.includes('modify')),
		isUserCanModify: idTokenClaims?.roles?.includes('modify'),
		isDistCanAccess: idTokenClaims?.roles?.includes('dist')
	}
	return userData;
}

// App component
const App = () => {
	const {instance, inProgress, accounts} = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [graphData, setGraphData] = useState(null);
	
	// Redirect to Azure if user is not logged in
	useMsalAuthentication(InteractionType.Redirect, loginRequest);
	
	// useEffect hook for default activities
	useEffect(() => {
		(async () => {
			// Retrieve access token to get ms user graph data
			if (isAuthenticated && inProgress === InteractionStatus.None) {
				const aadRequest = {
					...loginRequest,
					account: (accounts[0] || {})
				};
				await instance.acquireTokenSilent(aadRequest)
					.then(async tokenResponse => {
						// Fetch user graph data and set to graphData variable
						await callMsGraph(tokenResponse)
							.then(async graphResponse => {
								/* const memberGroups = await getMemberGroups({
									type: tokenResponse.tokenType,
									token: tokenResponse.accessToken
								});
								console.log('memberGroups>', memberGroups); */

								const msGraphData = getMsGraphData({
									...graphResponse,
									idTokenClaims: tokenResponse?.account?.idTokenClaims
								});
								setGraphData(msGraphData);
							})
							.catch(e => {console.log(e);});
					})
					.catch(async e => {
						await instance.acquireTokenPopup(aadRequest)
							.then(async tokenResponse => {
								// Fetch user graph data and set to graphData variable
								await callMsGraph(tokenResponse)
									.then(graphResponse => {
										const msGraphData = getMsGraphData({
											...graphResponse,
											idTokenClaims: tokenResponse?.account?.idTokenClaims
										});
										setGraphData(msGraphData);
									})
									.catch(e => {console.log(e);});
							})
							.catch(e => {console.log(e);});
					});
			}
		})();
	}, [isAuthenticated, inProgress, instance, accounts]);

	return (
		<ThemeProvider theme={smisTheme}>
			<BrowserRouter>
				<CssBaseline/>
				<SmisAppComponent graphData={graphData} />
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
