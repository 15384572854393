import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Autocomplete, FormControl, Paper, TextField } from '@mui/material';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import { SalesforceHierarchyService } from '../services/service.salesforce.hierarchy';
import { OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import { SfTreeView } from '../components/component.tree.views';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined';
import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';


// Table main container
const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1.2em;
	padding: 0 .1em;
`;
// Table title style
const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;
// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 30%;
    margin-left: 1.5em;
    margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>.MuiInputLabel-root {
            top: -2px;
        }
        &>.MuiInputBase-root {
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;


// Sales Force Hierarchy
export const SalesForceHierarchy = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [table1Data, setTable1Data] = useState({
        odata: null,
        cdata: null
    });
    const [table2Data, setTable2Data] = useState({
        odata: null,
        cdata: null
    });
    const [treeViewData, setTreeViewData] = useState();
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: ArrowCircleRightOutlinedIcon,
        action: (e, d) => {}
    });
    const [parentNode, setParentNode] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [routeLevel, setRouteLevel] = useState(0);
    // const [expandedNodeIds, setExpandedNodeIds] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    });

    const tbl1ColumnsIdentifier = {
        0: {lbl1: 'SalesForce #', lbl2: 'SalesForce Desc'},
        1: {lbl1: 'Area Mgr #', lbl2: 'Area Manager Name'},
        2: {lbl1: 'Dist Mgr #', lbl2: 'Dist Manager Name'},
        3: {lbl1: 'Route #', lbl2: 'Representative Name'}/* ,
        4: {lbl1: 'Route #', lbl2: 'Representative Name'} */
    }
    const [tbl1Columns, setTbl1Columns] = useState([
        {label: tbl1ColumnsIdentifier[0].lbl1, field: 'SLS_FRC_NO', width: 80, visible: true, filter: true, sorting: true},
        {label: tbl1ColumnsIdentifier[0].lbl2, field: 'SLS_FRC_DESC', width: 280, visible: true, filter: true, sorting: true, searching: false}
    ]);
    const [tbl1ExtActProps, setTbl1ExtActProps] = useState({
        ctx_visible_tbl1addroute1: true,
        ctx_visible_tbl1deleteroute1: true,
        ctx_visible_tbl1unassignroute1: false,
        act_visible_tbl1deleteroute2: true,
        act_visible_tbl1unassignroute2: false
    });
    const [tbl2ExtActProps, setTbl2ExtActProps] = useState({
        ctx_visible_tbl2assignroute1: false
    });
    
    // Table grid input properties - columns/options/actions etc.
    const tableInputProps1 = {
        // Popup dialog columns while select rows for an action to perform
        pdgcolumns: [
            {label: 'ID #', field: 'SLS_FRC_NO', width: 100, visible: true},
            {label: 'Name/Description', field: 'SLS_FRC_DESC', width: 260, visible: true}
        ],
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'tbl1addroute1',
                label: 'Add New',
                disable: !jdeAddrBookNo,
                icon: AddCircleOutlineOutlinedIcon,
                callback: (e) => {
                    if (!isModifyEnabled) {
                        return;
                    }

                    setDialogType({
                        type: 'REP_FRC_ADD',
                        title: 'Add',
                        icon: SaveIcon,
                        actionText: 'Add',
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            const newRowItemsNew = newRowItems?.reduce((accu, curr) => [...accu, {...curr, SLS_FRC_NO: parentNode?.item?.SLS_FRC_NO, JDE_ADDR_BOOK_N: jdeAddrBookNo?.id, AZR_USR_ID: userInfo?.upn}], []);
                            (async () => {
                                await addRteRoutes({level: (parentNode?.level || 0), rowItem: newRowItemsNew});
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'tbl1editroute1',
                label: 'Edit',
                icon: EditOutlinedIcon,
                callback: (e, {rowItems}) => {
                    if (!isModifyEnabled) {
                        return;
                    }
                    
                    setDialogType({
                        type: 'REP_FRC_EDT',
                        title: 'Edit',
                        icon: SaveIcon,
                        actionText: 'Update',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await updateRteRoutes({level: (parentNode?.level || 0), rowItem: newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], [])});
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'tbl1deleteroute1',
                label: 'Delete',
                icon: DeleteOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }

                    setDialogType({
                        type: 'REP_FRC_DEL',
                        title: this.label,
                        icon: this.icon,
                        actionText: 'Delete',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            const rowItemUuids = newRowItems?.reduce((accu, curr) => [...accu, curr?.UUID], []);
                            const rowChildItems = (parentNode || treeViewData)?.children?.filter(o => rowItemUuids?.includes(o.item?.UUID));
                            (async () => {
                                await deleteRteRoutes(rowChildItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'tbl1unassignroute1',
                label: 'Unassign',
                icon: ContentPasteOffOutlinedIcon,
                callback: (e, {rowItems}) => {
                    if (!isModifyEnabled) {
                        return;
                    }

                    (async () => {
                        setShowOverlayLoader(true);
                        await assignUnassignRteRoutes(rowItems?.reduce((accu, curr) => [...accu, {...curr, PARENT_FRC_ID: null, AZR_USR_ID: userInfo?.upn}], []));
                    })();
                }
            }
        ],
        // Row actions
        actions: [
            {
                title: 'Edit',
                id: 'tbl1editroute2',
                icon: EditOutlinedIcon,
                onClick: (e, {rowItem}) => {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    
                    setDialogType({
                        type: 'REP_FRC_EDT',
                        title: 'Edit',
                        icon: SaveIcon,
                        actionText: 'Update',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await updateRteRoutes({level: (parentNode?.level || 0), rowItem: newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], [])});
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                title: 'Delete',
                id: 'tbl1deleteroute2',
                icon: DeleteOutlinedIcon,
                onClick: (e, {rowItem}) => {
                    if (!isModifyEnabled) {
                        return false;
                    }

                    setDialogType({
                        type: 'REP_FRC_DEL',
                        title: 'Delete',
                        icon: DeleteOutlinedIcon,
                        actionText: 'Delete',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            const rowItemUuids = newRowItems?.reduce((accu, curr) => [...accu, curr?.UUID], []);
                            const rowChildItems = (parentNode || treeViewData)?.children?.filter(o => rowItemUuids?.includes(o.item?.UUID));
                            (async () => {
                                await deleteRteRoutes(rowChildItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                title: 'Unassign',
                id: 'tbl1unassignroute2',
                icon: ContentPasteOffOutlinedIcon,
                onClick: (e, {rowItem}) => {
                    if (!isModifyEnabled) {
                        return false;
                    }

                    (async () => {
                        setShowOverlayLoader(true);
                        await assignUnassignRteRoutes({...rowItem, PARENT_FRC_ID: null, AZR_USR_ID: userInfo?.upn});
                    })();
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: true,
            overlay: false,
            rowclick: true,
            contextmenu: true,
            dynscols: true,
            snackbar: false,
            titlecolor: true
        }
    }

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps2 = {
        // Columns
        columns: [
            {label: 'Route #', field: 'SLS_RTE_N', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Representative Name', field: 'SLS_REP_M', width: 220, visible: true, filter: true, sorting: true, searching: false}
        ],
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'tbl2assignroute1',
                label: 'Assign',
                icon: ContentPasteGoOutlinedIcon,
                callback: (e, {rowItems}) => {
                    if (!isModifyEnabled) {
                        return;
                    }
                    (async () => {
                        setShowOverlayLoader(true);
                        await assignUnassignRteRoutes(rowItems?.reduce((accu, curr) => [...accu, {...curr, PARENT_FRC_ID: parentNode?.item?.SLS_FRC_NO, AZR_USR_ID: userInfo?.upn}], []));
                    })();
                }
            }
        ],
        // Row actions
        actions: [
            {
                id: 'tbl2assignroute2',
                title: 'Assign',
                icon: ContentPasteGoOutlinedIcon,
                onClick: (e, {rowItem}) => {
                    if (!isModifyEnabled) {
                        return;
                    }
                    (async () => {
                        setShowOverlayLoader(true);
                        await assignUnassignRteRoutes({...rowItem, PARENT_FRC_ID: parentNode?.item?.SLS_FRC_NO, AZR_USR_ID: userInfo?.upn});
                    })();
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: true,
            overlay: false,
            rowclick: true,
            contextmenu: true,
            popdialog: false,
            snackbar: false,
            titlecolor: true
        }
    }


    // Find items
    const searchItem = (nodeId, nodes) => {
        const traverse = (callback) => {
            const goThrough = (node) => {
                callback(node);
                node?.children?.forEach(child => {
                    goThrough(child);
                });
            }
            goThrough(nodes);
        }

        let itemFound = null;
        traverse(node => {
            if (nodeId === node?.id) {
                itemFound = node;
            }
        });
        return itemFound;
    }

    // Build Tree view data
    const generateTreeViewData = ({frc_mgr, frc_mgr_area, frc_mgr_dist, frc_mgr_rep}) => {
        let sls_frcs_tree = {
            id: 'root',
            name: 'Parent',
            type: '',
            level: 0,
            children: []
        };

        // frc managers
        frc_mgr?.rows?.forEach((el1, i1) => {
            sls_frcs_tree.children.push({
                id: el1?.ROW_ID,
                name: el1?.SLS_FRC_D,
                item: el1,
                type: '_area',
                level: 1,
                children: []
            });

            // Area managers
            const frc_mgr_area_rows = frc_mgr_area?.rows?.filter(o => o?.SLS_FRC_N === el1?.SLS_FRC_N);
            frc_mgr_area_rows?.forEach((el2, i2) => {
                sls_frcs_tree.children[i1]?.children.push({
                    id: el2?.ROW_ID,
                    item: el2,
                    type: '_dist',
                    level: 2,
                    name: el2?.SLS_FRC_AREA_MGR_M,
                    children: []
                });

                // District managers
                const frc_mgr_dist_rows = frc_mgr_dist?.rows?.filter(o => o?.SLS_FRC_AREA_MGR_N === el2?.SLS_FRC_AREA_MGR_N);
                frc_mgr_dist_rows?.forEach((el3, i3) => {
                    sls_frcs_tree.children[i1]?.children[i2]?.children.push({
                        id: el3?.ROW_ID,
                        item: el3,
                        type: '_rep',
                        level: 3,
                        name: el3?.SLS_FRC_DIST_MGR_M,
                        children: []
                    });

                    // Representative managers
                    const frc_mgr_rep_rows = frc_mgr_rep?.rows?.filter(o => o?.SLS_FRC_DIST_MGR_N === el3?.SLS_FRC_DIST_MGR_N);
                    frc_mgr_rep_rows?.forEach(el4 => {
                        sls_frcs_tree.children[i1]?.children[i2]?.children[i3]?.children.push({
                            id: el4?.ROW_ID,
                            item: el4,
                            level: 4,
                            name: el4?.SLS_REP_M
                        });
                    });
                });
            });
        });

        return sls_frcs_tree;
    }

    // Set state values
    const setStateValues = (sls_frcs) => {
        const {frc_mgr, frc_mgr_area, frc_mgr_dist, frc_mgr_rep, frc_mgr_rep_iu} = sls_frcs;
        const sls_frcs_levels = {
            frc_mgrs_0: frc_mgr,
            frc_mgrs_1: frc_mgr_area,
            frc_mgrs_2: frc_mgr_dist,
            frc_mgrs_3: frc_mgr_rep,
            frc_mgrs_4: frc_mgr_rep_iu
        }
        const sls_frc_fields = {
            0: '',
            1: 'SLS_FRC_N',
            2: 'SLS_FRC_AREA_MGR_N',
            3: 'SLS_FRC_DIST_MGR_N'
        }
        const sls_frcs_tree = generateTreeViewData(sls_frcs);
        const parentNodeItem = searchItem(parentNode?.id, sls_frcs_tree);
        const frc_cdata = sls_frcs_levels[`frc_mgrs_${parentNodeItem?.level || 0}`];
        const sls_frc_number = sls_frc_fields[(parentNodeItem?.level || 0)];
        
        setParentNode(parentNodeItem);
        setTable1Data({
            odata: {
                frc_mgr: frc_mgr,
                frc_mgr_area: frc_mgr_area,
                frc_mgr_dist: frc_mgr_dist,
                frc_mgr_rep: frc_mgr_rep
            },
            cdata: frc_cdata?.rows?.filter(o => o[sls_frc_number] === (parentNodeItem?.item && parentNodeItem?.item[sls_frc_number]))
        });
        setTable2Data({
            odata: frc_mgr_rep_iu?.rows,
            cdata: frc_mgr_rep_iu?.rows?.filter(o => o.SLS_REP_M !== null || o.SLS_REP_M?.length > 0)
        });
        setTreeViewData(prev => ({
            ...sls_frcs_tree,
            item: prev?.item,
            name: prev?.name
        }));
        setTbl1ExtActProps(prev => ({
            ...prev,
            ctx_visible_tbl1unassignroute1: (parentNodeItem?.type === '_rep'),
            act_visible_tbl1unassignroute2: (parentNodeItem?.type === '_rep')
        }));
        setTbl2ExtActProps(prev => ({
            ...prev,
            ctx_visible_tbl2assignroute1: (parentNodeItem?.type === '_rep' && (frc_mgr_rep_iu?.rows?.filter(o => o.SLS_REP_M !== null || o.SLS_REP_M?.length > 0))?.length)
        }))
    }

    // Assign routes
    const assignUnassignRteRoutes = async (rowItem) => {
        await SalesforceHierarchyService.assignUnassignRteRoutes(rowItem)
            .then(response => {
                const {status, sls_frcs} = response.data;
                if (status.isOk) {
                    setStateValues(sls_frcs);
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowOverlayLoader(false);
            });
    }

    // Edit routes - area/dist/rte
    const updateRteRoutes = async (rowItems) => {
        await SalesforceHierarchyService.updateRteRoutes(rowItems)
            .then(response => {
                const {status, sls_frcs} = response.data;
                if (status.isOk) {
                    setStateValues(sls_frcs);
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
            });
    }

    // Delete routes - area/dist/rte
    const deleteRteRoutes = async (rowItem) => {
        await SalesforceHierarchyService.deleteRteRoutes(rowItem)
            .then(response => {
                console.log('>delete-routes>', response);
                const {status, sls_frcs} = response.data;
                if (status.isOk) {
                    setStateValues(sls_frcs);
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_DELETE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
            });
    }

    // Add routes - area/dist/rte
    const addRteRoutes = async (rowItems) => {
        await SalesforceHierarchyService.addRteRoutes(rowItems)
            .then(response => {
                const {status, sls_frcs} = response.data;
                if (status.isOk) {
                    setStateValues(sls_frcs);
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
            });
    }
    
    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        setJdeAddrBookNo(dwValue);
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        if (!dwValue?.id) {
            setTable1Data(null);
            setTable2Data(null);
            setTreeViewData({
                id: 'root',
                name: 'Parent'
            });
            return;
        }

        // Get Sales Rep data based on distributor
        (async () => {
            setShowLoader(true);
            setTreeViewData(null);

            await SalesforceHierarchyService.getSfRepManagersData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {sls_frcs, status} = response.data;
                    const {frc_mgr, frc_mgr_area, frc_mgr_dist, frc_mgr_rep, frc_mgr_rep_iu} = sls_frcs;
                    const sls_frcs_tree = generateTreeViewData(sls_frcs);
                    
                    setTable1Data({
                        odata: {
                            frc_mgr: frc_mgr,
                            frc_mgr_area: frc_mgr_area,
                            frc_mgr_dist: frc_mgr_dist,
                            frc_mgr_rep: frc_mgr_rep
                        },
                        cdata: frc_mgr?.rows
                    });
                    setTable2Data({
                        cdata: undefined,
                        odata: frc_mgr_rep_iu?.rows
                    });
                    setTreeViewData(prev => ({
                        ...prev,
                        ...sls_frcs_tree,
                        item: dwValue,
                        name: dwValue?.label
                    }));
                    
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, []);
    
    // onNodeToggleCallback to handle tree callback
    /* const onNodeToggleCallback = (e, nodeIds) => {
        // console.log('>onNodeToggleCallback>', nodeIds);

        // setExpandedNodeIds(nodeIds);
    } */

    // onNodeSelectCallback to handle tree callback
    const onNodeSelectCallback = (e, {nodeItem}) => {
        setParentNode(nodeItem);
        setRouteLevel(nodeItem?.level);
        setTbl1Columns(prev => prev?.reduce((accu, curr, index) => [...accu, {...curr, i: index, label: tbl1ColumnsIdentifier[nodeItem?.level]?.[`lbl${index + 1}`]}], []));
        setTable1Data(prev => ({
            ...prev,
            cdata: nodeItem?.children?.reduce((accu, curr) => [...accu, curr?.item], [])
        }));
        setTable2Data(prev => ({
            ...prev,
            cdata: nodeItem?.type === '_rep' ? prev.odata?.filter(o => o.SLS_REP_M !== null || o.SLS_REP_M?.length > 0) : null
        }));
        setTbl1ExtActProps(prev => ({
            ...prev,
            ctx_visible_tbl1addroute1: (nodeItem?.type !== '_rep'),
            ctx_visible_tbl1deleteroute1: (nodeItem?.type !== '_rep'),
            ctx_visible_tbl1unassignroute1: (nodeItem?.type === '_rep'),
            act_visible_tbl1unassignroute2: (nodeItem?.type === '_rep'),
            act_visible_tbl1deleteroute2: (nodeItem?.type !== '_rep')
        }));
        setTbl2ExtActProps(prev => ({
            ...prev,
            ctx_visible_tbl2assignroute1: (nodeItem?.type === '_rep' && (table2Data.odata?.filter(o => o.SLS_REP_M !== null || o.SLS_REP_M?.length > 0))?.length)
        }));
    }

	// userEffect hook to load all distributors data
    useEffect(() => {
        (async () => {
            setShowLoader(true);
            await SalesforceHierarchyService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setTable1Data(null);
                    setTable2Data(null);
                    setTreeViewData({
                        id: 'root',
                        name: 'Parent'
                    });
                    setShowLoader(false);
                });
        })();
    }, [onDistributorChangeCallback]);
    
    console.log(tableDimension);
    // Customized Grid Properties
	const customProps = {
		title: 'Sales Force Hierarchy',
		jdeAbNo: jdeAddrBookNo,
		allDistributors: allDistributorsData,
        gridWidth: tableDimension?.width,
        routeLevel: routeLevel,
        treegrid: {
            title: 'Hierarchy Tree View',
            width: tableDimension?.width/3 - 10,
            height: tableDimension?.height - 30,
            nodes: treeViewData,
            callbacks: {
                // onNodeToggle: onNodeToggleCallback,
                onNodeSelect: onNodeSelectCallback
            }
        },
        vgrid1: {
            title: 'Sales Force Hierarchy - Assigned',
            isModifyEnabled: isModifyEnabled,
            loader: {
                showLoader: showLoader
            },
            vgrid: {
                id: 'idAssignRouteCntr',
                rowData: table1Data?.cdata,
                tblProps: {...tableInputProps1, columns: tbl1Columns},
                tblExtActProps: tbl1ExtActProps,
                dimensions: {
                    ...tableDimension,
                    gheight: tableDimension?.height - 60,
                    actionColumnWidth: 60,
                    width: tableDimension?.width/3 + 30
                },
                psearch: {
                    // width: 200
                },
                pdialog: {
                    showDialog: showDialog,
                    dialogType: dialogType
                }
            }
        },
        vgrid2: {
            title: 'Sales Force Hierarchy - Unassigned',
            isModifyEnabled: isModifyEnabled,
            loader: {
                showLoader: showLoader
            },
            vgrid: {
                id: 'idUnAssignRouteCntr',
                rowData: table2Data?.cdata,
                tblProps: tableInputProps2,
                tblExtActProps: tbl2ExtActProps,
                dimensions: {
                    ...tableDimension,
                    gheight: tableDimension?.height - 60,
                    actionColumnWidth: 60,
                    width: tableDimension?.width/3 - 30
                },
                psearch: {
                    // width: 200
                },
                pdialog: {
                    showDialog: showDialog,
                    dialogType: dialogType
                }
            }
        },
		callbacks: {
			onDistributorChange: onDistributorChangeCallback
		}
    }

	return (
		<Container>
            <SnackbarAlert/>
			<SfHierarchyContainer {...customProps}/>
            <OverlayLoader loading={showOverlayLoader}/>
        </Container>
	)
}

// Main Sales Force Hierarchy Container
const SfHierarchyContainer = React.memo(props => {

    return (
        <TableRowContainer>
            <TableCaptionBar {...props} />
            <TableContainer {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        jdeAbNo,
        allDistributors,
        callbacks: {onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title}</Title>

            <StyledDropDown variant="standard"/*  style={{marginTop: '-1rem', minWidth: '28%'}} */>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`,
                        label2: `${el.DIST_DESC}`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    PaperComponent={props => <Paper sx={{fontSize: '0.75rem !important'}} {...props}/>}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Table Caption Bar - Title / Search
const TableContainer = React.memo(props => {
    const {
        vgrid1,
        vgrid2,
        treegrid,
        gridWidth,
        routeLevel
    } = props;
    // const col1Width = (width - (vgrid || [...vgrid]).reduce((a, v) => a = a + v?.gridWidth, 0));
    
    return (
        <div style={{/* justifyContent: 'space-evenly',  */display: 'flex', width: `${gridWidth}px`, maxHeight: `${treegrid?.height}px`}}>
            <div style={{/* border: '1px solid',  */width: `${treegrid?.width}px`, display: 'flex'}}>
                <SfTreeView {...treegrid}/>
            </div>
            <div style={{/* border: '1px solid',  */marginLeft: '1.5em', display: 'flex', width: `${vgrid1?.vgrid?.dimensions?.width + 4}px`}}>
                <VirtualizedDataGrid1 {...vgrid1}/>
            </div>
            <div style={{opacity: routeLevel !== 3 ? 0 : undefined, /* border: '1px solid',  */marginLeft: '1.5em', width: `${vgrid2?.vgrid?.dimensions?.width + 4}px`}}>
                <VirtualizedDataGrid1 {...vgrid2}/>
            </div>
        </div>
    )
})