import React, { useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { SvgIcon, alpha } from '@mui/material';
import styled from '@emotion/styled';
import { CircularProgressLoader } from './component.snackbar.alert';


// Styled components
const StyledTreeItem = styled(props => (
        <TreeItem {...props} /* TransitionComponent={TransitionComponent} */ />
    ))(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3
            }
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 14,
            paddingLeft: 14,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
        }
    })
);
// Treeview Container
const StyledTreeViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    &>.MuiTreeView-root .MuiTreeItem-label {
        font-size: 0.775rem;
        padding-top: 2px;
        padding-bottom: 2px;
        min-height: 1.8em;
    }
`;
// Styled Tree view title
const StyledTitle = styled.h4`
    display: flex;
    flex-grow: 1;
    align-items: center;
	font-size: 0.92rem;
	user-select: none;
    margin: 0 0 0.9em;
    padding: 0.25em 0.1em;
    background-color: #0e7eed1a;
`;

// Tree view component
export const SfTreeView = (props) => {
    const {
        nodes,
        title,
        titleHeight,
        height='auto',
        width='auto',
        callbacks: {onNodeToggle, onNodeSelect}
    } = props;
    const [nodesExpanded, setNodesExpanded] = useState([]);
    const [nodesSelected, setNodesSelected] = useState([]);

    /* if (typeof nodes !== 'object') {
        return false;
    } */
    const treeWidth = width !== 'auto' ? `${width}px` : width,
        treeHeight = height !== 'auto' ? `${height}px` : height,
        treeMaxWidth = width === 'auto' ? `99%` : treeWidth;
    
    // Traverse through three nodes
    const traverse = (callback) => {
        const goThrough = (node) => {
            callback(node);
            node?.children?.forEach(child => {
                goThrough(child);
            });
        }
        goThrough(nodes);
    }
    /* hasChild(id) {
        return this.children.reduce((prev, curr) => prev && curr.id === id, false);
      } */

    // Find items
    const search = (nodeId) => {
        let itemFound = null;
        traverse(node => {
            if (nodeId === node?.id) {
                itemFound = node;
            }
        });
        return itemFound;
    }

    // Callbacks
    const onNodeToggleCallback = (e, nodeIds) => {
        setNodesExpanded(nodeIds);
        // console.log('>onNodeToggleCallback>', e, nodeIds);

        onNodeToggle && 
            onNodeToggle(e, nodeIds);
    }

    // Callbacks
    const onNodeSelectCallback = (e, nodeId) => {
        const itemFound = search(nodeId);
        if (itemFound?.type === undefined) {
            return;
        }

        setNodesSelected(nodeId);
        onNodeSelect && 
            onNodeSelect(e, {nodeId: nodeId, nodeItem: itemFound});
    }

    // userEffect hook to load data
    useEffect(() => {
        
        // console.log('nodesExpanded>', nodesExpanded);
        setNodesExpanded([nodes?.id]);
        setNodesSelected([nodes?.id]);
    }, [nodes?.id])

    return (
        <StyledTreeViewContainer style={{width: `${treeWidth}`}}>
            {title && <StyledTitle {...(titleHeight && {style: {height: titleHeight + 'px'}})}>{title}</StyledTitle>}
            {!nodes?.id && 
                <CircularProgressLoader loading={!nodes?.id}/>}
            {(typeof nodes === 'object') && 
                <TreeView
                    aria-label="Tree View"
                    // defaultExpanded={[`${nodes?.id}`]}
                    expanded={nodesExpanded}
                    selected={nodesSelected}
                    defaultCollapseIcon={<MinusSquareIcon />}
                    defaultExpandIcon={<PlusSquareIcon />}
                    defaultEndIcon={<CloseSquareIcon />}
                    onNodeToggle={onNodeToggleCallback}
                    onNodeSelect={onNodeSelectCallback}
                    sx={{flexGrow: 1, padding: '0 0.5em', overflowY: 'auto', height: `${treeHeight}`, width: `${treeWidth}`, maxWidth: `${treeMaxWidth}`}}>
                        <TreeItemRenderer items={nodes} />
                </TreeView>}
        </StyledTreeViewContainer>
    );
}

// Render tree items
const TreeItemRenderer = React.memo(props => {
	const { items } = props;
    if (!items?.id) {
        return;
    }

    // Build and render tree
    return (
        <StyledTreeItem key={items?.id} nodeId={items?.id} label={items?.name}>
            {items?.children?.map((child, index) => <TreeItemRenderer key={`${index}${child}_${(new Date()).getTime()}`} items={child} />)}
        </StyledTreeItem>
    )
})

// Custom Minus Icon
const MinusSquareIcon = React.memo(props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 10, height: 11, opacity: 0.8 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    )
})

// Custom Plus Icon
const PlusSquareIcon = React.memo(props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 10, height: 11, opacity: 0.8 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    )
})

// Custom Close Icon
const CloseSquareIcon = React.memo(props => {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 10, height: 11 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    )
})
