import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { DistProfile } from '../utils/urls';


// Distributor Account Profile service
export const DistAccountProfileService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(DistProfile.AccountProfile.ALL_DISTRIBUTORS_DATA);

        return result;
    },

    // Get all distributor account profile data
    getAllAccountProfileData: async (data) => {
        let result = await axios.get(DistProfile.AccountProfile.ALL_ACCOUNT_PROFILE_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo
            }
        });
        rebuildResult(result);

        return result;
    },

    // Get distributor account profile data by account number
    getAccountProfileInfo: async (data) => {
        let result = await axios.get(DistProfile.AccountProfile.SNGL_ACCOUNT_PROFILE_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo,
                accountNo: data?.accountNo
            }
        });
        rebuildResult(result);

        return result;
    },

    // Get account profile dropdown values
    getAccountProfileDropdownValues: async () => {
        let result = await axios.get(DistProfile.AccountProfile.ALL_ACT_PROF_DWP_VALUES);

        return result;
    },

	// Update distributor account profile information
	updateDistAccountProfile: async (rowData) => {
		let result = await axios.put(DistProfile.AccountProfile.UPD_ACCOUNT_PROFILE_INFO, {
            data: rowData
        });

        /* if (result?.data?.status?.isOk) {
            // Get distributor contact data
            result = await axios.get(DistProfile.ContactInfo.ALL_CONTACT_INFO_DATA, {
                params: {
                    jdeAbNo: (rowData?.JDE_ADDR_BOOK_N || rowData[0]?.JDE_ADDR_BOOK_N)
                }
            });
            rebuildResult(result);
        } */
        
        return result;
	}
}

// Re-build resultset to add uuid
const rebuildResult = (result) => {
    if (!result?.data?.status?.isOk) {
        return;
    }

    let _rows_data_account_profile_info = [];
    (result?.data?.rows?.accntproinfo || []).forEach(item => {
        _rows_data_account_profile_info.push({...item, UUID: uuid_v4()});
    });
    if (result?.data?.rows) {
        result.data = {
            ...result?.data, 
            rows: {
                ...result?.data?.rows,
                accntproinfo: _rows_data_account_profile_info
            }
        }
    }
}
