import React, { useState, useEffect } from 'react';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
import { HistSalesRejectedDataService } from '../services/service.hist.sales.rejected';


/* 
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { HistSalesRejectedDataService } from '../services/service.hist.sales.rejected';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SMISHistSalesRejectedDataTable } from '../components/component.table.virtualized.histsalesrej';
// import { env } from '../utils/env.variables';
// import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
// import { SnackbarCfg } from '../utils/utils';
// include utils module
// require('../utils/utils');
 */
/* 
// Severity level - error, info, success, warning
const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
};
 */

// Historical Rejected Data module
export const HistSalesRejectedData = (props) => {
    /* const btnCancelRef = useRef();
    const btnCloseRef = useRef(); */


    /* const [rowData, setRowData] = useState({
        odata: undefined,
        fdata: undefined,
        cdata: undefined
    }); */

    /* 
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    });
	
    const [showLoading] = useState(false);
    const [callbacks, setCallbacks] = useState(null); */

    
    const {dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
    const [showLoader, setShowLoader] = useState(false);
    // const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [tableData, setTableData] = useState({
        odata: null,
        cdata: null
    });
    // const [selectedRow, setSelectedRow] = useState({});
    const [, setShowSnackbar] = useState();
    const [dialogType, setDialogType] = useState({
        icon: ArrowCircleRightOutlinedIcon,
        action: (e, d) => {}
    });
    const [showDialog, setShowDialog] = useState(false);

    SnackbarCfg.setState({
        state: setShowSnackbar
    })



    // Grid columns
    /* const [xGridColumns, setXGridColumns] = useState([
        {label: 'State', field: 'ST_C', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Market', field: 'MKT_D', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Distributor Name', field: 'DIST_D', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'File Name', field: 'FILE_M', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'File Date', field: 'FILE_DATE', sortColumn: 'FILE_DATE_T', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'From Date', field: 'FROM_INVC_DATE', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'To Date', field: 'TO_INVC_DATE', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Rec Count', field: 'RECORD_COUNT', width: 80, visible: true, filter: true, sorting: true, searching: true}
    ]); */

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'State', field: 'ST_C', width: 60, visible: true, filter: true, sorting: true},
            {label: 'Market', field: 'MKT_D', width: 180, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Distributor Name', field: 'DIST_D', width: 320, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 100, visible: true, filter: true, sorting: true},
            {label: 'File Name', field: 'FILE_M', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'File Date', field: 'FILE_DATE', sortColumn: 'FILE_DATE_T', width: 150, visible: true, filter: true, sorting: true},
            {label: 'From Date', field: 'FROM_INVC_DATE', width: 110, visible: true, filter: true, sorting: true},
            {label: 'To Date', field: 'TO_INVC_DATE', width: 110, visible: true, filter: true, sorting: true},
            {label: 'Rec Count', field: 'RECORD_COUNT', width: 100, visible: true, filter: true, sorting: true}
        ],
        // Popup dialog columns while select rows for an action to perform
        pdgcolumns: [
            {label: 'Dist #', field: 'JDE_ADDR_BOOK_N', width: 70, visible: true},
            {label: 'Dist Name', field: 'DIST_D', width: 260, visible: true},
            {label: 'Market', field: 'MKT_D', width: 150, visible: true},
            {label: 'File Name', field: 'FILE_M', width: 120, visible: true},
            {label: 'File Date', field: 'FILE_DATE', width: 130, visible: true}
        ],
        // Context menu - mouse right button click
        ctxmenus: [
            {
                id: 'process',
                label: 'Process',
                icon: ArrowCircleRightOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'REJ_CFM',
                        title: this.label,
                        icon: this.icon,
                        actionText: 'Process',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await processHistoricalRejectedData(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'deletei',
                label: 'Delete',
                icon: DeleteOutlinedIcon,
                callback: function(e, {rowItems}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'REJ_DEL',
                        title: this.label,
                        icon: this.icon,
                        actionText: 'Delete',
                        rowItem: rowItems,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await deleteHistoricalRejectedData(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'cancel',
                label: 'Cancel',
                icon: CancelOutlinedIcon
            }
        ],
        // Row actions
        actions: [
            {
                id: 'process1',
                title: 'Process',
                icon: ArrowCircleRightOutlinedIcon,
                onClick: function(e, {rowItem}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'REJ_CFM',
                        title: this.title,
                        icon: this.icon,
                        actionText: 'Process',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await processHistoricalRejectedData(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                id: 'deletei1',
                title: 'Delete',
                icon: DeleteOutlinedIcon,
                onClick: function(e, {rowItem}) {
                    if (!isModifyEnabled) {
                        return;
                    }
                    setDialogType({
                        type: 'REJ_DEL',
                        title: this.title,
                        icon: this.icon,
                        actionText: 'Delete',
                        rowItem: rowItem,
                        onClose: (e) => {
                            setShowDialog(false);
                        },
                        action: (e, newRowItems) => {
                            (async () => {
                                await deleteHistoricalRejectedData(newRowItems?.reduce((accu, curr) => [...accu, {...curr, AZR_USR_ID: userInfo?.upn}], []));
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: true,
            searching: true,
            contextmenu: true,
            rowclick: true,
            morecolumns: true
        }
    }



    // Table column defination
	/* const tableColumns = [
        {label: 'State', field: 'ST_C', align: 'center', width: 100, filter: true, sorting: true},
        {label: 'Market', field: 'MKT_D', width: 280, filter: true, sorting: true},
        {label: 'Distributor Name', field: 'DIST_D', width: 450, filter: true, sorting: true},
        {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 150, filter: true, sorting: true},
        {label: 'File Name', field: 'FILE_M', width: 260, filter: true, sorting: true},
        {label: 'File Date', field: 'FILE_DATE', width: 200, sorting: true, filter: true, sortColumn: 'FILE_DATE_T'},
        {label: 'From Date', field: 'FROM_INVC_DATE', width: 200, sorting: true},
        {label: 'To Date', field: 'TO_INVC_DATE', width: 180, sorting: true},
        {label: 'Rec Count', field: 'RECORD_COUNT', align: 'right', width: 150, sorting: true}
	]; */

	// Table options
	/* const tableOptions = {
		action: true,
        filtering: true,
        searching: true
	}; */

    // Table column search
    /* const tableColumnSearch = [
        'MKT_D', 
        'DIST_D', 
        'FILE_M'
    ]; */
    
    // Context Menu Items
    /* const contextMenuItems = [
        {
            id: 'process',
            label: 'Process',
            icon: ArrowCircleRightOutlinedIcon,
            callback: (e, result) => {
                const {sourceItems, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'PM',
                    title: 'Process',
                    icon: ArrowCircleRightOutlinedIcon,
                    actionText: 'Process',
                    action: (e, newData) => {
                        let _sourceItems = sourceItems?.map(item => ({...item, usrid: userInfo.upn}));
                        processHistoricalRejectedData(e, _sourceItems, onClearRowSelection);
                    }
                });
                setBtnDisabled(false);
                setShowDialog(true);
            }
        },
        {
            id: 'deletei',
            label: 'Delete',
            icon: DeleteOutlinedIcon,
            callback: (e, result) => {
                const {sourceItems, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'DM',
                    title: 'Delete',
                    icon: DeleteOutlinedIcon,
                    actionText: 'Confirm',
                    action: (e, newData) => {
                        let _sourceItems = sourceItems?.map(item => ({...item, usrid: userInfo.upn}));
                        deleteHistoricalRejectedData(e, _sourceItems, onClearRowSelection);
                    }
                });
                setBtnDisabled(false);
                setShowDialog(true);
            }
        },
        {
            id: 'cancel',
            label: 'Cancel',
            icon: CancelOutlinedIcon
        }
    ]; */
    
    // Callback for row actions
	/* const tableColumnActions = [
		{
            // Process sales rejected data
            title: 'Process',
			icon: ArrowCircleRightOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setSelectedRow(rowData);
                setBtnDisabled(false);
                // Open dialog to confirm process data
                setDialogType({
                    type: 'P',
                    title: 'Process',
                    icon: ArrowCircleRightOutlinedIcon,
                    actionText: 'Process',
                    action: (e, newData) => {
                        processHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		},
		{
			// Delete rejected data
            title: 'Delete',
			icon: DeleteOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setSelectedRow(rowData);
                setBtnDisabled(false);
                console.log(rowData);
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'D',
                    title: 'Delete',
                    icon: DeleteOutlinedIcon,
                    actionText: 'Confirm',
                    action: (e, newData) => {
                        deleteHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		}
	]; */

    // Close dialog
    /* const dialogClose = (e) => {
        setShowDialog(false);
        setTimeout(() => {
            setDialogType({});
            setBtnLoading(false);
            callbacks?.onClearRowSelection 
                && callbacks?.onClearRowSelection();
            setCallbacks(repackCallbacks('onClearRowSelection'));
        }, 1000);
    } */

    // Rebuild / repack callbacks
    /* const repackCallbacks = (skips) => {
        if (!(skips instanceof Array)) {
            skips = [skips];
        }

        let _new_callbacks = null;
        Object.entries(callbacks || {}).forEach(([key, value]) => {
            if (!skips.includes(key)) {
                _new_callbacks = {..._new_callbacks, [key]: value};
            }
        });
        return _new_callbacks;
    } */

    // Disable / enable button
    /* const toggleButtonState = (btnRef, state) => {
        if (!btnRef.current) {
            return;
        }
        // Disable buttons
        btnRef.current.disabled = state || false;
        if (state) {
            btnRef.current.classList.add('Mui-disabled');
        }
        else {
            btnRef.current.classList.remove('Mui-disabled');
        }
    } */

    // Close Snackbar
	/* const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
    } */

    // Show Snackbar message
    /* const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity]) */

    // MUI Alert - for Snackbar
    // const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);


    // Delete Historical Sales Rejected Data
    const deleteHistoricalRejectedData = async (rowItems) => {
        await HistSalesRejectedDataService.deleteHistoricalRejectedData(rowItems)
            .then(response => {
                const {status} = response.data;
                if (status.isOk) {
                    const rowItemUuids = rowItems?.reduce((accu, curr) => [...accu, curr?.UUID], []);
                    const tableDataNew = tableData.odata?.filter(o => !rowItemUuids.includes(o.UUID));
                    setTableData({
                        odata: tableDataNew,
                        cdata: tableDataNew
                    });
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_HIST_SLS_REJDATA_DELETE_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
                // setTimeout(() => { setSelectedRow({}); }, 5000);
            });
    }

    // Process Historical Sales Rejected Data
    const processHistoricalRejectedData = async (rowItems) => {
        await HistSalesRejectedDataService.processHistoricalRejectedData(rowItems)
            .then(response => {
                const {status} = response.data;
                if (status.isOk) {
                    const rowItemUuids = rowItems?.reduce((accu, curr) => [...accu, curr?.UUID], []);
                    const tableDataNew = tableData.odata?.filter(o => !rowItemUuids.includes(o.UUID));
                    setTableData({
                        odata: tableDataNew,
                        cdata: tableDataNew
                    });
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_HIST_SLS_REJDATA_PROCESS_SUCCESS') : status?.message).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowDialog(false);
                // setTimeout(() => { setSelectedRow({}); }, 5000);
            });
    }

    // userEffect hook to load historical sales rejected data during page load
    useEffect(() => {
        (async () => {
            setShowLoader(true);
            await HistSalesRejectedDataService.getAllHistoricalRejectedData()
                .then(response => {
                    const {status, rows} = response.data;
                    setTableData({
                        odata: rows || [],
                        cdata: rows || []
                    });
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                    // setTimeout(() => setTableData([]), 5000);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [])



    // Customized Grid Properties
	const customProps = {
		title: 'Historical Sales Data',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader/* ,
            showOverlayLoader: showOverlayLoader */
        },
        vgrid: {
            id: 'idSlsHistRejctCntr',
            rowData: tableData?.cdata,
            // selectedRow: selectedRow,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension,
                actionColumnWidth: 60
                // height: tableDimension?.height
                // width: tableDimension?.width
            },
            psearch: {
                // width: 200
            },
            pdialog: {
                showDialog: showDialog,
                dialogType: dialogType
            }
        }
    }
    
    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )
}
