import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconButton, Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styled from '@emotion/styled';


// Search field container style
const StyledSearchContainer = styled.div`
	position: relative;
	margin-left: 1.5em;
	&>.MuiInputBase-root:before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`;

// Search input field style
const StyledSearchInput = styled(Input)`
	color: inherit;
	width: 16em;
    font-size: 0.795rem;
`;

// Search Prev/Next Container
const StyledSearchPrevNextContainer = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.22);
	border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	display: flex;
	width: 100%;
	padding: 8px;
	z-index: 999;
	background: #ffffaf;
	position: absolute;
    align-items: center;
	&>button {
		padding: 1px;
	}
`;

// Search Counter Container
const StyledSearchCounterContainer = styled.span`
    flex-grow: 1;
    text-align: center;
    font-weight: 500;
    user-select: none;
`;


// Search box dialog Component
export const SearchBoxDialog = (props) => {
    const searchInputRef = useRef(null);
    const {
        width,
        gridData,
        gridOdata,
        columns,
        searchTextClear,
        disable=false,
        miscsearch=false,
        placeholder='Search',
        callbacks,
        inputRef=searchInputRef
    } = props;
    
	const [searchInputText, setSearchInputText] = useState();
    const [searchStates, setSearchStates] = useState({
		prevNextCount: 0,
		totalCount: 0,
        selectedIndex: 0,
        prevSelectedIndex: -1,
		prevBtnDisable: true,
		nextBtnDisable: true
	});
	const [searchRowData, setSearchRowData] = useState({
        odata: undefined,
        cdata: undefined
    });
    const [searchIds, setSearchIds] = useState([]);


    // Replace result with updated values
    const replaceItemsWithChangedValues = (currIndex, prevIndex, rawItems) => {
        // Previous search item row update
        let foundRowItemPi = rawItems.find((o, i) => i === prevIndex);
        foundRowItemPi && 
            Object.keys(foundRowItemPi)?.forEach(key => {
                foundRowItemPi = {
                    ...foundRowItemPi, 
                    [key]: (foundRowItemPi[key] || '')?.toString().replace(/<span[^>]*>/gi, '<span>')
                }
            });
        
        // Next search item row update
        let foundRowItemCi = rawItems.find((o, i) => i === currIndex);
        foundRowItemCi && 
            Object.keys(foundRowItemCi)?.forEach(key => {
                foundRowItemCi = {
                    ...foundRowItemCi, 
                    [key]: (foundRowItemCi[key] || '')?.toString().replace(/<span>/gi, '<span class="xclr">')
                }
            });
        
        const rawItemsResult = Array.from(rawItems);
        foundRowItemPi && 
            rawItemsResult.splice(prevIndex, 1, foundRowItemPi);
        foundRowItemCi && 
            rawItemsResult.splice(currIndex, 1, foundRowItemCi);
        return rawItemsResult;
    }

    // onMiscSearchCallback
    const onMiscSearchCallback = useCallback(e => {
        const {odata} = searchRowData;
        let _foundItems = [],
            searchText = (e.target.value || '');
        setSearchInputText(searchText);
        
        // filter search column fields
        const _searchColumns = columns.filter(c => c.visible && c.searching);
        if (!_searchColumns?.length) {
            return;
        }

        const replacedSearchText = searchText.replace(/\\/g, '\\\\');
		const regExpText = new RegExp(`(${replacedSearchText})`, 'gi');
        
        // If search text is not blank
        searchText?.length && 
            odata?.forEach((item, i) => {
                let _item = item,
                    _isMatchedText = false;
                
                // Find search string on fields
                _searchColumns?.forEach(({field}) => {
                    const fieldData = item[field]?.toString();
                    _isMatchedText = regExpText.test(fieldData) || _isMatchedText;
                });
                if (_isMatchedText) {
                    _foundItems.push(_item);
                }
            });
        
        const searchedAttrsData = {
            /* searchData: {
                odata: odata || [],
                cdata: searchText?.length ? _foundItems : []
            }, */
            searchData: {
                cdata: searchText?.length ? _foundItems : odata
            },
            foundFirstIndex: 0
        }
        callbacks?.onSearch 
            && callbacks.onSearch(e, searchedAttrsData);
    }, [callbacks, columns, searchRowData])
    
    // onSearch callback
    const onSearchCallback = useCallback(e => {
        const {odata} = searchRowData;
        let matched_count = 0,
            _foundFirstIndex = -1,
            _copyData = [],
            _matchedSearchIds = [],
            searchText = e.target.value || '';
        setSearchInputText(searchText);

        // filter search column fields
        const _searchColumns = columns.filter(c => c.visible && c.searching);
        if (!_searchColumns?.length) {
            return;
        }

        const replacedSearchText = searchText.replace(/\\/g, '\\\\');
		const regExpText = new RegExp(`(${replacedSearchText})`, 'gi');

        // If search text is not blank
        searchText?.length && 
            odata?.forEach((item, i) => {
                let _item = item,
                    _isMatchedText = false;
                
                // Find search string on fields
                _searchColumns?.forEach(({field}) => {
                    const fieldData = item[field]?.toString();
                    const searched_parts = (fieldData?.split(regExpText) || []);

                    searched_parts.forEach(part => {
                        const isMatched = part.toLowerCase() === searchText.toLowerCase();
                        if (isMatched) {
                            const _searchedText = fieldData.replace(regExpText, '<span>$1</span>');
                            _item = {..._item, [field]: _searchedText};
                            _isMatchedText = true;
                        }
                    });
                });

                if (_isMatchedText) {
                    matched_count++;
                    _foundFirstIndex = _foundFirstIndex < 0 ? i : _foundFirstIndex;
                    _matchedSearchIds.push({k: i, v: item?.UUID});
                }
                _copyData[i] = _item;
            });

        const _newCopyData = replaceItemsWithChangedValues(_foundFirstIndex, undefined, _copyData);
        const _isMatchedCount = matched_count > 1; // matched_count > 0;
        const searchedAttrsData = {
            searchData: {
                cdata: searchText?.length ? _newCopyData/* _copyData */ : odata
            },
            searchIds: _matchedSearchIds,
            foundFirstIndex: _foundFirstIndex
        }
        setSearchStates(prev => ({
            ...prev,
            prevNextCount: 1, //_isMatchedCount ? 1: 0, 
            totalCount: matched_count, 
            prevBtnDisable: true, 
            nextBtnDisable: !_isMatchedCount,
            selectedIndex: _foundFirstIndex
        }));
        setSearchIds(_matchedSearchIds);
        /* setSearchRowData(prev => ({
            ...prev,
            ...searchedAttrsData.searchData
        })); */

        callbacks?.onSearch 
            && callbacks.onSearch(e, searchedAttrsData);
    }, [callbacks, columns, searchRowData])

    // onSearchClear callback
    const onSearchClearCallback = useCallback(e => {
        setSearchStates({
            selectedIndex: 0,
            prevSelectedIndex: -1,
            prevNextCount: 0, 
            totalCount: 0, 
            prevBtnDisable: true, 
            nextBtnDisable: true
        });
        setSearchInputText('');
        /* setSearchRowData(prev => ({
            ...prev,
            cdata: prev.odata
        })) */
		inputRef.current.value = '';

        callbacks?.onSearchClear 
            && callbacks.onSearchClear(e, searchRowData);
    }, [searchRowData, callbacks, inputRef])

    // onSearchFirstCallback callback
    const onSearchFirstCallback = useCallback(e => {
        let {selectedIndex} = searchStates;
        const _searchSelectedIndex = searchIds.slice(0, 1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
        
        setSearchStates(prev => ({
            ...prev,
            prevNextCount: 1,
            prevBtnDisable: true,
            nextBtnDisable: false,
            prevSelectedIndex: prev.selectedIndex,
            selectedIndex: _searchSelectedIndexUpdated
        }));
        const _newCopyData = replaceItemsWithChangedValues(_searchSelectedIndexUpdated, selectedIndex, searchRowData?.cdata);
        
        callbacks?.onSearchFirst 
            && callbacks.onSearchFirst(e, _searchSelectedIndexUpdated, _newCopyData);
    }, [callbacks, searchIds, searchStates, searchRowData])

    // onSearchLastCallback callback
    const onSearchLastCallback = useCallback(e => {
        let {selectedIndex} = searchStates;
        const _searchSelectedIndex = searchIds.slice(-1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
        
        setSearchStates(prev => ({
            ...prev,
            prevNextCount: prev.totalCount,
            prevBtnDisable: false,
            nextBtnDisable: true,
            prevSelectedIndex: prev.selectedIndex,
            selectedIndex: _searchSelectedIndexUpdated
        }));
        const _newCopyData = replaceItemsWithChangedValues(_searchSelectedIndexUpdated, selectedIndex, searchRowData?.cdata);
        
        callbacks?.onSearchLast 
            && callbacks.onSearchLast(e, _searchSelectedIndexUpdated, _newCopyData);
    }, [callbacks, searchIds, searchStates, searchRowData])

    // onSearchPrevCallback callback
    const onSearchPrevCallback = useCallback(e => {
        let {selectedIndex, prevNextCount} = searchStates;
		const _firstSearchIndexCount = 1,
            _matchedSearchIds = [...searchIds];
        _matchedSearchIds.reverse();
        const _searchSelectedIndex = _matchedSearchIds.find(item => (selectedIndex || 0) > item.k);
        const _search_count_pnc = (prevNextCount <= _firstSearchIndexCount  ? _firstSearchIndexCount : --prevNextCount),
            _searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : selectedIndex,
            _isPrevDisabled = _search_count_pnc <= _firstSearchIndexCount;
        
        setSearchStates(prev => ({
            ...prev,
            prevNextCount: _search_count_pnc,
            prevBtnDisable: _isPrevDisabled,
            nextBtnDisable: false,
            prevSelectedIndex: prev.selectedIndex,
            selectedIndex: _searchSelectedIndexUpdated
        }));
        const _newCopyData = replaceItemsWithChangedValues(_searchSelectedIndexUpdated, selectedIndex, searchRowData?.cdata);
        
        callbacks?.onSearchPrev 
            && callbacks.onSearchPrev(e, _searchSelectedIndexUpdated, _newCopyData);
    }, [callbacks, searchStates, searchIds, searchRowData])

    // onSearchNextCallback callback
    const onSearchNextCallback = useCallback(e => {
        let {selectedIndex, prevNextCount, totalCount} = searchStates;
		const _matchedSearchIds = [...searchIds];
        const _searchSelectedIndex = _matchedSearchIds.find(item => item.k > (selectedIndex || 0));
        const _search_count_pnc = (prevNextCount >= totalCount  ? totalCount : ++prevNextCount),
            _searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : selectedIndex,
            _isNextDisabled = _search_count_pnc >= totalCount;
        
        setSearchStates(prev => ({
            ...prev,
            prevNextCount: _search_count_pnc,
            prevBtnDisable: false,
            nextBtnDisable: _isNextDisabled,
            prevSelectedIndex: prev.selectedIndex,
            selectedIndex: _searchSelectedIndexUpdated
        }));
        const _newCopyData = replaceItemsWithChangedValues(_searchSelectedIndexUpdated, selectedIndex, searchRowData?.cdata);
        
        callbacks?.onSearchNext 
            && callbacks.onSearchNext(e, _searchSelectedIndexUpdated, _newCopyData);
    }, [callbacks, searchStates, searchIds, searchRowData])
    
    useEffect(() => {
        // console.log('zzzz-xxx>>>>', searchTextClear);
        searchTextClear && 
            setSearchInputText('');
    }, [searchTextClear])

    useEffect(() => {
        const {fdata, cdata} = gridData;
        setSearchRowData({
            odata: fdata,
            cdata: cdata
        });
        /* setSearchRowData({
            odata: !fdata && gridOdata ? gridOdata : fdata,
            cdata: gridOdata || cdata
        }); */
        /* setSearchRowData({
            odata: !fdata && gridOdata ? gridOdata : fdata,
            cdata: !cdata && gridOdata ? gridOdata : cdata
        }); */
    }, [gridData, gridOdata])
    
    // console.log('xxx>>>>', searchTextClear);
    // console.log('search-searchRowData>>>>', searchRowData);

    return (
        <StyledSearchContainer>
            <StyledSearchInput 
                {...(searchTextClear && {key: searchTextClear})} 
                {...(disable && {disabled: disable})} 
                {...(width && {style: {width: `${width}px`}})} 
                inputRef={inputRef} aria-label="search" placeholder={placeholder} 
                startAdornment={<InputAdornment position="start"><SearchIcon sx={{width: '0.8em', height: '0.8em'}}/></InputAdornment>} 
                endAdornment={<IconButton sx={{visibility: searchInputText ? 'visible' : 'hidden'}} size="small" onClick={onSearchClearCallback}>
                        <ClearIcon sx={{width: '0.6em', height: '0.6em'}}/>
                    </IconButton>} 
                onKeyUp={miscsearch ? onMiscSearchCallback : onSearchCallback} />
            {!miscsearch && searchStates?.totalCount > 0 && 
                <StyledSearchPrevNextContainer>
                    <IconButton onClick={onSearchFirstCallback} disabled={searchStates?.prevBtnDisable} size="small"><FirstPageIcon sx={{width: '0.8em', height: '0.8em'}}/></IconButton>
                    <IconButton onClick={onSearchPrevCallback} disabled={searchStates?.prevBtnDisable} size="small"><NavigateBeforeIcon sx={{width: '0.8em', height: '0.8em'}}/></IconButton>
                    <StyledSearchCounterContainer>{searchStates?.prevNextCount} / {searchStates?.totalCount}</StyledSearchCounterContainer>
                    <IconButton onClick={onSearchNextCallback} disabled={searchStates?.nextBtnDisable} size="small"><NavigateNextIcon sx={{width: '0.8em', height: '0.8em'}}/></IconButton>
                    <IconButton onClick={onSearchLastCallback} disabled={searchStates?.nextBtnDisable} size="small"><LastPageIcon sx={{width: '0.8em', height: '0.8em'}}/></IconButton>
                </StyledSearchPrevNextContainer>
            }
        </StyledSearchContainer>
    )
}
