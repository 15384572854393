import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
// import MuiAlert from '@mui/material/Alert';
import { FormControl, InputLabel, MenuItem, Select, /* Snackbar,  */Autocomplete, TextField, Paper, IconButton } from '@mui/material';
// import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SalesCalendar } from '../components/component.sales.calendar';
import { NonSellingDaysService } from '../services/service.sales.nonsellingdays';
import { SnackbarAlert } from '../components/component.snackbar.alert';
import { env } from '../utils/env.variables';
import { SnackbarCfg } from '../utils/utils';
import SaveIcon from '@mui/icons-material/Save';
// include utils module
require('../utils/utils');


/**
 * Styled components
 */
// Table main container
const Container = styled.div`
    display: flex;
    margin-top: 8px;
    // border: 1px solid;
`;
// Table row container
const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    /* border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: inline-block; */
    margin: 0 auto;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
    display: flex;
    margin: 0 0 1em;
    padding: 0 .1em;
`;
// Table title style
const Title = styled.h4`
    margin: 0;
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 1rem;
    user-select: none;
`;
// Distributor type div style
/* const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0px solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
`; */
// Dropdown
const StyledDropDown = styled(FormControl)`
    min-width: 30%;
    margin-left: 1.5em;
    margin-top: -10px;
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>.MuiInputLabel-root {
            top: -2px;
        }
        &>.MuiInputBase-root {
            &>.MuiAutocomplete-input {
                font-size: 0.75rem;
            }
            &>.MuiAutocomplete-endAdornment {
                &>.MuiIconButton-root >.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Extended dropdown
const StyledDropDown1 = styled(FormControl)`
    min-width: 10%;
    margin-top: -10px;
    margin-left: 1.5em;
    &>.MuiInputBase-root {
        margin-bottom: 0;
        font-size: inherit;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
`;
// Calendar place holder
const CalendarPlaceHolderDiv = styled.div`
	display: inline-block;
    padding: .1em 0;
    width: 100%;
`;
// List menu item style
const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;
// Action Menu handler div style
const StyledActionMenuDiv = styled.div`
	display: flex;
	margin-left: 1.5em;
	align-items: center;
	& .MuiIconButton-root {
		padding: 0 5px;
	}
	& .MuiIconButton-root:last-child {
		padding: 0 0 0 5px;
	}
`;

// Severity level - error, info, success, warning
/* const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
}; */


// Sales Calendar - Non Selling Days
export const SalesCalNonSellingDays = (props) => {
    const {dimensions: tableDimension, graphData: userInfo} = props;
    // const {isUserCanModify: isModifyEnabled} = userInfo;
    const [years, setYears] = useState();
    const [yearSelected, setYearSelected] = useState();
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    // const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    /* const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    }); */
    const [nonSellingDaysData, setNonSellingDaysData] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [isCalEventEnabled, setIsCalEventEnabled] = useState(false);
    const [selectedDaysToProcess, setSelectedDaysToProcess] = useState([]);
    const [, setShowSnackbar] = useState();

    SnackbarCfg.setState({
        state: setShowSnackbar
    });

    // Get Fiscal year list
    const getFiscalYears = useCallback(() => {
        let currentYear = new Date().getFullYear(),
            startYear = currentYear - 2,
            endYear = currentYear + 1;
        const yearRangeArr = startYear.range(endYear);

        return yearRangeArr;
    }, []);

    // Get all distributors data
    const getAllDistributors = useCallback(async () => {
        return await NonSellingDaysService.getAllDistributorsData()
            .then(response => response)
            .catch(error => error?.response);
    }, []);

    // Get all Non Selling Days data
    const getNonSellingDaysData = useCallback(async (jdeAbNo, year) => {
        return await NonSellingDaysService.getNonSellingDays({jdeAbNo: jdeAbNo, year: year})
            .then(response => response)
            .catch(error => error?.response);
    }, []);

    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        setShowLoader(true);
        setJdeAddrBookNo(dwValue);
        sessionStorage.setItem('ssk.jdeAddrBookNo', JSON.stringify(dwValue));
        setIsCalEventEnabled(!!(dwValue?.id && yearSelected));
        if (!dwValue || !yearSelected) {
            setNonSellingDaysData([]);
            setShowLoader(false);
            return;
        }

        // Get Non Selling Days Data
        (async () => {
            await getNonSellingDaysData(dwValue?.id, yearSelected)
                .then(response => {
                    // console.log('response?.data>>', response?.data);
                    const {status, rows} = response?.data;
                    setNonSellingDaysData(rows || []);
                    if (!status?.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [yearSelected, getNonSellingDaysData]);

    // Year Change Callback defination
    const onYearChangeCallback = useCallback((e) => {
        setShowLoader(true);
        setYearSelected(e.target.value);
        setIsCalEventEnabled(!!(jdeAddrBookNo?.id && e.target.value));
        if (!e.target.value) {
            setNonSellingDaysData([]);
            setShowLoader(false);
            return;
        }

        // Get Non Selling Days Data
        (async () => {
            await getNonSellingDaysData(jdeAddrBookNo?.id, e.target.value)
                .then(response => {
                    const {status, rows} = response?.data;
                    setNonSellingDaysData(rows || []);
                    if (!status?.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [jdeAddrBookNo, getNonSellingDaysData]);
    
    // Day click callback
    const onCalendarDayClickCallback = useCallback(result => {
        // console.log('onCalendarDayClickCallback>>>>', result);
        let newResultData = [];
        result?.forEach(item => {
            newResultData.push({
                ...item,
                AZR_USR_ID: userInfo.upn,
                SELECTED_YEAR: yearSelected,
                N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id
            });
        });
        // console.log('onCalendarDayClickCallback>>newResultData>>', newResultData);
        
        (async () => {
            await updateNonSellingDays(newResultData);
            /* await NonSellingDaysService.updateNonSellingDays(newResultData)
                .then(response => {
                    const {status, rows: nonSellingData} = response?.data;
                    if (status?.isOk) {
                        setNonSellingDaysData(nonSellingData || []);
                    }
                    
                    SnackbarCfg
                        .setSeverity(status?.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                            .setMessage(status?.isOk ? env.getEnv('REACT_APP_MSG_NSD_UPDATE_SUCCESS') : (status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'))).show();
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                }); */
        })();
    }, [jdeAddrBookNo, yearSelected, userInfo]);

    // onCalendarDaySaveClick Callback
    const onCalendarDaySaveClickCallback = useCallback((e) => {
        let newResultData = [];
        selectedDaysToProcess?.forEach(item => {
            newResultData.push({
                ...item,
                AZR_USR_ID: userInfo.upn,
                SELECTED_YEAR: yearSelected,
                N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id
            });
        });
        // console.log('onCalendarDayClickCallback>>newResultData>>', selectedDaysToProcess, newResultData);
        
        (async () => {
            await updateNonSellingDays(newResultData);
        })();
    }, [jdeAddrBookNo, yearSelected, userInfo, selectedDaysToProcess]);

    // Update non selling days
    const updateNonSellingDays = async (newResultData) => {
        setShowLoader(true);
        await NonSellingDaysService.updateNonSellingDays(newResultData)
            .then(response => {
                const {status, rows: nonSellingData} = response?.data;
                if (status?.isOk) {
                    setNonSellingDaysData(nonSellingData || []);
                }
                
                SnackbarCfg
                    .setSeverity(status?.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status?.isOk ? env.getEnv('REACT_APP_MSG_NSD_UPDATE_SUCCESS') : (status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'))).show();
            })
            .catch(error => {
                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                    .setMessage((error?.response?.data?.message || error?.message)).show();
            })
            .finally(() => {
                setShowLoader(false);
                setSelectedDaysToProcess([]);
            });
    }
    
    // userEffect hook to load data if changes
    useEffect(() => {
        setShowLoader(true);
        setYears(getFiscalYears());
        setYearSelected(yearSelected || new Date().getFullYear());

        if (allDistributorsData) {
            setShowLoader(false);
            return;
        }

        // Get distributors data
        (async () => {
            await getAllDistributors()
                .then(response => {
                    const {status, rows} = response?.data;
                    setAllDistributorsData(rows || []);
                    // Reload selected distributor data
                    const sessStorageItems = JSON.parse(sessionStorage.getItem('ssk.jdeAddrBookNo') ?? null);
                    onDistributorChangeCallback(null, sessStorageItems);

                    if (!status?.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [yearSelected, allDistributorsData, getAllDistributors, getFiscalYears, onDistributorChangeCallback]);

    // Customized Properties
    const customProps = {
        title: 'Sales Calendar - Non Selling Days',
        dimensions: tableDimension,
        dwYears: years,
        showLoader: showLoader,
        dwYear: yearSelected,
        jdeAbNo: jdeAddrBookNo,
        nonSellingDays: nonSellingDaysData,
        selectedDaysToProcess: selectedDaysToProcess,
        setSelectedDaysToProcess: setSelectedDaysToProcess,
        isCalEventEnabled: isCalEventEnabled,
        allDistributors: allDistributorsData,
        callbacks: {
            onYearChange: onYearChangeCallback,
            onDistributorChange: onDistributorChangeCallback,
            onCalendarDayClick: onCalendarDayClickCallback,
            onCalendarDaySaveClick: onCalendarDaySaveClickCallback
        }/* ,
        snackbar: {
            duration: 5000,
            isOpen: openSnackbar,
            messages: snackbarMessage,
            callbacks: {
                onCloseSnackbar: onCloseSnackbarCallback
            }
        } */
    }

    return (
        <Container>
            <SnackbarAlert />
            <CalendarContainer {...customProps} />
            {/* <SnackbarAlert {...customProps.snackbar} /> */}
        </Container>
    )
}

// Main Calendar Container
const CalendarContainer = React.memo(props => {
    const {dimensions: {width: gridWidth}} = props;
    return (
        <TableRowContainer style={{width: `${gridWidth}px`}}>
            <TableCaptionBar {...props} />
            <CalendarPlaceHolder {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        dwYear,
        dwYears,
        jdeAbNo,
        allDistributors,
        selectedDaysToProcess,
        callbacks: {onYearChange, onDistributorChange, onCalendarDaySaveClick}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} (Year - {dwYear})</Title>

            <StyledDropDown variant="standard"/*  style={{marginTop: '-1rem', minWidth: '28%'}} */>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`,
                        label2: `${el.DIST_DESC}`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    PaperComponent={props => <Paper sx={{fontSize: '0.75rem !important'}} {...props}/>}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>

            {/* <StyledDropDown variant="standard" style={{minWidth: '20%'}}>
                <InputLabel id="distributor-input-label">Distributor</InputLabel>
                <Select id="distributor-select-label" labelId="distributor-select-label" defaultValue={''} 
                    value={jdeAbNo || ''} name="DW_DISTRIBUTOR" label="Distributor" onChange={onDistributorChange}>
                    <MenuItem value={''}>None</MenuItem>
                    {(allDistributors || []).map((item, i) => (
                        <MenuItem key={`dist-${item.JDE_ADDR_BOOK_N}-${i}`} value={item.JDE_ADDR_BOOK_N}>{item.DIST_DESC} ({item.JDE_ADDR_BOOK_N})</MenuItem>
                    ))}
                </Select>
            </StyledDropDown> */}
            <StyledDropDown1 variant="standard">
                <InputLabel id="year-input-label">Year</InputLabel>
                <Select id="year-select-label" labelId="year-select-label" defaultValue={''} 
                    value={dwYear || ''} name="DW_YEAR" label="Year" onChange={onYearChange}>
                    {(dwYears || []).map((year, i) => (
                        <StyledListItem key={`dwy-${year}-${i}`} value={year}>{year}</StyledListItem>
                    ))}
                </Select>
            </StyledDropDown1>

            {/* <StyledDropDown variant="standard">
                <InputLabel id="year-input-label">Year</InputLabel>
                <Select id="year-select-label" labelId="year-select-label" defaultValue={''} 
                    value={dwYear || ''} name="DW_YEAR" label="Year" onChange={onYearChange}>
                    {(dwYears || []).map((year, i) => (
                        <MenuItem key={`dwy-${year}-${i}`} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </StyledDropDown> */}
            <StyledActionMenuDiv>
                <IconButton title={'Save'} onClick={onCalendarDaySaveClick} disabled={!selectedDaysToProcess?.length}>
                    <SaveIcon size={'small'} sx={{width: '0.8em', height: '0.8em'}}/>
                </IconButton> 
            </StyledActionMenuDiv>
        </StyledCaptionBar>
    )
})

// Calendar Place Holder
const CalendarPlaceHolder = React.memo(props => {
    const {
        dwYear,
        showLoader,
        nonSellingDays,
        isCalEventEnabled,
        selectedDaysToProcess,
        setSelectedDaysToProcess,
        callbacks: {onCalendarDayClick}
    } = props;
    const calendarCallbacks = {
        onCalendarDayClick: onCalendarDayClick
    }

    return (
        <CalendarPlaceHolderDiv>
            <SalesCalendar 
                isCalEventEnabled={isCalEventEnabled} 
                calendarDays={nonSellingDays} 
                showLoader={showLoader} 
                callbacks={calendarCallbacks} 
                year={dwYear} 
                onloadcalled={!selectedDaysToProcess?.length}
                setSelectedDaysToProcess={setSelectedDaysToProcess}
                showNextMonth={true}
                calType="NSD"/>
        </CalendarPlaceHolderDiv>
    )
})
/* 
// Snackbar Alert Container
const SnackbarAlert = React.memo(props => {
    const {
        isOpen,
        duration,
        messages: {severity='info', message=''},
        callbacks: {onCloseSnackbar}
    } = props;
    
    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    return (
        <Snackbar open={isOpen} autoHideDuration={duration} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity={severity} sx={{width: '100%'}}>{message}</Alert>
        </Snackbar>
    )
})
 */